/* eslint-disable max-len */
import React, { FC } from 'react';
import { IconProps } from '../types';

export const Notifications: FC<IconProps> = ({
  size = '20',
  color = '#445371',
  transform,
  viewBox = '0 0 20 20',
  className,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    transform={transform}
    viewBox={viewBox}
    className={className}
    fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.66667 15.1667C6.66667 14.7064 7.03976 14.3333 7.5 14.3333H12.5C12.9602 14.3333 13.3333 14.7064 13.3333 15.1667V16C13.3333 17.8409 11.8409 19.3333 10 19.3333C8.15905 19.3333 6.66667 17.8409 6.66667 16V15.1667ZM8.33333 16C8.33333 16.9205 9.07953 17.6667 10 17.6667C10.9205 17.6667 11.6667 16.9205 11.6667 16H8.33333Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 4.33333C11.5398 4.33333 11.1667 3.96023 11.1667 3.5C11.1667 3.40689 11.1183 3.25906 10.9142 3.106C10.7099 2.95276 10.3894 2.83333 10 2.83333C9.61059 2.83333 9.29012 2.95276 9.08579 3.106C8.88171 3.25906 8.83333 3.40689 8.83333 3.5C8.83333 3.96023 8.46024 4.33333 8 4.33333C7.53976 4.33333 7.16667 3.96024 7.16667 3.5C7.16667 2.76468 7.566 2.16251 8.08579 1.77267C8.60531 1.38303 9.28484 1.16666 10 1.16666C10.7152 1.16666 11.3947 1.38303 11.9142 1.77267C12.434 2.16251 12.8333 2.76468 12.8333 3.5C12.8333 3.96023 12.4602 4.33333 12 4.33333Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.33334 9.33329C3.33334 5.6514 6.31809 2.66664 9.99996 2.66662C13.6819 2.66661 16.6667 5.65138 16.6667 9.33329V12.3211L17.0118 12.6666C17.3244 12.9792 17.5 13.4032 17.5 13.8452V14.3333C17.5 15.2537 16.7539 16 15.8334 16H4.16667C3.24616 16 2.5 15.2538 2.5 14.3333V13.8452C2.5 13.4032 2.67558 12.9792 2.98812 12.6666L3.33334 12.3215V9.33329ZM9.99997 4.33329C7.23858 4.3333 5.00001 6.57187 5.00001 9.33329V12.4354C5.00001 12.8046 4.85328 13.1584 4.59256 13.4193L4.16671 13.8451L4.16667 14.3333L15.8334 14.3333L15.8333 13.8452L15.4076 13.4194C15.1464 13.1583 15 12.8041 15 12.4352V9.33398C15 6.57256 12.7614 4.33328 9.99997 4.33329Z"
      fill={color}
    />
  </svg>
);
