import { call, put, all, takeLatest } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";

import apiClient from "@Helpers/apiClient";
import { actionsProjects } from "./Projects.reducer";
import { ProjectsMenuFetchPayload } from "../types";

const PROJECTS_URL = "/v2/users/projects";

function* mountSelectedProject({
  type,
  payload: { projectId },
}: PayloadAction<ProjectsMenuFetchPayload>) {
  yield put(actionsProjects.startLoading(type));

  try {
    const { data: response } = yield call(
      apiClient.get,
      `${PROJECTS_URL}/${projectId}`,
      {
        section: "selectedProject",
      }
    );

    yield put(actionsProjects.mountSelectedProject(response));
  } catch (e) {
    if (e instanceof Error) {
      console.error("Ошибка при запросе выбранного проекта", e.message);
    } else {
      console.error("Неизвестная ошибка при запросе выбранного проекта", e);
    }
  } finally {
    yield put(actionsProjects.finishLoading(type));
  }
}

function* fetchSelectedProject() {
  yield takeLatest(actionsProjects.fetchSelectedProject, mountSelectedProject);
}

export default function* selectProjectSaga() {
  yield all([fetchSelectedProject()]);
}
