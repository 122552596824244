import { FC, memo } from "react";

import { EditButton } from "@/widgets/Sidebar/Sidebar.styled";
import { EditIcon } from "./SubmenuSection.styled";
import { EditIconButtonProps } from "./types";

export const EditIconButton: FC<EditIconButtonProps> = memo(
  ({ onClick, className }) => (
    <EditButton onClick={onClick} className={className}>
      <EditIcon />
    </EditButton>
  )
);
