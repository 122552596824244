/* eslint-disable max-len */
import React, { FC } from 'react';
import { IconProps } from '../types';

export const Archives: FC<IconProps> = ({
  size = '20',
  color = '#445371',
  transform,
  viewBox = '0 0 20 20',
  className,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    transform={transform}
    viewBox={viewBox}
    className={className}
    fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.57521 10.2229C4.18861 10.2229 3.87521 10.5363 3.87521 10.9229V16.0019C3.87521 16.3885 4.18861 16.7019 4.57521 16.7019H15.4248C15.8114 16.7019 16.1248 16.3885 16.1248 16.0019V10.9229C16.1248 10.5363 15.8114 10.2229 15.4248 10.2229H13.9166C13.7784 10.2229 13.6433 10.2638 13.5283 10.3405L12.5529 10.9908C12.175 11.2427 11.7311 11.3771 11.2771 11.3771H8.72297C8.2689 11.3771 7.82498 11.2427 7.44716 10.9908L6.47174 10.3405C6.35675 10.2638 6.22164 10.2229 6.08345 10.2229H4.57521ZM2.27521 10.9229C2.27521 9.65268 3.30495 8.62293 4.57521 8.62293H6.08345C6.53752 8.62293 6.98144 8.75734 7.35926 9.00922L8.33468 9.6595C8.44967 9.73616 8.58478 9.77707 8.72297 9.77707H11.2771C11.4152 9.77707 11.5504 9.73616 11.6653 9.6595L12.6408 9.00922C13.0186 8.75734 13.4625 8.62293 13.9166 8.62293H15.4248C16.6951 8.62293 17.7248 9.65268 17.7248 10.9229V16.0019C17.7248 17.2721 16.6951 18.3019 15.4248 18.3019H4.57521C3.30495 18.3019 2.27521 17.2721 2.27521 16.0019V10.9229Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.9707 9.42293V7.46053C14.9707 7.07393 14.6573 6.76053 14.2707 6.76053H5.72934C5.34274 6.76053 5.02934 7.07393 5.02934 7.46053V9.42293H3.42934V7.46053C3.42934 6.19027 4.45909 5.16053 5.72934 5.16053H14.2707C15.5409 5.16053 16.5707 6.19028 16.5707 7.46053V9.42293H14.9707Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.8165 5.96053V3.99813C13.8165 3.61153 13.5031 3.29813 13.1165 3.29813H6.88348C6.49688 3.29813 6.18348 3.61153 6.18348 3.99813V5.96053H4.58347V3.99813C4.58347 2.72787 5.61322 1.69813 6.88348 1.69813H13.1165C14.3868 1.69813 15.4165 2.72787 15.4165 3.99813V5.96053H13.8165Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.43331 14.5589C7.43331 15.0346 7.8189 15.4202 8.29456 15.4202H11.7052C12.1808 15.4202 12.5664 15.0346 12.5664 14.5589C12.5664 14.0833 12.1808 13.6977 11.7052 13.6977H8.29456C7.8189 13.6977 7.43331 14.0833 7.43331 14.5589Z"
      fill={color}
    />
  </svg>
);
