import styled from 'styled-components';
import { IIconStyles } from './types';
import { ICONS } from '@suz/ui-utils/constants/Icons';
import { COLORS } from '@suz/ui-utils/constants/Colors';

export const IconFontWrap = styled.i<IIconStyles>`
  position: relative;
  display: inline-block;
  font-family: 'suz_icons';
  font-style: normal;
  font-size: 20px;
  color: ${({ color }) => color ? COLORS[color] : 'inherit'};
  text-align: center;
  &:before {
    content: "\\${({ icon }) => ICONS[icon]}";
  }
`;
