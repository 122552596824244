
import { createAction } from '@reduxjs/toolkit';

const prefix = '[WS]';

export const connectToWS = createAction(`${prefix} connectToWS`);
export const exchangeMessagesWS = createAction(`${prefix} exchangeMessagesWS`);
export const sendMessageToWS = createAction(`${prefix} sendMessageToWS`);
export const subscribeWS = createAction(`${prefix} subscribeWS`);
export const unsubscribeWS = createAction(`${prefix} unsubscribeWS`);
export const disconnectWS = createAction(`${prefix} disconnectWS`);

export default {
  connectToWS,
  exchangeMessagesWS,
  sendMessageToWS,
  subscribeWS,
  unsubscribeWS,
  disconnectWS,
};
