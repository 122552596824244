import { FC, createElement } from 'react';

import { ICONS } from './Icons';
import { IconComponentProps } from './types';

const Icon: FC<IconComponentProps> = ({
  icon,
  size,
  color,
  transform,
  viewBox,
  className
}) => (
  createElement(ICONS[icon], {size, color, transform, viewBox, className})
);

export default Icon;
