/* eslint-disable max-len */
import React, { FC } from 'react';
import { IconProps } from '../types';

export const TagAdd: FC<IconProps> = ({
  size = '20',
  color = '#445371',
  transform,
  viewBox = '0 0 20 20',
  className,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    transform={transform}
    viewBox={viewBox}
    className={className}
    fill="none">
    <g clipPath="url(#clip0_2333_1057)">
      <path
        d="M4.16666 17.5V4.16667C4.16666 3.70833 4.33 3.31611 4.65666 2.99C4.98333 2.66389 5.37555 2.50056 5.83333 2.5H14.1667C14.625 2.5 15.0175 2.66333 15.3442 2.99C15.6708 3.31667 15.8339 3.70889 15.8333 4.16667V17.5L10 15L4.16666 17.5ZM5.83333 14.9583L10 13.1667L14.1667 14.9583V4.16667H5.83333V14.9583Z"
        fill={color}
      />
      <path
        d="M11 14.5H18M14.5 18V11"
        stroke="white"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 14.5H18M14.5 18V11"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2333_1057">
        <rect width={size} height={size} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
