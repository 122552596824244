/* eslint-disable max-len */
import React, { FC } from 'react';
import { IconProps } from '../types';

export const CancelS: FC<IconProps> = ({
  size = '16',
  color = '#445371',
  transform,
  viewBox = '0 0 16 16',
  className,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    transform={transform}
    viewBox={viewBox}
    className={className}
    fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.07739 4.07743C4.40283 3.75199 4.93046 3.75199 5.2559 4.07743L11.9226 10.7441C12.248 11.0695 12.248 11.5972 11.9226 11.9226C11.5971 12.248 11.0695 12.248 10.744 11.9226L4.07739 5.25594C3.75195 4.9305 3.75195 4.40287 4.07739 4.07743Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9226 4.07743C12.248 4.40287 12.248 4.9305 11.9226 5.25594L5.2559 11.9226C4.93046 12.2481 4.40283 12.2481 4.07739 11.9226C3.75195 11.5972 3.75195 11.0696 4.07739 10.7441L10.7441 4.07743C11.0695 3.75199 11.5972 3.75199 11.9226 4.07743Z"
      fill={color}
    />
  </svg>
);
