import { createSelector } from "@reduxjs/toolkit";
import { ProfileState } from "../types";

const profileState = (state: ProfileState) => state.profile;

export const getIsLoadingUser = () =>
  createSelector(profileState, (profile) => profile.isLoading);

export const getUser = () =>
  createSelector(profileState, (profile) => profile.user);

export const getUserId = () =>
  createSelector(profileState, (profile) => profile.user?.id);
