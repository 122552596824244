import React, { forwardRef } from 'react';
import Popover from '../Popover';
import useMenuList from './useMenuList';

import { List, RootWrap } from './MenuList.styled';

import { MenuListProps } from './types';

const MenuList = forwardRef<Element, MenuListProps>((props, ref) => {
  const { onClose, children, anchorEl = null, open, subHeader } = props;

  const { listProps, popoverProps, rootProps } = useMenuList({
    open,
    onClose,
    anchorEl,
  });

  const items = React.Children.map(children, (child) => {
    return child;
  });

  return (
    <RootWrap {...rootProps}>
      <Popover {...popoverProps} ref={ref}>
        <List {...listProps}>
          {subHeader}
          {items}
        </List>
      </Popover>
    </RootWrap>
  );
});

MenuList.displayName = 'MenuList';

export default MenuList;
