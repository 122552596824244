/* eslint-disable max-len */
import React, { FC } from 'react';
import { IconProps } from '../types';

export const Sidebar: FC<IconProps> = ({
  size = '20',
  color = '#445371',
  transform,
  viewBox = '0 0 20 20',
  className,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    transform={transform}
    viewBox={viewBox}
    className={className}
    fill="none">
    <path
      d="M2.66327 18C0.890585 18 0 17.1048 0 15.3058V4.69419C0 2.89517 0.890585 2 2.66327 2H17.3367C19.1179 2 20 2.90386 20 4.69419V15.3058C20 17.0961 19.1179 18 17.3367 18H2.66327ZM2.68024 16.6008H6.4631V3.39924H2.68024C1.83206 3.39924 1.36556 3.85986 1.36556 4.76372V15.2363C1.36556 16.1401 1.83206 16.6008 2.68024 16.6008ZM17.3198 3.39924H7.79474V16.6008H17.3198C18.1595 16.6008 18.6344 16.1401 18.6344 15.2363V4.76372C18.6344 3.85986 18.1595 3.39924 17.3198 3.39924ZM4.83461 6.62357H3.01103C2.75657 6.62357 2.53605 6.38892 2.53605 6.13688C2.53605 5.87615 2.75657 5.65019 3.01103 5.65019H4.83461C5.08906 5.65019 5.31807 5.87615 5.31807 6.13688C5.31807 6.38892 5.08906 6.62357 4.83461 6.62357ZM4.83461 8.87452H3.01103C2.75657 8.87452 2.53605 8.63987 2.53605 8.37914C2.53605 8.11841 2.75657 7.90114 3.01103 7.90114H4.83461C5.08906 7.90114 5.31807 8.11841 5.31807 8.37914C5.31807 8.63987 5.08906 8.87452 4.83461 8.87452ZM4.83461 11.1168H3.01103C2.75657 11.1168 2.53605 10.8995 2.53605 10.6388C2.53605 10.3781 2.75657 10.1521 3.01103 10.1521H4.83461C5.08906 10.1521 5.31807 10.3781 5.31807 10.6388C5.31807 10.8995 5.08906 11.1168 4.83461 11.1168Z"
      fill={color}
    />
  </svg>
);
