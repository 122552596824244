import { FC, MouseEvent, memo } from "react";

import {
  ProjectInfoText,
  ProjectTitle,
  ProjectJobTitle,
  EditIconButtonStyled,
  ProjectHover,
  ProjectInfo,
  ProjectListItemContainer,
} from "./Projects.styled";
import ProjectAvatar from "./ProjectAvatar";
import { useDraggingOver } from "./DragAndDrop";
import { IProjectItem } from "./types";

const ProjectItem: FC<IProjectItem> = ({
  id,
  name,
  roleName,
  icon,
  iconColor,
  isActive = false,
  isGlobalAdmin = false,
  onClickProjectEdit,
  onSelectProject,
  className,
}) => {
  const isDraggingOver = useDraggingOver();

  const handleProjectSelect = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    onSelectProject?.(id, isActive);
  };

  const handleEditProject = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();
    onClickProjectEdit(id);
  };

  return (
    <ProjectListItemContainer
      id={`list-project-id-${id}`}
      isActive={isActive}
      isDraggingOver={isDraggingOver}
      isGlobalAdmin={isGlobalAdmin}
      to={`/projects/${id}/main/news`}
      onClick={handleProjectSelect}
      className={className}>
      <ProjectHover>
        <ProjectInfo>
          <ProjectAvatar name={name} icon={icon} iconColor={iconColor} />
          <ProjectInfoText>
            <ProjectTitle>{name}</ProjectTitle>
            <ProjectJobTitle>{roleName ?? ""}</ProjectJobTitle>
          </ProjectInfoText>
        </ProjectInfo>

        {isGlobalAdmin && (
          <EditIconButtonStyled
            onClick={handleEditProject}
            className={className}
          />
        )}
      </ProjectHover>
    </ProjectListItemContainer>
  );
};

export default memo(ProjectItem);
