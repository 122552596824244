import { ReactNode } from 'react';
import { Placement } from '@popperjs/core';
import { IconName } from "../Icon/types";

export type Variant = 'filled' | 'outline' | 'filter';

type WrapperConfigKeys =
  'border' | 
  'backgroundColor' | 
  'width' | 
  'height' |
  'borderRadius' |
  'color' |
  'opacity' |
  'pointerEvents' |
  'hover';

type TextConfigKeys =
  'fontSize' |
  'lineHeight';

export type ViewWrapperConfig = Record<Variant, Record<WrapperConfigKeys, string>>;

export type ViewTextConfig = Record<Variant, Record<TextConfigKeys, string>>;

export type DirectionType = 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight' | 'bottom' | 'top';

export const DIRECTIONS = {
  topLeft: 'top-start',
  topRight: 'top-end',
  top: 'top',
  bottomLeft: 'bottom-start',
  bottomRight: 'bottom-end',
  bottom: 'bottom',
} as { [key: string]: Placement };

export interface IDropDown {
  variant: Variant;
  placeholder?: string;
  disabled?: boolean;
  rightIcon?: IconName;
  leftIcon?: IconName;
  direction?: DirectionType;
  onClick?: () => void;
  contentHeight?: string | number;
  contentWidth?: string | number;
  children: ReactNode
};

export type StyledDropDown = {
  variant: Variant; 
  disabled?: boolean;
  active?: boolean; 
  filled?: boolean;
}

export type StyledDropDownContent = {
  $contentHeight?: string | number;
  $contentWidth?: string | number;
  $isFitContent?: boolean;
}