// @ts-nocheck
import { call, put, all, takeLatest, select } from "redux-saga/effects";
import apiClient from "@Helpers/apiClient";
import * as actions from "./News.actions";

const MAIN_PATH = "/v2/projects";

function* mountNewsList({ payload: projectId }) {
  try {
    const { data } = yield call(
      apiClient.get,
      `${MAIN_PATH}/${projectId}/news`,
      { filterIds: [], size: 10, page: 0 }
    );
    yield put(actions.mountNewsList(data));
  } catch (e) {
    yield put(actions.catchNewsError(e));
    console.error("Ошибка при запросе списка новостей", e);
  }
}

function* fetchNewsList() {
  yield takeLatest(actions.fetchNewsList, mountNewsList);
}

function* mountMoreNews({ payload: pageNumber }) {
  try {
    const projectId = yield select(
      (state) => state.sideBar.projects.selectedProject.id
    );

    const { data } = yield call(
      apiClient.get,
      `${MAIN_PATH}/${projectId}/news`,
      { filterIds: [], size: 10, page: pageNumber }
    );
    yield put(actions.mountMoreNews(data));
  } catch (e) {
    yield put(actions.catchNewsError(e));
    console.error("Ошибка при запросе списка новостей", e);
  }
}

function* fetchMoreNews() {
  yield takeLatest(actions.fetchMoreNews, mountMoreNews);
}

function* mountNews({ payload: newsId }) {
  try {
    const projectId = yield select(
      (state) => state.sideBar.projects.selectedProject.id
    );

    const { data } = yield call(
      apiClient.get,
      `${MAIN_PATH}/${projectId}/news/${newsId}`
    );
    yield put(actions.mountNews(data));
  } catch (e) {
    yield put(actions.catchNewsError(e));
    console.error("Ошибка при запросе новости", e);
  }
}

function* fetchNews() {
  yield takeLatest(actions.fetchNews, mountNews);
}

function* markAsRead({ payload: newsId }) {
  try {
    const projectId = yield select(
      (state) => state.sideBar.projects.selectedProject.id
    );

    yield call(
      apiClient.post,
      `${MAIN_PATH}/${projectId}/news/${newsId}/read-receipt`
    );
    yield put(actions.markedNewsAsRead(newsId));
  } catch (e) {
    yield put(actions.catchNewsError(e));
    console.error("Ошибка при отправке запроса о прочтении новости", e);
  }
}

function* markNewsAsRead() {
  yield takeLatest(actions.markNewsAsRead, markAsRead);
}

export default function* newsSaga() {
  yield all([fetchNewsList(), fetchMoreNews(), fetchNews(), markNewsAsRead()]);
}
