/* eslint-disable max-len */
import React, { FC } from 'react';
import { IconProps } from '../types';

export const News: FC<IconProps> = ({
  size = '20',
  color = '#445371',
  transform,
  viewBox = '0 0 20 20',
  className,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    transform={transform}
    viewBox={viewBox}
    className={className}
    fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.13563 3.13546C3.40912 2.86197 3.78005 2.70832 4.16683 2.70832H12.5002C12.8869 2.70832 13.2579 2.86197 13.5314 3.13546C13.8048 3.40895 13.9585 3.77988 13.9585 4.16665V4.37499H15.8335C16.2203 4.37499 16.5912 4.52863 16.8647 4.80212C17.1382 5.07561 17.2918 5.44655 17.2918 5.83332V15C17.2918 15.6078 17.0504 16.1907 16.6206 16.6204C16.1908 17.0502 15.608 17.2917 15.0002 17.2917H5.8335C5.00469 17.2917 4.20984 16.9624 3.62379 16.3764C3.03774 15.7903 2.7085 14.9955 2.7085 14.1667V4.16665C2.7085 3.77988 2.86214 3.40895 3.13563 3.13546ZM12.9589 16.0417H5.8335C5.33622 16.0417 4.8593 15.8441 4.50767 15.4925C4.15604 15.1408 3.9585 14.6639 3.9585 14.1667V4.16665C3.9585 4.1114 3.98045 4.05841 4.01952 4.01934C4.05859 3.98027 4.11158 3.95832 4.16683 3.95832H12.5002C12.5554 3.95832 12.6084 3.98027 12.6475 4.01934C12.6865 4.05841 12.7085 4.1114 12.7085 4.16665V15C12.7085 15.3655 12.7958 15.722 12.9589 16.0417ZM13.9585 5.62499V15C13.9585 15.2763 14.0682 15.5412 14.2636 15.7366C14.4589 15.9319 14.7239 16.0417 15.0002 16.0417C15.2764 16.0417 15.5414 15.9319 15.7367 15.7366C15.9321 15.5412 16.0418 15.2763 16.0418 15V5.83332C16.0418 5.77807 16.0199 5.72508 15.9808 5.68601C15.9417 5.64694 15.8887 5.62499 15.8335 5.62499H13.9585ZM6.04183 6.66665C6.04183 6.32148 6.32165 6.04165 6.66683 6.04165H10.0002C10.3453 6.04165 10.6252 6.32148 10.6252 6.66665C10.6252 7.01183 10.3453 7.29165 10.0002 7.29165H6.66683C6.32165 7.29165 6.04183 7.01183 6.04183 6.66665ZM6.04183 9.99999C6.04183 9.65481 6.32165 9.37499 6.66683 9.37499H10.0002C10.3453 9.37499 10.6252 9.65481 10.6252 9.99999C10.6252 10.3452 10.3453 10.625 10.0002 10.625H6.66683C6.32165 10.625 6.04183 10.3452 6.04183 9.99999ZM6.04183 13.3333C6.04183 12.9881 6.32165 12.7083 6.66683 12.7083H10.0002C10.3453 12.7083 10.6252 12.9881 10.6252 13.3333C10.6252 13.6785 10.3453 13.9583 10.0002 13.9583H6.66683C6.32165 13.9583 6.04183 13.6785 6.04183 13.3333Z"
      fill={color}
    />
  </svg>
);
