import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { LogoutData, User, UserState } from "@/widgets/Header/types";

const initialState: UserState = {
  isLoading: false,
  user: null,
};

const NAMESPACE = "[User]";

export const fetchUser = createAction(`${NAMESPACE}/fetch`);
export const logoutUser = createAction<LogoutData>(`${NAMESPACE}/logout`);

const UserSlice = createSlice({
  name: "[User]",
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true;
    },
    finishLoading: (state) => {
      state.isLoading = false;
    },
    mountUser: (state, { payload }: PayloadAction<User>) => {
      state.user = payload;
    },
    cleanUser: (state) => {
      state.user = null;
    },
  },
});

export const actionsUser = {
  ...UserSlice.actions,
  fetchUser,
  logoutUser,
};

export const useActionsUser = () => {
  const dispatch = useDispatch();

  return useMemo(() => bindActionCreators(actionsUser, dispatch), [dispatch]);
};

export default UserSlice.reducer;
