import React, {
  useRef,
  useState,
  ChangeEventHandler,
  useCallback,
} from 'react';
import { StyledInput, Section, Container, ClearWrap } from './Input.styled';
import { InputProps } from './types';
import Icon from '../Icon';
import IconButton from '../IconButton/IconButton';

const Input = React.forwardRef<HTMLElement, InputProps>(
  (
    {
      disable,
      placeholder,
      icon,
      error,
      clearable,
      children,
      className,
      iconColor,
      value,
      onChange,
      onKeyDown,
      onKeyUp,
      onFocus,
      onClear,
      startAdornment = null,
      endAdornment = null,
      ...props
    },
    ref
  ) => {
    const [active, setActive] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);

    const handleClick = useCallback(() => {
      if (disable) return;

      inputRef.current?.focus();
    }, [disable]);
    const handleFocus = useCallback(() => {
      setActive(true);
    }, []);
    const handleBlur = useCallback(() => {
      setActive(false);
    }, []);
    const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
      (e) => {
        if (disable) return;

        onChange?.(e);
      },
      [onChange, disable]
    );

    const handleClear = useCallback(() => {
      onClear?.();
    }, [onClear]);

    return (
      <Section
        ref={ref}
        disable={disable}
        active={!disable && active}
        error={error}
        onClick={handleClick}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
        onFocus={onFocus}
        className={className}
        data-testid="input-section"
      >
        {icon && <Icon icon={icon} color={iconColor} />}
        {startAdornment}

        <Container>
          <StyledInput
            data-testid="input"
            ref={inputRef}
            onFocus={handleFocus}
            onBlur={handleBlur}
            value={value}
            onChange={handleChange}
            placeholder={placeholder}
            size={0}
            {...props}
          />
        </Container>
        {clearable && (
          <ClearWrap>
            <IconButton
              data-testid="clear-button"
              icon="CancelM"
              color="neutral400"
              onClick={handleClear}
            />
          </ClearWrap>
        )}
        {endAdornment}
        {children}
      </Section>
    );
  }
);

Input.displayName = 'Input';

export default Input;