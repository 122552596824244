import styled from 'styled-components';
import { COLORS } from '@suz/ui-utils/constants/Colors';

export const RootWrap = styled.div``;

export const List = styled.ul`
  background-color: ${COLORS.neutral0};
  padding: 12px 0px 4px 0px;
  border-radius: 8px;
  // TODO move to const
  box-shadow: 0px 0px 15px 0px #00000033;
`;