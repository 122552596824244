import { combineReducers } from "redux";

import menu from "../components/Menu/ducks/Menu.reducer";
import submenu from "../components/SubMenu/ducks/SubMenu.reducer";
import projects from "../components/ProjectsMenu/ducks/Projects.reducer";
import sidebar from "@Widgets/Sidebar/ducks/Sidebar.reducer";

const SideBarRootReducers = combineReducers({
  menu: menu,
  submenu: submenu,
  projects: projects,
  sidebar: sidebar,
});

export default SideBarRootReducers;
