/* eslint-disable max-len */
import React, { FC } from 'react';
import { IconProps } from '../types';

export const Pin: FC<IconProps> = ({
  size = '20',
  color = '#445371',
  transform,
  viewBox = '0 0 20 20',
  className,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    transform={transform}
    viewBox={viewBox}
    className={className}
    fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.15621 4.20623C5.29458 3.98935 5.48285 3.8113 5.70418 3.68805C5.92551 3.56479 6.17298 3.50018 6.42442 3.5L13.575 3.5C13.8265 3.50018 14.0745 3.56479 14.2958 3.68805C14.5172 3.8113 14.7054 3.98935 14.8438 4.20623C14.9822 4.42313 15.0663 4.67208 15.0887 4.93081C15.111 5.18954 15.0709 5.44995 14.9719 5.68873L13.9759 8.0899L15.5364 12.1208C15.6287 12.3589 15.6632 12.6171 15.6367 12.8721C15.6102 13.1273 15.5236 13.3718 15.3845 13.5844C15.2453 13.797 15.0579 13.9711 14.8385 14.0915C14.6191 14.2119 14.3746 14.2749 14.1262 14.2751H10.7827L10.7827 17.6914C10.7827 18.138 10.4323 18.5 10 18.5C9.56773 18.5 9.2173 18.138 9.2173 17.6914L9.2173 14.2751L5.87432 14.2751C5.62592 14.2749 5.38088 14.2119 5.16152 14.0915C4.94216 13.9711 4.7547 13.797 4.61554 13.5844C4.47639 13.3718 4.38978 13.1273 4.36328 12.8721C4.33681 12.6171 4.3712 12.3592 4.46344 12.1211L6.02409 8.0899L5.02817 5.68886C4.92918 5.45008 4.88901 5.18953 4.91136 4.93081C4.93372 4.67208 5.01785 4.42312 5.15621 4.20623ZM6.49413 5.1172L13.5059 5.1172L12.5297 7.47055C12.4511 7.6602 12.4093 7.86411 12.4069 8.07043C12.4045 8.27665 12.4415 8.48134 12.5156 8.67281L14.0584 12.6579H5.94162L7.48424 8.67318C7.55842 8.48171 7.59551 8.27665 7.59312 8.07043C7.59072 7.86407 7.54897 7.66023 7.47027 7.47055L6.49413 5.1172Z"
      fill={color}
    />
  </svg>
);
