
import { handleActions } from 'redux-actions';
import {
  connectToWS,
  exchangeMessagesWS,
  sendMessageToWS,
  subscribeWS,
  unsubscribeWS,
  disconnectWS,
} from './WS.actions';
import Stomp from 'stompjs';

const initialState = { websocket: null };

const webSocket = handleActions(
  {
    [connectToWS]: (state) => {
      const protocolWS = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
      const websocket = {
        client: Stomp.client(`${protocolWS}//${window.location.host}/data/websocket`),
        subStack: [],
        msgStack: [],
      };
      return { ...state, websocket };
    },
    [exchangeMessagesWS]: (state) => {
      const { websocket } = state;
      let { client, subStack, msgStack } = websocket;
      subStack = subStack.map(({ destination, callback, id }) => {
        if (client.connected) {
          client.subscribe(destination, callback, { id });
        } else {
          subStack.push({ destination, callback, id });
        }
      });
      msgStack.forEach((msg) => {
        client.send(msg.destination, msg.headers, msg.data);
      });
      return { ...state, websocket };
    },
    [sendMessageToWS]: (state, { payload: { destination, data, headers = {} } }) => {
      const { websocket } = state;
      if (websocket) {
        let { client, msgStack } = websocket;
        if (client.connected) {
          client.send(destination, headers, data);
        } else {
          msgStack.push({ destination, headers, data });
        }
        return { ...state, websocket };
      } else {
        return state;
      }
    },
    [subscribeWS]: (state, { payload: { destination, callback, id } }) => {
      const { websocket } = state;
      if (websocket) {
        let { client, subStack } = websocket;
        if (client.connected) {
          client.subscribe(destination, callback, { id });
        } else {
          subStack.push({ destination, callback, id });
        }
        return { ...state, websocket };
      } else {
        return state;
      }
    },
    [unsubscribeWS]: (state, { payload: name }) => {
      const { websocket } = state;
      for (let sub in websocket.client.subscriptions) {
        if (websocket.client.subscriptions.hasOwnProperty(sub) && sub.includes(name)) {
          websocket.client.unsubscribe(sub);
        }
      }
      return { ...state, websocket };
    },
    [disconnectWS]: (state) => {
      state.websocket.client.disconnect();
      return { ...state, websocket: null };
    },
  },
  initialState,
);

export default webSocket;
