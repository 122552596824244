import React, { forwardRef, useRef, useState } from "react";
import Popper from "@suz/ui-kit/Popper";
import { TooltipProps } from "./types";

import {
  StyledTopArrow,
  StyledBottomArrow,
  StyledLeftArrow,
  StyledRightArrow,
  StyledTooltip,
} from "./Tooltip.styled";

const Tooltip = forwardRef<HTMLDivElement, TooltipProps>((props, ref) => {
  const {
    open = false,
    title,
    fontSize,
    arrow,
    placement = "bottom",
    styles,
    renderAnchor,
  } = props;
  const popperRef = useRef<HTMLDivElement>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const childrenProps = {
    ref: setAnchorEl,
  };

  return (
    <>
      {renderAnchor(childrenProps)}
      <Popper
        ref={popperRef}
        open={open}
        placement={placement}
        anchorEl={anchorEl}
        styles={styles}
      >
        <StyledTooltip ref={ref} placement={placement} fontSize={fontSize}>
          {title}
          {arrow && placement === "top" ? (
            <StyledTopArrow placement={placement} />
          ) : null}
          {arrow && placement === "bottom" ? (
            <StyledBottomArrow placement={placement} />
          ) : null}
          {arrow && placement === "left" ? (
            <StyledLeftArrow placement={placement} />
          ) : null}
          {arrow && placement === "right" ? (
            <StyledRightArrow placement={placement} />
          ) : null}
        </StyledTooltip>
      </Popper>
    </>
  );
});

Tooltip.displayName = "Tooltip";

export default Tooltip;
