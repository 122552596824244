import { call, put, all, takeLatest, select } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";

import apiClient from "@Helpers/apiClient";
import { actionsProjects } from "./Projects.reducer";
import { getProjectsList } from "./Projects.selector";
import { IProject } from "../components/Projects/types";
import { compareWithStringify } from "@/widgets/Sidebar/utils";
import { ProjectsResponse } from "../types";

const PROJECTS_URL = "/v2/users/projects";

function* mountProjects({ type }: PayloadAction) {
  const prevProjects: IProject[] = yield select(getProjectsList());

  yield put(actionsProjects.startLoading(type));

  try {
    const { data: responseProjects }: ProjectsResponse = yield call(
      apiClient.get,
      PROJECTS_URL,
      {
        section: "projects",
      },
    );

    const isProjectsChanged = compareWithStringify({
      first: prevProjects,
      second: responseProjects,
    });

    if (isProjectsChanged) {
      yield put(actionsProjects.mountProjects(responseProjects));
    }
  } catch (e) {
    console.error("Ошибка при запросе списка проектов", e);
  } finally {
    yield put(actionsProjects.finishLoading(type));
  }
}

function* fetchProjects() {
  yield takeLatest(actionsProjects.fetchProjects, mountProjects);
}

export default function* fetchProjectsSaga() {
  yield all([fetchProjects()]);
}
