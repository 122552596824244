import styled, { css } from "styled-components";
import Icon, { StyledIconProps } from "@suz/ui-kit/Icon";
import { ClearButton } from "@Widgets/Sidebar/Sidebar.styled";
import { SubMenuWrapperProps, SubmenuTitleProps } from "./types";

export const SubMenuCreateIcon = styled(Icon).attrs<StyledIconProps>({
  icon: "PlusCircle",
  color: "#858fa3",
})``;

export const SubMenuArrowUpIcon = styled(Icon).attrs<StyledIconProps>({
  icon: "ArrowMUp",
  color: "#69758E",
})``;

export const SubMenuTitleButton = styled(ClearButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  gap: 10px;
  height: 20px;
  width: 20px;
`;

export const SubMenuListTitle = styled.span`
  display: flex;
  font-size: 12px;
  margin: 0px 8px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.36px;
  line-height: normal;
`;

export const SubMenuListTitleLeftPartContainer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: flex-start;
  align-items: center;

  :hover {
    ${SubMenuListTitle} {
      color: #445371;
    }
  }
`;

export const SubMenuListTitleContainer = styled.div<SubmenuTitleProps>`
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 36px;
  padding: 12px 16px 8px 24px;
  position: sticky;
  bottom: 0;
  left: 0;

  ${({ isBottomLocked }) =>
    isBottomLocked
      ? css`
          background: #eceef1;
          border-top: none;

          ${SubMenuListTitleLeftPartContainer} {
            user-select: auto;
            cursor: pointer;
          }

          ${SubMenuListTitle} {
            color: #69758e;
          }

          ${SubMenuTitleButton} {
            :not(:first-child) {
              margin-left: 16px;
            }
          }
        `
      : css`
          background: #f9fafb;
          border-top: 1px solid #eceef1;

          ${SubMenuListTitleLeftPartContainer} {
            user-select: none;
            cursor: auto;
          }

          ${SubMenuListTitle} {
            color: #445371;
          }

          ${SubMenuTitleButton} {
            :not(:first-child) {
              margin-left: 0;
            }
          }
        `}
`;

export const SubMenuWrapper = styled.div<SubMenuWrapperProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 276px;
  max-width: 396px;
  border-top: ${({ isBottomLocked }) =>
    isBottomLocked ? "1px solid #eceef1" : "none"};
  font-size: 14px;
  font-weight: 500;
  line-height: 142.857%;
`;

export const SubMenuDragContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  padding: 0 8px;
  box-sizing: border-box;
`;
