/* eslint-disable max-len */
import React, { FC } from 'react';
import { IconProps } from '../types';

export const Delete: FC<IconProps> = ({
  size = '20',
  color = '#445371',
  transform,
  viewBox = '0 0 20 20',
  className,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    transform={transform}
    viewBox={viewBox}
    className={className}
    fill="none">
    <path
      d="M3.125 5H1.875C1.70924 5 1.55027 4.93415 1.43306 4.81694C1.31585 4.69973 1.25 4.54076 1.25 4.375C1.25 4.20924 1.31585 4.05027 1.43306 3.93306C1.55027 3.81585 1.70924 3.75 1.875 3.75H6.875V1.87375C6.875 1.70799 6.94085 1.54902 7.05806 1.43181C7.17527 1.3146 7.33424 1.24875 7.5 1.24875H12.5C12.6658 1.24875 12.8247 1.3146 12.9419 1.43181C13.0592 1.54902 13.125 1.70799 13.125 1.87375V3.75H18.125C18.2908 3.75 18.4497 3.81585 18.5669 3.93306C18.6842 4.05027 18.75 4.20924 18.75 4.375C18.75 4.54076 18.6842 4.69973 18.5669 4.81694C18.4497 4.93415 18.2908 5 18.125 5H16.875V18.125C16.875 18.2908 16.8092 18.4497 16.6919 18.5669C16.5747 18.6842 16.4158 18.75 16.25 18.75H3.75C3.58424 18.75 3.42527 18.6842 3.30806 18.5669C3.19085 18.4497 3.125 18.2908 3.125 18.125V5ZM11.875 3.75V2.5H8.125V3.75H11.875ZM4.375 17.5H15.625V5H4.375V17.5ZM8.125 15C7.95924 15 7.80027 14.9342 7.68306 14.8169C7.56585 14.6997 7.5 14.5408 7.5 14.375V8.125C7.5 7.95924 7.56585 7.80027 7.68306 7.68306C7.80027 7.56585 7.95924 7.5 8.125 7.5C8.29076 7.5 8.44973 7.56585 8.56694 7.68306C8.68415 7.80027 8.75 7.95924 8.75 8.125V14.375C8.75 14.5408 8.68415 14.6997 8.56694 14.8169C8.44973 14.9342 8.29076 15 8.125 15ZM11.875 15C11.7092 15 11.5503 14.9342 11.4331 14.8169C11.3158 14.6997 11.25 14.5408 11.25 14.375V8.125C11.25 7.95924 11.3158 7.80027 11.4331 7.68306C11.5503 7.56585 11.7092 7.5 11.875 7.5C12.0408 7.5 12.1997 7.56585 12.3169 7.68306C12.4342 7.80027 12.5 7.95924 12.5 8.125V14.375C12.5 14.5408 12.4342 14.6997 12.3169 14.8169C12.1997 14.9342 12.0408 15 11.875 15Z"
      fill={color}
    />
  </svg>
);
