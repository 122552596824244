import { call, put, all, takeLatest } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";

import apiClient from "@Helpers/apiClient";
import { actionsMenu } from "./Menu.reducer";
import { MenuFetchPayload } from "../types";

function* fetchMenuList({
  payload: { projectId },
}: PayloadAction<MenuFetchPayload>) {
  yield put(actionsMenu.setLoading(true));

  try {
    const MENU_URL = `/v2/menu?projectId=${projectId}`;
    const { data } = yield call(apiClient.get, MENU_URL);
    yield put(actionsMenu.mountMenu(data));
  } catch (e) {
    const errorText = e instanceof Error ? e.message : "Неизвестная ошибка";
    yield put(actionsMenu.setError(errorText));
    console.error(errorText, e);
  } finally {
    yield put(actionsMenu.setLoading(false));
  }
}

function* fetchMenu() {
  yield takeLatest(actionsMenu.fetchMenu, fetchMenuList);
}

function* fetchNotificationsFeedbackCount() {
  try {
    const { data } = yield call(apiClient.get, "/v2/feedback/ticket-count");
    yield put(actionsMenu.setNotificationsFeedbackCount(data.count));
  } catch (e) {
    console.error(e);
  }
}

function* fetchMenuNotifications() {
  yield takeLatest(
    actionsMenu.fetchNotificationsFeedback,
    fetchNotificationsFeedbackCount
  );
}

export default function* menuSaga() {
  yield all([fetchMenu(), fetchMenuNotifications()]);
}
