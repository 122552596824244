import { createGlobalStyle } from 'styled-components';
// import '@suz/ui-kit/bundle.css';

const pathToFonts = 'style/fonts/Inter/';

export default createGlobalStyle`
    *, *::before, *::after {
        box-sizing: border-box;
    }
    * {
        margin: 0;
        padding: 0;
    }
    html, body {
        height: 100%;
        width: 100%;
    }
    img, picture, video, canvas, svg {
        display: block;
        max-width: 100%;
    }
    ul, ol {
        list-style: none;
    }
    input, button, textarea, select {
        font: inherit;
    }
    p, h1, h2, h3, h4, h5, h6 {
        overflow-wrap: break-word;
    }
    a {
        text-decoration: none;
    }
    @font-face {
        font-family: 'Inter', sans-serif;
        src: url(${pathToFonts}'Inter-Regular') format('woff2');
        font-weight: 400;
        font-style: normal;
    }
    @font-face {
        font-family: 'Inter', sans-serif;
        src: url(${pathToFonts}'Inter-Medium') format('woff2');
        font-weight: 500;
        font-style: normal;
    }
    @font-face {
        font-family: 'Inter', sans-serif;
        src: url(${pathToFonts}'Inter-SemiBold') format('woff2');
        font-weight: 600;
        font-style: normal;
    }
    @font-face {
        font-family: 'Inter', sans-serif;
        src: url(${pathToFonts}'Inter-Bold') format('woff2');
        font-weight: 700;
        font-style: normal;
    }

`;
