/* eslint-disable max-len */
import React, { FC } from 'react';
import { IconProps } from '../types';

export const Like: FC<IconProps> = ({
  size = '20',
  color = '#445371',
  transform,
  viewBox = '0 0 20 20',
  className,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    transform={transform}
    viewBox={viewBox}
    className={className}
    fill="none">
    <path
      d="M17.75 8.4C17.5158 8.11887 17.2228 7.89256 16.8917 7.73706C16.5605 7.58155 16.1992 7.50062 15.8333 7.5H12.0333L12.5 6.30833C12.6941 5.78657 12.7588 5.2255 12.6884 4.67326C12.618 4.12103 12.4147 3.5941 12.096 3.13769C11.7772 2.68127 11.3525 2.30899 10.8583 2.05278C10.364 1.79657 9.81503 1.66408 9.25833 1.66666C9.09803 1.667 8.94123 1.71356 8.80672 1.80077C8.67222 1.88797 8.56572 2.01212 8.5 2.15833L6.125 7.5H4.16666C3.50362 7.5 2.86774 7.76339 2.3989 8.23223C1.93006 8.70107 1.66666 9.33696 1.66666 10V15.8333C1.66666 16.4964 1.93006 17.1323 2.3989 17.6011C2.86774 18.0699 3.50362 18.3333 4.16666 18.3333H14.775C15.3598 18.3331 15.9261 18.1279 16.3753 17.7533C16.8244 17.3788 17.1281 16.8586 17.2333 16.2833L18.2917 10.45C18.3571 10.0895 18.3425 9.71898 18.2489 9.36473C18.1553 9.01047 17.985 8.68112 17.75 8.4ZM5.83333 16.6667H4.16666C3.94565 16.6667 3.73369 16.5789 3.57741 16.4226C3.42113 16.2663 3.33333 16.0543 3.33333 15.8333V10C3.33333 9.77898 3.42113 9.56702 3.57741 9.41074C3.73369 9.25446 3.94565 9.16666 4.16666 9.16666H5.83333V16.6667ZM16.6667 10.15L15.6083 15.9833C15.5728 16.1775 15.4696 16.3527 15.317 16.4778C15.1644 16.603 14.9723 16.6699 14.775 16.6667H7.5V8.50833L9.76666 3.40833C9.99998 3.47635 10.2167 3.592 10.403 3.74798C10.5894 3.90396 10.7414 4.09689 10.8495 4.31457C10.9576 4.53226 11.0193 4.76999 11.0309 5.01275C11.0424 5.25551 11.0035 5.49802 10.9167 5.725L10.475 6.91666C10.3809 7.16854 10.3491 7.43945 10.3824 7.70627C10.4157 7.97309 10.513 8.2279 10.6661 8.44895C10.8192 8.66999 11.0235 8.85072 11.2616 8.9757C11.4997 9.10067 11.7644 9.16619 12.0333 9.16666H15.8333C15.9558 9.16646 16.0767 9.19324 16.1876 9.24509C16.2985 9.29694 16.3966 9.37259 16.475 9.46666C16.5553 9.55943 16.6141 9.66878 16.6472 9.78691C16.6803 9.90503 16.687 10.029 16.6667 10.15Z"
      fill={color}
    />
  </svg>
);
