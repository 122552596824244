import React, { FC, useLayoutEffect, useState } from "react";

import {
  SearchWrapper,
  SearchIcon,
  SearchField,
  SearchHotKeys,
} from "./Search.styled";

const Search: FC = () => {
  const [showSearchModal, setshowSearchModal] = useState<boolean>(false);
  const [platform, setPlatform] = useState<string>("");

  const showModal = (event: React.SyntheticEvent | Event) => {
    event.preventDefault();
    alert("Здесь будет поисковая модалка");
    setshowSearchModal(true);
  };

  useLayoutEffect(() => {
    setPlatform(navigator.userAgent.toLowerCase());

    const handleKeyDown = (event: KeyboardEvent) => {
      const { code, ctrlKey, metaKey } = event;

      if (!(code === "KeyK" && (ctrlKey || metaKey))) {
        return;
      }

      showModal(event);
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleOpen = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    showModal(event);
  };

  const macosPlatforms = /(macintosh|macintel|macppc|mac68k|macos)/i;
  const isMacOs = macosPlatforms.test(platform);

  return (
    <SearchWrapper onClick={handleOpen}>
      <SearchIcon />
      <SearchField>Поиск</SearchField>
      <SearchHotKeys>{isMacOs ? "⌘ + K" : "Ctrl + K"}</SearchHotKeys>
    </SearchWrapper>
  );
};

export default Search;
