import React, { useRef, useEffect } from 'react';
import { useMenuListParams } from './types';

const useMenu = (params: useMenuListParams) => {
  const { open, onClose, anchorEl } = params;

  const rootRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<HTMLUListElement>(null);

  // TODO
  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      onClose?.(event, 'tabKeyDown');
    } else if (event.key === 'Escape') {
      onClose?.(event, 'escapeKeyDown');
    }
  }

  const handleClose = (event: object) => {
    onClose?.(event, 'backdropClick');
  };

  const prevOpen = useRef(open);

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      listRef.current?.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return {
    isOpen: open,
    rootProps: {
      ref: rootRef,
    },
    listProps: {
      ref: listRef,
      onKeyDown: handleListKeyDown,
    },
    popoverProps: {
      open,
      anchorEl,
      container: rootRef.current,
      onClose: handleClose,
    },
  };
};

export default useMenu;
