import { call, put, all, takeLatest } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";

import apiClient from "@Helpers/apiClient";
import { actionsLogin, LoginData } from "./Login.reducer";

const LOGIN_URL = "/v2/auth";

function* loginSaga({ type, payload }: PayloadAction<LoginData>) {
  yield put(actionsLogin.startLoading(type));
  yield put(actionsLogin.setError(false));

  const { body, success } = payload;

  try {
    // @ts-ignore
    const response = yield call(
      apiClient.post,
      LOGIN_URL,
      {
        login: body.login,
        password: body.pass,
      },
      {},
      new FormData()
    );
    if (response.status === 200) {
      success();
    } else {
      yield put(actionsLogin.setError(true));
    }
  } catch (e) {
    yield put(actionsLogin.setError(true));
    console.error("Ошибка при попытке аутентификации", e);
  } finally {
    yield put(actionsLogin.finishLoading(type));
  }
}

export default function* fetchLogin() {
  yield takeLatest(actionsLogin.login, loginSaga);
}
