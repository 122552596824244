
import React, { useEffect, useRef, useState, createContext } from 'react';
import { useSelector } from 'react-redux';
import Stomp from 'stompjs';

const protocolWS = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
const wsUrl = `${protocolWS}//${window.location.host}/data/websocket`;

export const WSContext = createContext();

export const WSProvider = ({ children }) => {
  const [data, setData] = useState({});
  const [watchers, setWatchers] = useState({});
  const [conected, setConected] = useState(false);
  const websocket = useRef();
  const userId = useSelector((store) => store.profile?.user?.id);

  // useEffect(() => {
  //   websocket.current = Stomp.client(wsUrl);
  //   window.testWs = websocket.current;
  //   websocket.current.heartbeat.outgoing = 25000;
  //   websocket.current.heartbeat.incoming = 25000;
  //   websocket.current.reconnect_delay = 0;
  //   websocket.current.debug = () => {};
  //   websocket.current.connect(
  //     { 'client-id': userId },
  //     () => setConected(websocket?.current?.connected),
  //     () => setConected(false),
  //   );

  //   return () => {
  //     websocket?.current?.disconnect();
  //   };
  // }, []);

  return (
    <WSContext.Provider value={{ conected, websocket, data, setData, watchers, setWatchers }}>
      {children}
    </WSContext.Provider>
  );
};
