import { combineReducers } from "redux";
import webSocket from "@/ws/ducks/WS.reducer";

import LoginReducers from "@Pages/LoginPage/ducks/Login.reducer"
import ProfileReducers from "@Widgets/Header/ducks/Profile.reducer";
import SideBarReducers from "@Widgets/Sidebar/ducks/Sidebar.root.reducer";
import MainReducers from "@Widgets/Main/ducks/Main.reducer";
import DocumentTitleReducers from "@Widgets/DocumentTitle/ducks/DocumentTitle.reducer";

const reducersCore = combineReducers({
  login: LoginReducers,
  profile: ProfileReducers,
  sideBar: SideBarReducers,
  main: MainReducers,
  documentTitle: DocumentTitleReducers,
  webSocket,
});

export default reducersCore;
