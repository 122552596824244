/**
 * Transforms a string based on the number.
 *
 * @param {number} number - The number to determine the string transformation.
 * @param {string[]} titles - List of word forms: One thing, Two things, Five things.
 * @returns {string} - The transformed string.
 */
export const textOfNumber = (number: number, titles: string[]): string => {
  const n: number = Math.abs(number);
  const index: number =
    n % 10 === 1 && n % 100 !== 11
      ? 0
      : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20)
        ? 1
        : 2;

  return titles[index];
};
