/* eslint-disable max-len */
import React, { FC } from 'react';
import { IconProps } from '../types';

export const Move: FC<IconProps> = ({
  size = '20',
  color = '#445371',
  transform,
  viewBox = '0 0 20 20',
  className,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    transform={transform}
    viewBox={viewBox}
    className={className}
    fill="none">
    <g clipPath="url(#clip0_2292_2034)">
      <path
        d="M10.2 12L8.575 13.625L9.975 15.025L14 11L9.975 6.975L8.575 8.375L10.2 10H6V12H10.2ZM2 18C1.45 18 0.979333 17.8043 0.588 17.413C0.196667 17.0217 0.000666667 16.5507 0 16V4C0 3.45 0.196 2.97933 0.588 2.588C0.98 2.19667 1.45067 2.00067 2 2H8L10 4H18C18.55 4 19.021 4.196 19.413 4.588C19.805 4.98 20.0007 5.45067 20 6V16C20 16.55 19.8043 17.021 19.413 17.413C19.0217 17.805 18.5507 18.0007 18 18H2ZM2 16H18V6H9.175L7.175 4H2V16Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_2292_2034">
        <rect width={size} height={size} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
