import styled, { css } from "styled-components";
import { NavLink } from "react-router-dom";

import Icon, { StyledIconProps } from "@suz/ui-kit/Icon";
import { EditButton } from "@Widgets/Sidebar/Sidebar.styled";
import ToggleSubCategoryButton from "../../../ToggleCategoryButton";
import { TCategoryIcon } from "./types";
import { SectionContainerProps } from "./types";

export const EditIcon = styled(Icon).attrs<StyledIconProps>({
  icon: "EditS",
  color: "#69758e",
  size: "16",
})`
  min-width: 16px;
`;

export const SubmenuSectionTitle = styled.h3`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: #303a4f;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 142.857%;
`;

export const SubCategoryIcon = styled(Icon).attrs<TCategoryIcon>(
  ({ iconType, isOpen }) => ({
    icon:
      iconType === "Folder" ? (isOpen ? "FolderOpened" : "Folder") : iconType,
    color: "#445371",
  })
)<TCategoryIcon>`
  min-width: 20px;
  margin: ${({ iconType }) => (iconType === "Folder" ? "0 -1px 0 1px" : "0")};
`;

export const ToggleSubMenuCategoryButton = styled(ToggleSubCategoryButton)``;

export const SubmenuSectionWrapper = styled.section`
  display: flex;
  margin-left: auto;
  align-self: center;
  align-items: center;
  gap: 8px;
`;

export const SubmenuSectionContainer = styled(NavLink)<SectionContainerProps>`
  position: relative;
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  padding: ${({ lvl }) => `8px 16px 8px ${16 + 8 * lvl}px`};
  border-radius: 8px;
  gap: 8px;

  ${({ isActive }) =>
    isActive
      ? css`
          background-color: #e6eeff;

          ${SubmenuSectionTitle} {
            color: #0055ff;
          }

          ${SubCategoryIcon} {
            path {
              fill: #0055ff;
            }
          }

          ${ToggleSubMenuCategoryButton} {
            path {
              fill: #3377ff;
            }
          }

          ${EditButton} {
            display: none;
          }
        `
      : css`
          &:hover {
            background: #eceef1;
            transition: all 0.2s ease-out;

            ${SubCategoryIcon} {
              path {
                fill: #303a4f;
              }
            }

            ${EditButton} {
              display: inline-block;
            }
          }
        `}
`;
