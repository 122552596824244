/* eslint-disable max-len */
import React, { FC } from 'react';
import { IconProps } from '../types';

export const SchemeSFilled: FC<IconProps> = ({
  size = '16',
  color = '#0055FF',
  transform,
  viewBox = '0 0 16 16',
  className,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    transform={transform}
    viewBox={viewBox}
    className={className}
    fill="none">
    <path
      d="M6.25 3.60107C6.25 3.04879 6.69772 2.60107 7.25 2.60107H8.75C9.30228 2.60107 9.75 3.04879 9.75 3.60107V5.10107C9.75 5.65336 9.30228 6.10107 8.75 6.10107H7.25C6.69772 6.10107 6.25 5.65336 6.25 5.10107V3.60107Z"
      fill={color}
    />
    <path
      d="M6.25 10.8989C6.25 10.3466 6.69772 9.89893 7.25 9.89893H8.75C9.30228 9.89893 9.75 10.3466 9.75 10.8989V12.3989C9.75 12.9512 9.30228 13.3989 8.75 13.3989H7.25C6.69772 13.3989 6.25 12.9512 6.25 12.3989V10.8989Z"
      fill={color}
    />
    <path
      d="M1 10.8989C1 10.3466 1.44772 9.89893 2 9.89893H3.5C4.05228 9.89893 4.5 10.3466 4.5 10.8989V12.3989C4.5 12.9512 4.05228 13.3989 3.5 13.3989H2C1.44772 13.3989 1 12.9512 1 12.3989V10.8989Z"
      fill={color}
    />
    <path
      d="M11.5 10.8989C11.5 10.3466 11.9477 9.89893 12.5 9.89893H14C14.5523 9.89893 15 10.3466 15 10.8989V12.3989C15 12.9512 14.5523 13.3989 14 13.3989H12.5C11.9477 13.3989 11.5 12.9512 11.5 12.3989V10.8989Z"
      fill={color}
    />
    <path d="M7.41663 3.36768H8.58329V10.3335H7.41663V3.36768Z" fill={color} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.7074 8.50049C3.43126 8.50049 3.2074 8.72435 3.2074 9.00049V10.3338H2.2074V9.00049C2.2074 8.17206 2.87897 7.50049 3.7074 7.50049H12.2926C13.121 7.50049 13.7926 8.17206 13.7926 9.00049V10.3338H12.7926V9.00049C12.7926 8.72435 12.5687 8.50049 12.2926 8.50049H3.7074Z"
      fill={color}
    />
  </svg>
);
