import { useState, useEffect } from 'react';
import { usePopper as useReactPopper } from 'react-popper';
import useControlledState from '@suz/ui-utils/hooks/useControlledState';

import { VirtualElement } from '@popperjs/core';

import { Anchor, usePopperParams } from './types';

const resolveAnchorEl = (
  anchorEl: Anchor | undefined
): HTMLElement | VirtualElement | null | undefined =>
  typeof anchorEl === 'function' ? anchorEl() : anchorEl;

const resolveContainer = (
  container: Element | (() => Element | null) | null | undefined
): Element | null | undefined =>
  typeof container === 'function' ? container() : container;

const usePopper = (params: usePopperParams) => {
  const { open, container, anchorEl, placement, children } = params;

  const [isOpen] = useControlledState(open);
  const [mountNode] = useControlledState<Element | null>(
    resolveContainer(container) || document.body
  );

  const [popperElem, setPopperElem] = useState<HTMLElement | null>(null);

  const { styles, attributes, update } = useReactPopper(
    resolveAnchorEl(anchorEl),
    popperElem,
    {
      placement,
      strategy: mountNode ? 'fixed' : 'absolute',
    }
  );

  useEffect(() => {
    update?.();
  }, [update, children, anchorEl]);

  return {
    isOpen,
    anchor: mountNode,
    rootProps: {
      ref: setPopperElem,
      style: styles.popper,
      ...attributes.popper,
    },
  };
};

export default usePopper;
