/* eslint-disable max-len */
import React, { FC } from 'react';
import { IconProps } from '../types';

export const StarFilled: FC<IconProps> = ({
  size = '20',
  color = '#445371',
  transform,
  viewBox = '0 0 20 20',
  className,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    transform={transform}
    viewBox={viewBox}
    className={className}
    fill="none">
    <path
      d="M10 15.27L14.15 17.78C14.91 18.24 15.84 17.56 15.64 16.7L14.54 11.98L18.21 8.8C18.88 8.22 18.52 7.12 17.64 7.05L12.81 6.64L10.92 2.18C10.58 1.37 9.42 1.37 9.08 2.18L7.19 6.63L2.36 7.04C1.48 7.11 1.12 8.21 1.79 8.79L5.46 11.97L4.36 16.69C4.16 17.55 5.09 18.23 5.85 17.77L10 15.27Z"
      fill={color}
    />
  </svg>
);
