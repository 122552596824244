import React, { forwardRef } from 'react';

import Modal from '../Modal';

import { PopoverProps } from './types';
import usePopover from './usePopover';

import { PopoverBody } from './Popover.styled';

const Popover = forwardRef<Element, PopoverProps>((props, ref) => {
  const {
    open,
    anchorEl,
    // TODO
    // container,
    anchorOrigin = {
      vertical: 'bottom',
      horizontal: 'left',
    },
    transformOrigin = {
      vertical: 'top',
      horizontal: 'left',
    },
    // TODO
    marginThreshold = 16,
    disableScrollLock = false,
    children,
    onClose,
  } = props;

  const { rootProps, bodyProps } = usePopover({
    open,
    anchorEl,
    anchorOrigin,
    transformOrigin,
    marginThreshold,
    disableScrollLock,
  });

  const Root = Modal;

  return (
    <Root {...rootProps} ref={ref} onClose={onClose} invisibleBackdrop>
      <PopoverBody {...bodyProps}>{children}</PopoverBody>
    </Root>
  );
});

Popover.displayName = 'Popover';

export default Popover;
