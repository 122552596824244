/* eslint-disable max-len */
import React, { FC } from 'react';
import { IconProps } from '../types';

export const Search: FC<IconProps> = ({
  size = '20',
  color = '#445371',
  transform,
  viewBox = '0 0 20 20',
  className,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    transform={transform}
    viewBox={viewBox}
    className={className}
    fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.33342 1.66666C4.65152 1.66666 1.66675 4.65143 1.66675 8.33332C1.66675 12.0152 4.65152 15 8.33342 15C9.87401 15 11.2925 14.4774 12.4215 13.5999L16.9108 18.0892C17.2363 18.4147 17.7639 18.4147 18.0893 18.0892C18.4148 17.7638 18.4148 17.2362 18.0893 16.9107L13.6 12.4214C14.4775 11.2925 15.0001 9.87392 15.0001 8.33332C15.0001 4.65143 12.0153 1.66666 8.33342 1.66666ZM3.33341 8.33332C3.33341 5.5719 5.57199 3.33332 8.33342 3.33332C11.0948 3.33332 13.3334 5.5719 13.3334 8.33332C13.3334 11.0947 11.0948 13.3333 8.33342 13.3333C5.57199 13.3333 3.33341 11.0947 3.33341 8.33332Z"
      fill={color}
    />
  </svg>
);
