import { useCallback, useEffect, useRef } from 'react';
import debounce from '@suz/ui-utils/utils/debounce';

import { usePopoverParams, PopoverOrigin } from './types';

type Rect = {
  height: number;
  width: number
}

function ownerDocument(node: Node | null | undefined): Document {
  return (node && node.ownerDocument) || document;
}

function ownerWindow(node: Node | null | undefined): Window {
  const doc = ownerDocument(node);
  return doc.defaultView || window;
}

export function getOffsetTop(
  rect: Rect,
  vertical: PopoverOrigin['vertical']
) {
  let offset = 0;

  if (typeof vertical === 'number') {
    offset = vertical;
  } else if (vertical === 'center') {
    offset = rect.height / 2;
  } else if (vertical === 'bottom') {
    offset = rect.height;
  }

  return offset;
}

export function getOffsetLeft(
  rect: Rect,
  horizontal: PopoverOrigin['horizontal']
) {
  let offset = 0;

  if (typeof horizontal === 'number') {
    offset = horizontal;
  } else if (horizontal === 'center') {
    offset = rect.width / 2;
  } else if (horizontal === 'right') {
    offset = rect.width;
  }

  return offset;
}

function getTransformOriginValue(transformOrigin: {
  vertical: number;
  horizontal: number;
}) {
  return [transformOrigin.horizontal, transformOrigin.vertical]
    .map((n) => (typeof n === 'number' ? `${n}px` : n))
    .join(' ');
}

function resolveAnchorEl(anchorEl: null | Element | (() => Element)) {
  return typeof anchorEl === 'function' ? anchorEl() : anchorEl;
}

const usePopover = (params: usePopoverParams) => {
  const {
    open,
    anchorEl,
    anchorOrigin,
    transformOrigin,
    marginThreshold,
    disableScrollLock,
  } = params;

  const bodyRef = useRef<HTMLDivElement>(null);

  const getAnchorOffset = useCallback(() => {
    const resolvedAnchorEl = resolveAnchorEl(anchorEl);

    // If an anchor element wasn't provided, just use the parent body element of this Popover
    const anchorElement =
      resolvedAnchorEl && resolvedAnchorEl.nodeType === 1
        ? resolvedAnchorEl
        : ownerDocument(bodyRef.current).body;
    const anchorRect = anchorElement.getBoundingClientRect();

    return {
      top: anchorRect.top + getOffsetTop(anchorRect, anchorOrigin.vertical),
      left:
        anchorRect.left + getOffsetLeft(anchorRect, anchorOrigin.horizontal),
    };
  }, [anchorEl, anchorOrigin.horizontal, anchorOrigin.vertical]);

  const getTransformOrigin = useCallback(
    (elemRect: Rect) => {
      return {
        vertical: getOffsetTop(elemRect, transformOrigin.vertical),
        horizontal: getOffsetLeft(elemRect, transformOrigin.horizontal),
      };
    },
    [transformOrigin.horizontal, transformOrigin.vertical]
  );

  const getPositioningStyle = useCallback(
    (element: HTMLDivElement) => {
      const elemRect = {
        width: element.offsetWidth,
        height: element.offsetHeight,
        
      };

      // Get the transform origin point on the element itself
      const elemTransformOrigin = getTransformOrigin(elemRect);

      // Get the offset of the anchoring element
      const anchorOffset = getAnchorOffset();

      // Calculate element positioning
      let top = anchorOffset.top - elemTransformOrigin.vertical;
      let left = anchorOffset.left - elemTransformOrigin.horizontal;
      const bottom = top + elemRect.height;
      const right = left + elemRect.width;

      // Use the parent window of the anchorEl if provided
      const containerWindow = ownerWindow(resolveAnchorEl(anchorEl));

      // Window thresholds taking required margin into account
      const heightThreshold =
        containerWindow.innerHeight - (marginThreshold || 0);
      const widthThreshold =
        containerWindow.innerWidth - (marginThreshold || 0);

      // Check if the vertical axis needs shifting
      if (marginThreshold !== null && top < marginThreshold) {
        const diff = top - marginThreshold;

        top -= diff;

        elemTransformOrigin.vertical += diff;
      } else if (marginThreshold !== null && bottom > heightThreshold) {
        const diff = bottom - heightThreshold;

        top -= diff;

        elemTransformOrigin.vertical += diff;
      }

      // Check if the horizontal axis needs shifting
      if (marginThreshold !== null && left < marginThreshold) {
        const diff = left - marginThreshold;
        left -= diff;
        elemTransformOrigin.horizontal += diff;
      } else if (right > widthThreshold) {
        const diff = right - widthThreshold;
        left -= diff;
        elemTransformOrigin.horizontal += diff;
      }

      return {
        top: `${Math.round(top)}px`,
        left: `${Math.round(left)}px`,
        transformOrigin: getTransformOriginValue(elemTransformOrigin),
      };
    },
    [anchorEl, getAnchorOffset, getTransformOrigin, marginThreshold]
  );

  const setPositioningStyles = useCallback(() => {
    const element = bodyRef.current;

    if (!element) {
      return;
    }

    const positioning = getPositioningStyle(element);

    if (positioning.top !== null) {
      element.style.top = positioning.top;
    }
    if (positioning.left !== null) {
      element.style.left = positioning.left;
    }

    element.style.transformOrigin = positioning.transformOrigin;
  }, [getPositioningStyle]);

  useEffect(() => {
    if (open) {
      setPositioningStyles();
    }
  });

  useEffect(() => {
    if (disableScrollLock) {
      window.addEventListener('scroll', setPositioningStyles);
    }
    return () => window.removeEventListener('scroll', setPositioningStyles);
  }, [anchorEl, disableScrollLock, setPositioningStyles]);

  useEffect(() => {
    if (!open) {
      return undefined;
    }

    const handleResize = debounce(() => {
      setPositioningStyles();
    });

    const containerWindow = ownerWindow(resolveAnchorEl(anchorEl));

    containerWindow.addEventListener('resize', handleResize);
    return () => {
      handleResize.clear();
      containerWindow.removeEventListener('resize', handleResize);
    };
  }, [anchorEl, open, setPositioningStyles]);

  return {
    rootProps: {
      open,
    },
    bodyProps: {
      ref: bodyRef,
    },
  };
};

export default usePopover;
