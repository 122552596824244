import { useCallback, useEffect, useLayoutEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";

import { useActionsProjects } from "./components/ProjectsMenu/ducks/Projects.reducer";
import { useActionsSidebar } from "./ducks/Sidebar.reducer";
import { getSelectedProject } from "./components/ProjectsMenu/ducks/Projects.selector";
import {
  getSidebarVisibility,
  getSidebarBlocksPositionAndHeight,
} from "./ducks/Sidebar.selector";

import Menu from "./components/Menu/Menu";
import SubMenu from "./components/SubMenu/SubMenu";
import ProjectsMenu from "./components/ProjectsMenu/ProjectsMenu";
import { SidebarWrapper, MenusWrapper, SidebarScroll } from "./Sidebar.styled";
import { ChangeHeightHandlerActions, SidebarBlockIds } from "./types";

const Sidebar = () => {
  let { projectId = "5" } = useParams();

  const sidebarShow = useSelector(getSidebarVisibility());
  const {
    isSubmenuTitleBottomLocked,
    menuBlockClientHeigth,
    subMenuBlockClientHeigth,
  } = useSelector(getSidebarBlocksPositionAndHeight());

  const project = useSelector(getSelectedProject());

  const projectsActions = useActionsProjects();
  const sidebarActions = useActionsSidebar();

  const sidebarScrollRef = useRef<any>(null);
  const scrollBoxBlock = sidebarScrollRef?.current?.view as HTMLElement;
  const menuBlock = scrollBoxBlock?.firstChild as HTMLElement;
  const submenuTitleBlock = scrollBoxBlock?.childNodes[1] as HTMLElement;
  const submenuBlock = scrollBoxBlock?.lastChild as HTMLElement;

  const handleScroll = (e: React.UIEvent<HTMLElement>) => {
    sidebarActions.toggleSubmenuTitleBottomLock(
      menuBlockClientHeigth > submenuTitleBlock?.offsetTop
    );
  };

  const handleScrollTopCategories = () => {
    if (sidebarScrollRef.current && isSubmenuTitleBottomLocked) {
      sidebarScrollRef.current.scrollTop(menuBlockClientHeigth);
    }
  };

  const changeHeightHandler = useCallback(
    (entries: ResizeObserverEntry[]) =>
      requestAnimationFrame(() => {
        const actionsMap: ChangeHeightHandlerActions = {
          menuBlock: {
            currentHeight: menuBlockClientHeigth,
            action: sidebarActions.setMenuBlockClientHeigth,
          },
          submenuBlock: {
            currentHeight: subMenuBlockClientHeigth,
            action: sidebarActions.setSubMenuBlockClientHeigth,
          },
        };

        entries.forEach((entry) => {
          const { id, clientHeight } = entry.target;

          if (id in actionsMap) {
            const { currentHeight, action } = actionsMap[id as SidebarBlockIds];

            if (clientHeight !== currentHeight) action(clientHeight);
          }
        });
      }),
    [menuBlockClientHeigth, subMenuBlockClientHeigth, sidebarActions]
  );

  useLayoutEffect(() => {
    const blockHeightObserver = new ResizeObserver(changeHeightHandler);

    [menuBlock, submenuBlock].forEach((element) => {
      if (element) blockHeightObserver.observe(element);
    });

    return () => blockHeightObserver.disconnect();
  }, [menuBlock, submenuBlock, changeHeightHandler]);

  useEffect(() => {
    projectsActions.fetchSelectedProject({ projectId: +projectId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectsActions]);

  return (
    <SidebarWrapper sidebarShow={sidebarShow}>
      <ProjectsMenu project={project} />
      {project?.id && (
        <MenusWrapper>
          <SidebarScroll
            ref={sidebarScrollRef}
            autoHide
            onScroll={handleScroll}>
            <Menu projectId={project.id} />
            <SubMenu
              projectId={project.id}
              isSubmenuTitleBottomLocked={isSubmenuTitleBottomLocked}
              onScrollTopCategories={handleScrollTopCategories}
            />
          </SidebarScroll>
        </MenusWrapper>
      )}
    </SidebarWrapper>
  );
};

export default Sidebar;
