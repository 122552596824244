import { createSelector } from "@reduxjs/toolkit";
import { SideBarRootState } from "@/widgets/Sidebar/types";

export const mainMenuState = (state: SideBarRootState) => {
  return state.sideBar.menu;
};

export const getMenu = () =>
  createSelector(mainMenuState, (state) => state.menu);

export const getLoadingStatusMenu = () =>
  createSelector(mainMenuState, (state) => state.isLoading);

export const getMenuUnfolded = () =>
  createSelector(mainMenuState, (state) => state.isUnfoldMenu);

export const getMenuItemOpened = () =>
  createSelector(mainMenuState, (state) => state.menuItemOpenedId);

export const getNotificationCount = () =>
  createSelector(mainMenuState, (state) => state.notificationsFeedbackCount);
