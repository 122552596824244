import { FC, memo } from "react";
import {
  ToggleCategoryButton,
  ChevroneIcon,
} from "@Widgets/Sidebar/Sidebar.styled";
import { ToggleButtonProps } from "./types";

const ToggleSubCategoryButton: FC<ToggleButtonProps> = ({
  lvl = 0,
  onToggleOpen,
  isOpen = false,
  className,
}) => (
  <ToggleCategoryButton lvl={lvl} onClick={onToggleOpen} className={className}>
    <ChevroneIcon isOpenMenu={isOpen} />
  </ToggleCategoryButton>
);

export default memo(ToggleSubCategoryButton);
