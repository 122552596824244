import { HTMLAttributes, CSSProperties, ReactNode } from 'react';
import { SPACING_TYPES, SHADOWS_TYPES } from '@suz/ui-utils/types/general';

export const CORNERS_ROUNDING = {
  none: '0px',
  xs: '4px',
  s: '8px',
  m: '16px',
  ls: '24px',
  xl: '36px',
} as const;

export type CORNERS_ROUNDING = keyof typeof CORNERS_ROUNDING;
export interface IBox extends HTMLAttributes<HTMLDivElement> {
    /** Задает фиксированную ширину */
    w?: string;
    /** Задает фиксированную высоту */
    h?: string;
    /** Задает внутренний отступ 
      * @param none - 0px 
      * @param xs - 6px
      * @param s - 12px
      * @param m - 18px
      * @param l - 24px
      * @param xl - 30px
      * @param xxl - 36px
    */
    spacing?: SPACING_TYPES;

    /** Задает внешний отступ сверху
      * @param none - 0px 
      * @param xs - 6px
      * @param s - 12px
      * @param m - 18px
      * @param l - 24px
      * @param xl - 30px
      * @param xxl - 36px
    */
    spacingTop?: SPACING_TYPES;

    /** Задает внешний отступ снизу 
      * @param none - 0px 
      * @param xs - 6px
      * @param s - 12px
      * @param m - 18px
      * @param l - 24px
      * @param xl - 30px
      * @param xxl - 36px
    */
    spacingBottom?: SPACING_TYPES;

    /** Задает скругление углов компонента (borderRadius) 
      * @param xs - 4px
      * @param s - 8px
      * @param m - 16px
      * @param l - 24px
      * @param xl - 36px
    */
    cornersRounding?: CORNERS_ROUNDING;

    /** Задает тень для компонента 
     *  @param bottomS
     *  @param bottomM
     *  @param bottomL
     *  @param bottomXL
     *  @param topS
     *  @param topM
     *  @param topL
     *  @param topXL
    */
    shadow?: SHADOWS_TYPES;

    /** Задает дополнительные стили для компонента */
    style?: CSSProperties;

    /** Дочерние элементы компонента */
    children?: ReactNode;
    /** Цвет фона */
    bgColor?: string;
  }