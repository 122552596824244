/* eslint-disable max-len */
import React, { FC } from 'react';
import { IconProps } from '../types';

export const Favorites: FC<IconProps> = ({
  size = '20',
  color = '#445371',
  transform,
  viewBox = '0 0 20 20',
  className,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    transform={transform}
    viewBox={viewBox}
    className={className}
    fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.86472 2.13798C9.31149 1.16939 10.6881 1.16939 11.1349 2.13798L13.1474 6.50112L17.9189 7.06685C18.9781 7.19245 19.4035 8.50168 18.6204 9.22589L15.0927 12.4882L16.0291 17.201C16.237 18.2472 15.1233 19.0563 14.1925 18.5353L9.99979 16.1884L5.80704 18.5353C4.87628 19.0563 3.76258 18.2472 3.97046 17.201L4.90688 12.4882L1.37919 9.22589L1.94499 8.61407L1.37919 9.22589C0.59607 8.50168 1.02146 7.19245 2.08071 7.06685L6.8522 6.50112L8.86472 2.13798ZM14.922 12.646C14.9218 12.6462 14.9217 12.6464 14.9215 12.6465L14.922 12.646ZM14.6386 12.3406L15.0472 12.2594L14.6386 12.3406ZM9.99979 3.65633L8.26794 7.41098C8.08586 7.80574 7.71175 8.07755 7.28005 8.12873L3.17399 8.61557L6.2097 11.4229C6.52887 11.7181 6.67177 12.1579 6.58704 12.5842L5.78122 16.6398L9.38924 14.6202C9.76858 14.4078 10.231 14.4078 10.6103 14.6202L14.2184 16.6398L13.4125 12.5843C13.3278 12.1579 13.4707 11.7181 13.7899 11.4229L16.8256 8.61557L12.7195 8.12873C12.2878 8.07755 11.9137 7.80574 11.7316 7.41098L9.99979 3.65633Z"
      fill={color}
    />
  </svg>
);
