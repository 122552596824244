/* eslint-disable max-len */
import React, { FC } from 'react';
import { IconProps } from '../types';

export const SchemeFilled: FC<IconProps> = ({
  size = '20',
  color = '#445371',
  transform,
  viewBox = '0 0 20 20',
  className,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    transform={transform}
    viewBox={viewBox}
    className={className}
    fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.5 4.375C7.5 3.87772 7.69754 3.40081 8.04917 3.04917C8.40081 2.69754 8.87772 2.5 9.375 2.5H10.625C11.1223 2.5 11.5992 2.69754 11.9508 3.04917C12.3025 3.40081 12.5 3.87772 12.5 4.375V5.625C12.5 6.12228 12.3025 6.59919 11.9508 6.95083C11.5992 7.30246 11.1223 7.5 10.625 7.5V8.75H13.75C13.9158 8.75 14.0747 8.81585 14.1919 8.93306C14.3092 9.05027 14.375 9.20924 14.375 9.375V10.625C14.375 10.7908 14.3092 10.9497 14.1919 11.0669C14.0747 11.1842 13.9158 11.25 13.75 11.25C13.5842 11.25 13.4253 11.1842 13.3081 11.0669C13.1908 10.9497 13.125 10.7908 13.125 10.625V10H6.875V10.625C6.875 10.7908 6.80915 10.9497 6.69194 11.0669C6.57473 11.1842 6.41576 11.25 6.25 11.25C6.08424 11.25 5.92527 11.1842 5.80806 11.0669C5.69085 10.9497 5.625 10.7908 5.625 10.625V9.375C5.625 9.20924 5.69085 9.05027 5.80806 8.93306C5.92527 8.81585 6.08424 8.75 6.25 8.75H9.375V7.5C8.87772 7.5 8.40081 7.30246 8.04917 6.95083C7.69754 6.59919 7.5 6.12228 7.5 5.625V4.375ZM3.75 14.375C3.75 13.8777 3.94754 13.4008 4.29917 13.0492C4.65081 12.6975 5.12772 12.5 5.625 12.5H6.875C7.37228 12.5 7.84919 12.6975 8.20083 13.0492C8.55246 13.4008 8.75 13.8777 8.75 14.375V15.625C8.75 16.1223 8.55246 16.5992 8.20083 16.9508C7.84919 17.3025 7.37228 17.5 6.875 17.5H5.625C5.12772 17.5 4.65081 17.3025 4.29917 16.9508C3.94754 16.5992 3.75 16.1223 3.75 15.625V14.375ZM11.25 14.375C11.25 13.8777 11.4475 13.4008 11.7992 13.0492C12.1508 12.6975 12.6277 12.5 13.125 12.5H14.375C14.8723 12.5 15.3492 12.6975 15.7008 13.0492C16.0525 13.4008 16.25 13.8777 16.25 14.375V15.625C16.25 16.1223 16.0525 16.5992 15.7008 16.9508C15.3492 17.3025 14.8723 17.5 14.375 17.5H13.125C12.6277 17.5 12.1508 17.3025 11.7992 16.9508C11.4475 16.5992 11.25 16.1223 11.25 15.625V14.375Z"
      fill={color}
    />
  </svg>
);
