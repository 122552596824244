import { createSelector } from '@reduxjs/toolkit';
import { SideBarRootState } from '@/widgets/Sidebar/types';

export const projectsState = (state: SideBarRootState) => {
  return state.sideBar.projects;
};

export const getLoadingProjectsStatus = () =>
  createSelector(projectsState, (state) =>
    state.isLoading.find((i) => i === '[Projects] fetchProjects'),
  );

export const getLoadingSelectedProjectStatus = () =>
  createSelector(projectsState, (state) =>
    state.isLoading.find((i) => i === '[Projects] fetchSelectedProject'),
  );

export const getProjectsList = () => createSelector(projectsState, (state) => state.projects);

export const getSelectedProject = () =>
  createSelector(projectsState, (state) => state.selectedProject);
