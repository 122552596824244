/* eslint-disable max-len */
import React, { FC } from 'react';
import { IconProps } from '../types';

export const PlusCircle: FC<IconProps> = ({
  size = '20',
  color = '#445371',
  transform,
  viewBox = '0 0 20 20',
  className,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    transform={transform}
    viewBox={viewBox}
    className={className}
    fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 3.33334C6.31811 3.33334 3.33334 6.31811 3.33334 10C3.33334 13.6819 6.31811 16.6667 10 16.6667C13.6819 16.6667 16.6667 13.6819 16.6667 10C16.6667 6.31811 13.6819 3.33334 10 3.33334ZM1.66667 10C1.66667 5.39763 5.39763 1.66667 10 1.66667C14.6024 1.66667 18.3333 5.39763 18.3333 10C18.3333 14.6024 14.6024 18.3333 10 18.3333C5.39763 18.3333 1.66667 14.6024 1.66667 10ZM10 5.83334C10.4602 5.83334 10.8333 6.20643 10.8333 6.66667V9.16667H13.3333C13.7936 9.16667 14.1667 9.53977 14.1667 10C14.1667 10.4602 13.7936 10.8333 13.3333 10.8333H10.8333V13.3333C10.8333 13.7936 10.4602 14.1667 10 14.1667C9.53977 14.1667 9.16667 13.7936 9.16667 13.3333V10.8333H6.66667C6.20644 10.8333 5.83334 10.4602 5.83334 10C5.83334 9.53977 6.20644 9.16667 6.66667 9.16667H9.16667V6.66667C9.16667 6.20643 9.53977 5.83334 10 5.83334Z"
      fill={color}
    />
  </svg>
);
