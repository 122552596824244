import SimpleBar from "simplebar-react";
import styled from "styled-components";
import "simplebar-react/dist/simplebar.min.css";

interface ScrollBoxProps {
  width?: number;
}

const ScrollBox = styled(SimpleBar).attrs<ScrollBoxProps>(
  ({ width = 280 }) => ({
    autoHide: false,
    scrollbarMinSize: 112,
    style: {
      maxHeight: "calc(100vh - 120px)",
      width: `${width}px`,
    },
  })
)<ScrollBoxProps>`
  .simplebar-content {
    display: flex;
  }

  .simplebar-scrollbar.simplebar-visible {
    background: #858fa37e;
    border-radius: 16px;
    width: 6px;
    left: 7px;
    &:hover {
      background: #4453717f;
    }
  }
  .simplebar-track.simplebar-vertical {
    pointer-events: auto;
    width: 20px;
  }
  .simplebar-track.simplebar-horizontal {
    visibility: hidden !important;
    display: none;
  }
  .simplebar-scrollbar.simplebar-visible:before {
    display: none;
  }
`;

export default ScrollBox;
