/* eslint-disable max-len */
import React, { FC } from 'react';
import { IconProps } from '../types';

export const DragVertical: FC<IconProps> = ({
  size = '20',
  color = '#445371',
  transform,
  viewBox = '0 0 20 20',
  className,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    transform={transform}
    viewBox={viewBox}
    className={className}
    fill="none">
    <path
      d="M9.16667 6.25C9.16667 6.94036 8.60702 7.5 7.91667 7.5C7.22631 7.5 6.66667 6.94036 6.66667 6.25C6.66667 5.55964 7.22631 5 7.91667 5C8.60702 5 9.16667 5.55964 9.16667 6.25Z"
      fill={color}
    />
    <path
      d="M9.16667 10.4167C9.16667 11.107 8.60702 11.6667 7.91667 11.6667C7.22631 11.6667 6.66667 11.107 6.66667 10.4167C6.66667 9.72631 7.22631 9.16667 7.91667 9.16667C8.60702 9.16667 9.16667 9.72631 9.16667 10.4167Z"
      fill={color}
    />
    <path
      d="M9.16667 14.5833C9.16667 15.2737 8.60702 15.8333 7.91667 15.8333C7.22631 15.8333 6.66667 15.2737 6.66667 14.5833C6.66667 13.893 7.22631 13.3333 7.91667 13.3333C8.60702 13.3333 9.16667 13.893 9.16667 14.5833Z"
      fill={color}
    />
    <path
      d="M13.3333 6.25C13.3333 6.94036 12.7737 7.5 12.0833 7.5C11.393 7.5 10.8333 6.94036 10.8333 6.25C10.8333 5.55964 11.393 5 12.0833 5C12.7737 5 13.3333 5.55964 13.3333 6.25Z"
      fill={color}
    />
    <path
      d="M13.3333 10.4167C13.3333 11.107 12.7737 11.6667 12.0833 11.6667C11.393 11.6667 10.8333 11.107 10.8333 10.4167C10.8333 9.72631 11.393 9.16667 12.0833 9.16667C12.7737 9.16667 13.3333 9.72631 13.3333 10.4167Z"
      fill={color}
    />
    <path
      d="M13.3333 14.5833C13.3333 15.2737 12.7737 15.8333 12.0833 15.8333C11.393 15.8333 10.8333 15.2737 10.8333 14.5833C10.8333 13.893 11.393 13.3333 12.0833 13.3333C12.7737 13.3333 13.3333 13.893 13.3333 14.5833Z"
      fill={color}
    />
  </svg>
);
