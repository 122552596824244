/* eslint-disable max-len */
import React, { FC } from 'react';
import { IconProps } from '../types';

export const ArrowSLeft: FC<IconProps> = ({
  size = '16',
  color = '#69758E',
  transform,
  viewBox = '0 0 16 16',
  className,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    transform={transform}
    viewBox={viewBox}
    className={className}
    fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.92259 5.24409C10.248 5.56952 10.248 6.09716 9.92259 6.4226L8.01184 8.33334L9.92259 10.2441C10.248 10.5695 10.248 11.0972 9.92259 11.4226C9.59715 11.748 9.06951 11.748 8.74408 11.4226L6.24408 8.9226C5.91864 8.59716 5.91864 8.06952 6.24408 7.74409L8.74408 5.24409C9.06951 4.91865 9.59715 4.91865 9.92259 5.24409Z"
      fill={color}
    />
  </svg>
);
