/* eslint-disable max-len */
import React, { FC } from 'react';
import { IconProps } from '../types';

export const GroupAdd: FC<IconProps> = ({
  size = '20',
  color = '#445371',
  transform,
  viewBox = '0 0 20 20',
  className,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    transform={transform}
    viewBox={viewBox}
    className={className}
    fill="none">
    <path
      d="M7.5 10V15M10 12.5H5"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.33334 6.66667H11.6667C12.5872 6.66667 13.3333 7.41286 13.3333 8.33333V16.6667C13.3333 17.5871 12.5872 18.3333 11.6667 18.3333H3.33334C2.41287 18.3333 1.66668 17.5871 1.66668 16.6667V8.33333C1.66668 7.41286 2.41287 6.66667 3.33334 6.66667Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.6667 13.3333C17.5833 13.3333 18.3333 12.5833 18.3333 11.6667V3.33333C18.3333 2.41667 17.5833 1.66667 16.6667 1.66667H8.33334C7.41668 1.66667 6.66668 2.41667 6.66668 3.33333"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
