import { FC, memo } from "react";
import { useSelector } from "react-redux";

import { useActionsSidebar } from "../../ducks/Sidebar.reducer";
import { getActiveProjectsMenuItem } from "../../ducks/Sidebar.selector";
import { useActionsMenu } from "./ducks/Menu.reducer";
import { getMenuItemOpened } from "./ducks/Menu.selector";

import {
  MenuItemContainer,
  MenuItemIcon,
  MenuItemWrapper,
  ToggleMenuCategoryButton,
  MenuItemTitle,
  MenuCounter,
} from "./Menu.styled";
import Menu from "./Menu";
import { MenuItemProps } from "./types";

const MenuItem: FC<MenuItemProps> = ({
  id,
  projectId,
  linkEnd = "/",
  title = "",
  icon,
  hasChildren = false,
  isLastSectionBlock,
  lvl = 0,
  notificationCount,
}) => {
  const openedId = useSelector(getMenuItemOpened());
  const isOpenItem = openedId === id;
  const activeId = useSelector(getActiveProjectsMenuItem());

  const menuActions = useActionsMenu();
  const sidebarActions = useActionsSidebar();

  const handleToggleOpen = () => {
    sidebarActions.setActiveMenuItem({ isSubmenu: false, id });

    if (hasChildren) menuActions.toggleMenuItemOpened(isOpenItem ? null : id);
  };

  return (
    <MenuItemWrapper
      id={`projects_menu_item_${id}`}
      isLastSectionBlock={!!isLastSectionBlock}>
      <MenuItemContainer
        to={`/projects/${projectId}/${linkEnd}`}
        onClick={handleToggleOpen}
        isActive={activeId === id}>
        {hasChildren && <ToggleMenuCategoryButton isOpen={isOpenItem} />}
        <MenuItemIcon icon={icon} />
        <MenuItemTitle>{title}</MenuItemTitle>
        {notificationCount && <MenuCounter count={notificationCount} />}
      </MenuItemContainer>
      {isOpenItem && <Menu parentId={id} lvl={lvl + 1} projectId={projectId} />}
    </MenuItemWrapper>
  );
};

export default memo(MenuItem);
