/* eslint-disable max-len */
import React, { FC } from 'react';
import { IconProps } from '../types';

export const Files: FC<IconProps> = ({
  size = '20',
  color = '#445371',
  transform,
  viewBox = '0 0 20 20',
  className,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    transform={transform}
    viewBox={viewBox}
    className={className}
    fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.66666 5.83333C1.66666 4.91286 2.41285 4.16666 3.33332 4.16666L7.98815 4.16666C8.43017 4.16666 8.8541 4.34226 9.16666 4.65482L12.0118 7.5C12.3244 7.81256 12.5 8.23648 12.5 8.67851V16.6667C12.5 17.5871 11.7538 18.3333 10.8333 18.3333H3.33332C2.41285 18.3333 1.66666 17.5871 1.66666 16.6667L1.66666 5.83333ZM7.98815 5.83333L3.33332 5.83333L3.33332 16.6667H10.8333V8.67851L7.98815 5.83333Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.49999 3.33333C7.49999 2.41286 8.24618 1.66666 9.16666 1.66666L13.8215 1.66666C14.2635 1.66666 14.6874 1.84226 15 2.15482L17.8452 5C18.1577 5.31256 18.3333 5.73648 18.3333 6.17851V14.1667C18.3333 15.0871 17.5871 15.8333 16.6667 15.8333L11.6667 15.8333C11.2064 15.8333 10.8333 15.4602 10.8333 15C10.8333 14.5398 11.2064 14.1667 11.6667 14.1667L16.6667 14.1667V6.17851L13.8215 3.33333L9.16666 3.33333L9.16666 5C9.16666 5.46023 8.79356 5.83333 8.33332 5.83333C7.87309 5.83333 7.49999 5.46023 7.49999 5V3.33333Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.49999 4.16666C7.96023 4.16666 8.33332 4.53976 8.33332 5L8.33332 8.33333H11.6667C12.1269 8.33333 12.5 8.70643 12.5 9.16666C12.5 9.6269 12.1269 10 11.6667 10L8.33332 10C7.41285 10 6.66666 9.25381 6.66666 8.33333V5C6.66666 4.53976 7.03975 4.16666 7.49999 4.16666Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.3333 1.66666C13.7936 1.66666 14.1667 2.03976 14.1667 2.5V5.83333H17.5C17.9602 5.83333 18.3333 6.20643 18.3333 6.66666C18.3333 7.1269 17.9602 7.5 17.5 7.5L14.1667 7.5C13.2462 7.5 12.5 6.75381 12.5 5.83333L12.5 2.5C12.5 2.03976 12.8731 1.66666 13.3333 1.66666Z"
      fill={color}
    />
  </svg>
);
