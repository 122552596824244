/* eslint-disable max-len */
import React, { FC } from 'react';
import { IconProps } from '../types';

export const ArrowLUp: FC<IconProps> = ({
  size = '20',
  color = '#445371',
  transform,
  viewBox = '0 0 20 20',
  className,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    transform={transform}
    viewBox={viewBox}
    className={className}
    fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.41075 6.91074C9.73619 6.5853 10.2638 6.5853 10.5893 6.91074L16.4226 12.7441C16.748 13.0695 16.748 13.5971 16.4226 13.9226C16.0972 14.248 15.5695 14.248 15.2441 13.9226L10 8.67851L4.75593 13.9226C4.4305 14.248 3.90286 14.248 3.57742 13.9226C3.25198 13.5972 3.25198 13.0695 3.57742 12.7441L9.41075 6.91074Z"
      fill={color}
    />
  </svg>
);
