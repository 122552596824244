/* eslint-disable max-len */
import React, { FC } from 'react';
import { IconProps } from '../types';

export const ArrowMDown: FC<IconProps> = ({
  size = '20',
  color = '#445371',
  transform,
  viewBox = '0 0 20 20',
  className,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    transform={transform}
    viewBox={viewBox}
    className={className}
    fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.07742 7.74408C6.40286 7.41864 6.9305 7.41864 7.25593 7.74408L10 10.4882L12.7441 7.74408C13.0695 7.41864 13.5972 7.41864 13.9226 7.74408C14.248 8.06951 14.248 8.59715 13.9226 8.92259L10.5893 12.2559C10.2638 12.5814 9.73619 12.5814 9.41075 12.2559L6.07742 8.92259C5.75198 8.59715 5.75198 8.06951 6.07742 7.74408Z"
      fill={color}
    />
  </svg>
);
