import { useCallback, useEffect, useRef, useState } from 'react';

type ItemProps = {
  id: string | number;
  imagePath?: string;
  [key: string]: any;
};

export type UseImageErrorChecker<T> = (
  items: T[],
  imagePathKey?: keyof T,
  getImagePathFromItemKey?: (imagePath: string) => string | undefined,
) => Record<string | number, boolean>;

export const useImagesErrorChecker: UseImageErrorChecker<ItemProps> = (
  items,
  imagePathKey = 'imagePath',
  getImagePathFromItemKey = (imagePath) => imagePath,
) => {
  const [imageErrors, setImageErrors] = useState<Record<string, boolean>>({});
  const checkedImagesRef = useRef<Record<string, boolean>>({});

  const updateImageErrorStatus = useCallback((id: string | number, errorStatus: boolean) => {
    setImageErrors((prevErrors) => ({
      ...prevErrors,
      [id]: errorStatus,
    }));
    checkedImagesRef.current[id] = true;
  }, []);

  const getImagePath = useCallback(
    (item: ItemProps) => {
      return item?.[imagePathKey] ? getImagePathFromItemKey(item[imagePathKey]) : undefined;
    },
    [getImagePathFromItemKey, imagePathKey],
  );

  useEffect(() => {
    items.forEach((item) => {
      const imagePath = getImagePath(item);
      const id = item.id;
      if (imagePath && !checkedImagesRef.current[id]) {
        const img = new Image();
        img.src = imagePath;
        img.onload = () => updateImageErrorStatus(id, false);
        img.onerror = () => updateImageErrorStatus(id, true);
      }
    });
  }, [items, updateImageErrorStatus, getImagePathFromItemKey, getImagePath]);

  return imageErrors;
};
