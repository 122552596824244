import styled from "styled-components";
import Icon, { StyledIconProps } from "@suz/ui-kit/Icon";
import { FONTS } from "@/uikit/constants/Fonts";

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0;
  right: 0;
  width: 100%;
  background-color: #f9fafb;
  font-family: Inter, sans-serif;
  font-size: ${FONTS.body4strong.fs};
  line-height: ${FONTS.body4strong.lh};
  font-weight: ${FONTS.body4strong.weight};
  border-bottom: 1px solid #e4e5ea;
`;

export const BreadCrumbsPanel = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  padding: 12px 24px;
`;

export const UserPanel = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 12px 24px;
`;

export const UserPanelIconWrapper = styled.div`
  position: relative;
  display: flex;
  padding: 8px;
`;

const HeaderIcon = styled(Icon).attrs<StyledIconProps>({ color: "#445371" })`
  min-width: 20px;
  min-height: 20px;
  cursor: pointer;
`;

export const SidebarToggleIcon = styled(HeaderIcon).attrs({
  icon: "Sidebar",
})``;

export const NotificationsIcon = styled(HeaderIcon).attrs({
  icon: "Notifications",
})``;

export const SettingsIcon = styled(HeaderIcon).attrs({ icon: "Settings" })``;

export const ArrowDownIcon = styled(HeaderIcon).attrs({ icon: "ArrowMDown" })``;
