import { call, put, all, takeLatest, select } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";

import apiClient from "@Helpers/apiClient";
import { ProjectsResponse } from "../types";
import { actionsProjects } from "./Projects.reducer";
import { IProject } from "../components/Projects/types";
import { getProjectsList } from "./Projects.selector";
import { compareWithStringify } from "@/widgets/Sidebar/utils";

const PROJECTS_URL = "/v2/users/projects/orders";

function* changeOrderProjectsList({
  payload: changedProjectsOptimistic,
}: PayloadAction<IProject[]>) {
  const prevProjects: IProject[] = yield select(getProjectsList());

  yield put(actionsProjects.mountProjects(changedProjectsOptimistic));

  const body = { order: changedProjectsOptimistic.map(({ id }) => id) };

  try {
    const { data: changedProjectsResponse }: ProjectsResponse = yield call(
      apiClient.patch,
      PROJECTS_URL,
      {},
      body
    );

    const isProjectsChanged = compareWithStringify({
      first: prevProjects,
      second: changedProjectsResponse,
    });

    if (isProjectsChanged) {
      yield put(actionsProjects.mountProjects(changedProjectsResponse));
    }
  } catch (e) {
    yield put(actionsProjects.revertOrderProjects(prevProjects));

    if (e instanceof Error) {
      console.error(
        "Ошибка при изменении порядка в списке проектов",
        e.message
      );
    } else {
      console.error(
        "Неизвестная ошибка при изменении порядка в списке проектов",
        e
      );
    }
  }
}

function* changeOrderProjects() {
  yield takeLatest(
    actionsProjects.changeOrderProjects,
    changeOrderProjectsList
  );
}

export default function* changeOrderProjectsSaga() {
  yield all([changeOrderProjects()]);
}
