/* eslint-disable max-len */
import React, { FC } from 'react';
import { IconProps } from '../types';

export const ReportAccept: FC<IconProps> = ({
  size = '20',
  color = '#72747A',
  transform,
  viewBox = '0 0 20 20',
  className,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    transform={transform}
    viewBox={viewBox}
    className={className}
    fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.0893 10.2441C13.4148 10.5695 13.4148 11.0972 13.0893 11.4226L9.75601 14.7559C9.59972 14.9122 9.38776 15 9.16675 15C8.94573 15 8.73377 14.9122 8.57749 14.7559L6.91082 13.0892C6.58538 12.7638 6.58539 12.2361 6.91083 11.9107C7.23627 11.5853 7.76391 11.5853 8.08934 11.9107L9.16676 12.9881L11.9108 10.2441C12.2363 9.91866 12.7639 9.91867 13.0893 10.2441Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.00017 3.3333L5.00016 16.6666H15.0001V7.32652L11.3027 3.3333L5.00017 3.3333ZM3.3335 3.33329C3.3335 2.41283 4.07968 1.66663 5.00016 1.66663H11.3028C11.767 1.66663 12.2102 1.8603 12.5256 2.20094C12.5256 2.20093 12.5257 2.20095 12.5256 2.20094L16.2231 6.19416C16.5084 6.50229 16.6668 6.90676 16.6668 7.32652V16.6667C16.6668 17.5871 15.9206 18.3333 15.0002 18.3333H5.00016C4.07971 18.3333 3.3335 17.5871 3.3335 16.6667V3.33329Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.8334 1.66663C11.2936 1.66663 11.6667 2.03972 11.6667 2.49996V6.66663H15.8334C16.2936 6.66663 16.6667 7.03972 16.6667 7.49996C16.6667 7.9602 16.2936 8.3333 15.8334 8.3333H11.6667C10.7461 8.3333 10 7.58701 10 6.66663V2.49996C10 2.03972 10.3731 1.66663 10.8334 1.66663Z"
      fill={color}
    />
  </svg>
);
