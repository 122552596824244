/* eslint-disable max-len */
import React, { FC } from 'react';
import { IconProps } from '../types';

export const ArrowLleft: FC<IconProps> = ({
  size = '20',
  color = '#445371',
  transform,
  viewBox = '0 0 20 20',
  className
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    transform={transform}
    viewBox={viewBox}
    className={className}
    fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.0893 3.57741C13.4147 3.90285 13.4147 4.43049 13.0893 4.75592L7.84519 10L13.0893 15.2441C13.4147 15.5695 13.4147 16.0972 13.0893 16.4226C12.7638 16.748 12.2362 16.748 11.9108 16.4226L6.07742 10.5893C5.75198 10.2638 5.75198 9.73618 6.07742 9.41075L11.9108 3.57741C12.2362 3.25198 12.7638 3.25198 13.0893 3.57741Z"
      fill={color}
    />
  </svg>
);
