/* eslint-disable max-len */
import React, { FC } from 'react';
import { IconProps } from '../types';

export const Home: FC<IconProps> = ({
  size = '20',
  color = '#445371',
  transform,
  viewBox = '0 0 20 20',
  className,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    transform={transform}
    viewBox={viewBox}
    className={className}
    fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.90249 2.35302C9.53087 1.80319 10.4691 1.80319 11.0975 2.35302L16.9308 7.45718C17.2925 7.77366 17.5 8.23087 17.5 8.71148V15.8333C17.5 16.7538 16.7538 17.5 15.8333 17.5H12.5C11.5795 17.5 10.8333 16.7538 10.8333 15.8333V12.5H9.16667V15.8333C9.16667 16.7538 8.42048 17.5 7.5 17.5H4.16667C3.24619 17.5 2.5 16.7538 2.5 15.8333V8.71148C2.5 8.23087 2.70747 7.77366 3.06916 7.45718L8.90249 2.35302ZM10 3.60731L4.16667 8.71148V15.8333H7.5V12.5C7.5 11.5795 8.24619 10.8333 9.16667 10.8333H10.8333C11.7538 10.8333 12.5 11.5795 12.5 12.5V15.8333H15.8333V8.71148L10 3.60731Z"
      fill={color}
    />
  </svg>
);
