import { all } from 'redux-saga/effects';

import login from "@Pages/LoginPage/ducks/root.saga"
import projectsSaga from '@/widgets/Sidebar/components/ProjectsMenu/ducks/Projects.saga';
import menuSaga from '@Widgets/Sidebar/components/Menu/ducks/Menu.saga';
import subMenuSaga from '@Widgets/Sidebar/components/SubMenu/ducks/SubMenu.saga';
import userSaga from '@Widgets/Header/components/User/ducks/User.root.saga';

import newsSaga from '@Widgets/Main/components/News/ducks/News.saga';
import updatesSaga from '@Widgets/Main/components/Updates/ducks/Updates.saga';

export default function* rootSaga() {
  yield all([
    login(),
    menuSaga(),
    subMenuSaga(),
    projectsSaga(),
    newsSaga(),
    updatesSaga(),
    userSaga(),
  ]);
}
