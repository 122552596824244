/* eslint-disable max-len */
import React, { FC } from 'react';
import { IconProps } from '../types';

export const Folder: FC<IconProps> = ({
  size = '20',
  color = '#445371',
  transform,
  viewBox = '0 0 20 20',
  className,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    transform={transform}
    viewBox={viewBox}
    className={className}
    fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.0182 4.96863C3.6316 4.96863 3.3182 5.28203 3.3182 5.66863V14.3314C3.3182 14.718 3.6316 15.0314 4.0182 15.0314H15.9818C16.3684 15.0314 16.6818 14.718 16.6818 14.3314V6.81137C16.6818 6.42477 16.3684 6.11137 15.9818 6.11137H8.97027C8.48251 6.11137 8.00737 5.9563 7.61351 5.66858L6.83985 5.10339C6.71998 5.01582 6.57538 4.96863 6.42693 4.96863H4.0182ZM1.7182 5.66863C1.7182 4.39837 2.74795 3.36863 4.0182 3.36863H6.42693C6.91469 3.36863 7.38983 3.52369 7.78369 3.81142L8.55734 4.37661C8.67721 4.46418 8.82182 4.51137 8.97027 4.51137H15.9818C17.2521 4.51137 18.2818 5.54112 18.2818 6.81137V14.3314C18.2818 15.6016 17.2521 16.6314 15.9818 16.6314H4.0182C2.74795 16.6314 1.7182 15.6016 1.7182 14.3314V5.66863Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.7182 9.20739C1.7182 8.21327 2.52409 7.40739 3.5182 7.40739H16.4818C17.4759 7.40739 18.2818 8.21327 18.2818 9.20739V14.8314C18.2818 15.8255 17.4759 16.6314 16.4818 16.6314H3.5182C2.52409 16.6314 1.7182 15.8255 1.7182 14.8314V9.20739ZM3.5182 9.00739C3.40774 9.00739 3.3182 9.09693 3.3182 9.20739V14.8314C3.3182 14.9418 3.40774 15.0314 3.5182 15.0314H16.4818C16.5923 15.0314 16.6818 14.9418 16.6818 14.8314V9.20739C16.6818 9.09693 16.5923 9.00739 16.4818 9.00739H3.5182Z"
      fill={color}
    />
  </svg>
);
