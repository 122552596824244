/* eslint-disable max-len */
import React, { FC } from 'react';
import { IconProps } from '../types';

export const EyeClosed: FC<IconProps> = ({
  size = '20',
  color = '#445371',
  transform,
  viewBox = '0 0 20 20',
  className,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    transform={transform}
    viewBox={viewBox}
    className={className}
    fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.2473 9.21443C3.09045 8.80383 2.63124 8.59639 2.2191 8.75094C1.80541 8.90607 1.5958 9.3672 1.75094 9.7809L2.5 9.5C1.75094 9.7809 1.75112 9.7814 1.75132 9.78191L1.75173 9.78301L1.75267 9.78549L1.75501 9.79157L1.7615 9.80807C1.76665 9.82097 1.77347 9.83764 1.78205 9.85783C1.7992 9.8982 1.82344 9.95271 1.85556 10.0193C1.91974 10.1524 2.01566 10.3345 2.14973 10.549C2.41765 10.9777 2.84005 11.5393 3.46851 12.0979C4.73939 13.2276 6.80332 14.3 10 14.3C13.1967 14.3 15.2606 13.2276 16.5315 12.0979C17.16 11.5393 17.5824 10.9777 17.8503 10.549C17.9843 10.3345 18.0803 10.1524 18.1444 10.0193C18.1766 9.95271 18.2008 9.8982 18.218 9.85783C18.2265 9.83764 18.2334 9.82097 18.2385 9.80807L18.245 9.79157L18.2473 9.78549L18.2483 9.78301L18.2487 9.78191C18.2489 9.7814 18.2491 9.7809 17.5 9.5L18.2491 9.7809C18.4042 9.3672 18.1946 8.90607 17.7809 8.75094C17.3688 8.59638 16.9095 8.80384 16.7527 9.21446C16.7526 9.21458 16.7528 9.2143 16.7527 9.21446L16.7454 9.23201C16.7377 9.25023 16.7238 9.28167 16.7032 9.32444C16.6619 9.41007 16.5938 9.5405 16.4935 9.701C16.2927 10.0223 15.965 10.4607 15.4685 10.9021C14.4894 11.7724 12.8033 12.7 10 12.7C7.19668 12.7 5.51061 11.7724 4.53149 10.9021C4.03495 10.4607 3.70735 10.0223 3.50652 9.701C3.40621 9.5405 3.33807 9.41007 3.29679 9.32444C3.27617 9.28167 3.26232 9.25023 3.25458 9.23201L3.2473 9.21443ZM16.7513 9.21818L16.7521 9.21596L16.7527 9.21446"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.86804 16.1075C10.3099 16.1075 10.668 16.4656 10.668 16.9075V18.5075C10.668 18.9493 10.3099 19.3075 9.86804 19.3075C9.42621 19.3075 9.06804 18.9493 9.06804 18.5075V16.9075C9.06804 16.4656 9.42621 16.1075 9.86804 16.1075Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.20005 15.3072C3.81741 15.0863 3.32814 15.2174 3.10723 15.6L2.30723 16.9856C2.08631 17.3683 2.21741 17.8575 2.60005 18.0785C2.98268 18.2994 3.47196 18.1683 3.69287 17.7856L4.49287 16.4C4.71378 16.0174 4.58268 15.5281 4.20005 15.3072Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.6 15.3072C15.9826 15.0863 16.4719 15.2174 16.6928 15.6L17.4928 16.9856C17.7137 17.3683 17.5826 17.8575 17.2 18.0785C16.8174 18.2994 16.3281 18.1683 16.1072 17.7856L15.3072 16.4C15.0863 16.0174 15.2174 15.5281 15.6 15.3072Z"
      fill={color}
    />
  </svg>
);
