import React from 'react';
import { createPortal } from 'react-dom';
import useModal from './useModal';

import { ModalWrap, Backdrop } from './Modal.styled';

import { ModalProps } from './types';
// @ts-ignore
const Modal = React.forwardRef<Element, ModalProps>((props, forwardedRef) => {
  const {
    open,
    container,
    keepMounted,
    children,
    disableEscapeKeyDown,
    onClose,
    // TODO
    disableScrollLock = false,
    invisibleBackdrop = false,
  } = props;
  const { rootProps, backdropProps, mountNode } = useModal({
    open,
    disableEscapeKeyDown,
    onClose,
    rootRef: forwardedRef,
    children,
    container,
    disableScrollLock,
  });

  return mountNode
    ? createPortal(
      <>
        {keepMounted || open ? (
          <ModalWrap data-testid="root" {...rootProps}>
            <Backdrop
              data-testid="backdrop"
              {...backdropProps}
              invisible={invisibleBackdrop}
            >
              {children}
            </Backdrop>
          </ModalWrap>
        ) : null}
      </>,
      mountNode
    )
    : mountNode;
});

Modal.displayName = 'Modal';

export default Modal;
