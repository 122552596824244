import React from 'react';
import styled from 'styled-components';

const LoaderWrapperContent = styled.div<{ h: string, white: string }>`
  position: sticky;
  bottom: 0;
  &&& {
    height: ${({ h = '100vh' }) => `${h}`};
  }
  width: 100% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    z-index: 1000;
    g {
      circle {
        fill: ${({ white }) => (white ? '#fff' : '#444A53')};
      }
    }
  }
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    background: rgba(255, 255, 255, 0.8);
  }
`;

const Loader = () => (
 <div>{"Loading..."}</div>
);

export default Loader;
