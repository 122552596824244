import { useRef, useState, useEffect } from 'react';

const useControlledState = <T>(
  outerState: T,
  callback?: (state: T) => void
): [T, (newState: T, callback?: () => void) => void] => {

  const [state, setState] = useState<T>(outerState);

  const callbackRef = useRef<((state: T) => void) | null>(null);

  const setNewState = (newState: T) =>{
    if (callback) callbackRef.current = callback;

    setState(newState);
  };

  useEffect(() => {
    if (callbackRef.current) {
      callbackRef.current(state);
      callbackRef.current = null;
    }
  }, [state]);

  useEffect(() => {
    if (outerState !== undefined) {
      setState(outerState);
    }
  }, [outerState]);

  return [state, setNewState];
};

export default useControlledState;
