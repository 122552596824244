/* eslint-disable max-len */
import React, { FC } from 'react';
import { IconProps } from '../types';

export const Communication: FC<IconProps> = ({
  size = '20',
  color = '#445371',
  transform,
  viewBox = '0 0 20 20',
  className,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    transform={transform}
    viewBox={viewBox}
    className={className}
    fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5 6.66667C12.5 6.20643 12.8731 5.83333 13.3333 5.83333H16.6667C17.5871 5.83333 18.3333 6.57952 18.3333 7.5V16.6667C18.3333 16.9898 18.1465 17.2838 17.854 17.4211C17.5615 17.5583 17.216 17.5141 16.9675 17.3077L14.1897 15H7.5C6.57952 15 5.83333 14.2538 5.83333 13.3333V10.8333C5.83333 10.3731 6.20643 10 6.66667 10C7.1269 10 7.5 10.3731 7.5 10.8333V13.3333H14.1897C14.5788 13.3333 14.9555 13.4694 15.2548 13.718L16.6667 14.891V7.5H13.3333C12.8731 7.5 12.5 7.1269 12.5 6.66667Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.66667 4.16667C1.66667 3.24619 2.41286 2.5 3.33333 2.5H12.5C13.4205 2.5 14.1667 3.24619 14.1667 4.16667V10C14.1667 10.9205 13.4205 11.6667 12.5 11.6667L5.81025 11.6667L3.03252 13.9743C2.78398 14.1808 2.43848 14.225 2.14597 14.0877C1.85346 13.9504 1.66667 13.6565 1.66667 13.3333V4.16667ZM12.5 4.16667L3.33333 4.16667V11.5576L4.74522 10.3847C5.04446 10.1361 5.42123 10 5.81025 10H12.5V4.16667Z"
      fill={color}
    />
  </svg>
);
