import styled from 'styled-components';
import { IBox, CORNERS_ROUNDING } from './types';
import { SPACING, SHADOWS } from '@suz/ui-utils/constants/defaultValues';

export const StyledBox = styled.div<IBox>`
    width: ${({ w }) => w};
    height: ${({ h }) => h};
    padding: ${({ spacing = 'none' }) => SPACING[spacing]};
    box-shadow: ${({ shadow = 'none' }) => SHADOWS[shadow]};
    margin-top: ${({ spacingTop = 'none' }) => SPACING[spacingTop]};
    margin-bottom: ${({ spacingBottom = 'none' }) => SPACING[spacingBottom]};
    border-radius: ${({ cornersRounding = 'none' }) => CORNERS_ROUNDING[cornersRounding]};
    background-color: ${({ bgColor }) => bgColor ? bgColor : 'transparent'};
`;