import React, { forwardRef } from 'react';
import ButtonBase from '../ButtonBase';
import { StyledButton, ButtonText } from './Button.styled';
import { IButton } from './types';
import Icon from '@suz/ui-kit/Icon';

const Button = forwardRef<HTMLButtonElement, IButton>(
  (
    { children, startIcon, renderStartIcon, endIcon, renderEndIcon, className, ...props },
    ref
  ) => {
    return (
      <StyledButton ref={ref} as={ButtonBase} {...props}>
        {renderStartIcon || (startIcon && <Icon icon={startIcon} className={className} />)}

        {children && <ButtonText className={className}>{children}</ButtonText>}

        {renderEndIcon || (endIcon && <Icon icon={endIcon} className={className} />)}
      </StyledButton>
    );
  }
);

Button.displayName = 'Button';

export default Button;
