/* eslint-disable max-len */
import React, { FC } from 'react';
import { IconProps } from '../types';

export const Archive: FC<IconProps> = ({
  size = '20',
  color = '#445371',
  transform,
  viewBox = '0 0 20 20',
  className,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    transform={transform}
    viewBox={viewBox}
    className={className}
    fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.80001 7.84294V14.9557C4.80001 15.3423 5.11341 15.6557 5.50001 15.6557H14.5C14.8866 15.6557 15.2 15.3423 15.2 14.9557V7.84294H16.8V14.9557C16.8 16.226 15.7703 17.2557 14.5 17.2557H5.50001C4.22976 17.2557 3.20001 16.226 3.20001 14.9557V7.84294H4.80001Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.52069 4.54429C1.52069 3.55018 2.32658 2.74429 3.32069 2.74429H16.6793C17.6734 2.74429 18.4793 3.55018 18.4793 4.54429V6.84294C18.4793 7.83705 17.6734 8.64294 16.6793 8.64294H3.32069C2.32658 8.64294 1.52069 7.83705 1.52069 6.84294V4.54429ZM3.32069 4.34429C3.21023 4.34429 3.12069 4.43384 3.12069 4.54429V6.84294C3.12069 6.9534 3.21023 7.04294 3.32069 7.04294H16.6793C16.7898 7.04294 16.8793 6.9534 16.8793 6.84294V4.54429C16.8793 4.43384 16.7898 4.34429 16.6793 4.34429H3.32069Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.69973 10.9631C7.69973 11.4342 8.08166 11.8162 8.55279 11.8162H11.5079C11.9791 11.8162 12.361 11.4342 12.361 10.9631C12.361 10.492 11.9791 10.11 11.5079 10.11H8.55279C8.08166 10.11 7.69973 10.492 7.69973 10.9631Z"
      fill={color}
    />
  </svg>
);
