/* eslint-disable max-len */
import React, { FC } from 'react';
import { IconProps } from '../types';

export const Filters: FC<IconProps> = ({
  size = '20',
  color = '#445371',
  transform,
  viewBox = '0 0 20 20',
  className,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    transform={transform}
    viewBox={viewBox}
    className={className}
    fill="none">
    <path
      d="M15.8333 1.66667H4.16667C3.50363 1.66667 2.86774 1.93006 2.3989 2.3989C1.93006 2.86774 1.66667 3.50363 1.66667 4.16667V5.14167C1.66655 5.48579 1.73747 5.82623 1.875 6.14167V6.19167C1.99273 6.45914 2.15949 6.70222 2.36667 6.90833L7.5 12.0083V17.5C7.49972 17.6416 7.53553 17.781 7.60406 17.9049C7.67259 18.0289 7.77157 18.1333 7.89167 18.2083C8.02428 18.2905 8.17731 18.3338 8.33333 18.3333C8.46378 18.3326 8.59223 18.3012 8.70833 18.2417L12.0417 16.575C12.1791 16.5058 12.2947 16.3998 12.3756 16.2689C12.4565 16.138 12.4995 15.9872 12.5 15.8333V12.0083L17.6 6.90833C17.8072 6.70222 17.9739 6.45914 18.0917 6.19167V6.14167C18.2407 5.82869 18.323 5.48815 18.3333 5.14167V4.16667C18.3333 3.50363 18.0699 2.86774 17.6011 2.3989C17.1323 1.93006 16.4964 1.66667 15.8333 1.66667ZM11.075 11.075C10.9978 11.1529 10.9367 11.2452 10.8952 11.3467C10.8537 11.4483 10.8327 11.557 10.8333 11.6667V15.3167L9.16667 16.15V11.6667C9.1673 11.557 9.14628 11.4483 9.10481 11.3467C9.06334 11.2452 9.00223 11.1529 8.925 11.075L4.50833 6.66667H15.4917L11.075 11.075ZM16.6667 5H3.33333V4.16667C3.33333 3.94565 3.42113 3.73369 3.57741 3.57741C3.73369 3.42113 3.94565 3.33333 4.16667 3.33333H15.8333C16.0543 3.33333 16.2663 3.42113 16.4226 3.57741C16.5789 3.73369 16.6667 3.94565 16.6667 4.16667V5Z"
      fill={color}
    />
  </svg>
);
