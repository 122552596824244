/* eslint-disable max-len */
import React, { FC } from 'react';
import { IconProps } from '../types';

export const Download: FC<IconProps> = ({
  size = '20',
  color = '#445371',
  transform,
  viewBox = '0 0 20 20',
  className,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    transform={transform}
    viewBox={viewBox}
    className={className}
    fill="none">
    <path
      d="M10 14.088L5.95657 10.0434L6.76457 9.22171L9.42857 11.8857V2H10.5714V11.8857L13.2343 9.22286L14.0434 10.0434L10 14.088ZM3.84571 18C3.32 18 2.88114 17.824 2.52914 17.472C2.17638 17.1192 2 16.68 2 16.1543V13.3851H3.14286V16.1543C3.14286 16.3295 3.216 16.4907 3.36229 16.6377C3.50933 16.784 3.67048 16.8571 3.84571 16.8571H16.1543C16.3295 16.8571 16.4907 16.784 16.6377 16.6377C16.784 16.4907 16.8571 16.3295 16.8571 16.1543V13.3851H18V16.1543C18 16.68 17.824 17.1189 17.472 17.4709C17.1192 17.8236 16.68 18 16.1543 18H3.84571Z"
      fill={color}
    />
  </svg>
);
