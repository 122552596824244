import { createSelector } from "@reduxjs/toolkit";

import { SideBarRootState } from "@Widgets/Sidebar/types";
import { SidebarParamsState } from "../types";
import { mainMenuState } from "../components/Menu/ducks/Menu.selector";

export const sideBarState = (state: SideBarRootState) => {
  return state.sideBar.sidebar;
};

export const getSidebarVisibility = () =>
  createSelector(sideBarState, (state) => state.sidebarShow);

export const getSidebarScrollPosition = () =>
  createSelector(
    sideBarState,
    (state: SidebarParamsState) => state.sidebarScrollPosition
  );

export const getSidebarBlocksPositionAndHeight = () =>
  createSelector(
    [sideBarState, mainMenuState],
    (
      {
        isSubmenuTitleBottomLocked,
        menuBlockClientHeigth,
        subMenuBlockClientHeigth,
      },
      { isUnfoldMenu }
    ) => ({
      isSubmenuTitleBottomLocked: isUnfoldMenu
        ? isSubmenuTitleBottomLocked
        : false,
      menuBlockClientHeigth,
      subMenuBlockClientHeigth,
    })
  );

export const getActiveProjectsMenuItem = () =>
  createSelector(sideBarState, (state) => {
    const { isSubmenu, id } = state.activeMenuItem;
    return !isSubmenu ? id : null;
  });

export const getActiveCategory = () =>
  createSelector(sideBarState, (state) => {
    const { isSubmenu, id } = state.activeMenuItem;
    return isSubmenu ? id : null;
  });
