/* eslint-disable max-len */
import React, { FC } from 'react';
import { IconProps } from '../types';

export const Blank: FC<IconProps> = ({
  size = '20',
  color = '#445371',
  transform,
  viewBox = '0 0 20 20',
  className,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    transform={transform}
    viewBox={viewBox}
    className={className}
    fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.8316 1.66667L5.00001 1.66667C4.07954 1.66667 3.33334 2.41286 3.33334 3.33333L3.33334 16.6667C3.33334 17.5871 4.07954 18.3333 5.00001 18.3333H15C15.9205 18.3333 16.6667 17.5871 16.6667 16.6667L16.6667 7.32656C16.6667 6.90669 16.5082 6.50229 16.2229 6.19421L12.5255 2.20099C12.2101 1.86032 11.7669 1.66667 11.3026 1.66667L10.835 1.66667C10.8345 1.66666 10.8339 1.66666 10.8333 1.66666C10.8328 1.66666 10.8322 1.66666 10.8316 1.66667ZM14.389 6.66666L11.6667 6.66666V3.72656L14.389 6.66666ZM15 8.33333L15 16.6667H5.00001L5.00001 3.33333L10 3.33333V6.66666C10 7.58714 10.7462 8.33333 11.6667 8.33333L15 8.33333Z"
      fill={color}
    />
  </svg>
);
