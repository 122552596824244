import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { useActionsDocumentTitle } from "./ducks/DocumentTitle.reducer";
import { selectDocumentTitleData } from "./ducks/DocumentTitle.secector";

import { textOfNumber } from "@/utils/helpers/HelpersString";

const DocumentTitle = () => {
  const { setDocumentTitle, setBrowserTabActivity, clearUnreededMessagesIds } =
    useActionsDocumentTitle();

  const {
    currentTitle,
    prevTitle,
    unreededMessagesIds,
    isApplicationTabActive,
  } = useSelector(selectDocumentTitleData);

  const unreededMessagesCount = unreededMessagesIds.length;
  const [intervalId, setIntervalId] = useState<number | null>(null);

  useEffect(() => {
    const handleFocusBlur = (e: FocusEvent) => {
      const isNextStateActive = e.type === "focus";
      setBrowserTabActivity(isNextStateActive);
    };

    window.addEventListener("blur", handleFocusBlur);
    window.addEventListener("focus", handleFocusBlur);

    return () => {
      window.removeEventListener("blur", handleFocusBlur);
      window.removeEventListener("focus", handleFocusBlur);
    };
  }, [setBrowserTabActivity]);

  /* Действие при изменении заголовка страницы */

  useEffect(() => {
    document.title = currentTitle;

    /* Прокрутка текста во вкладке браузера */

    if (!isApplicationTabActive && unreededMessagesCount > 0) {
      intervalId && clearInterval(intervalId);
      setIntervalId(
        window.setInterval(() => {
          const titleArr = document.title.split("");

          const title = titleArr.shift();
          title && titleArr.push(title);

          document.title = titleArr.join("");
        }, 300)
      );
    }
  }, [currentTitle, isApplicationTabActive, unreededMessagesCount, intervalId]);

  /* Действие при активации вкладки, сброс. */

  useEffect(() => {
    if (isApplicationTabActive && unreededMessagesCount > 0) {
      intervalId && clearInterval(intervalId);
      clearUnreededMessagesIds();
      setDocumentTitle(prevTitle);
    }
  }, [
    isApplicationTabActive,
    unreededMessagesCount,
    clearUnreededMessagesIds,
    intervalId,
    setDocumentTitle,
    prevTitle,
  ]);

  /* Действие при изменениее количества непрочитанных сообщений */

  useEffect(() => {
    if (!isApplicationTabActive && unreededMessagesCount > 0) {
      const notificationText = textOfNumber(unreededMessagesCount, [
        "новое\u00a0уведомление",
        "новых\u00a0уведомления",
        "новых\u00a0уведомлений",
      ]);

      const title = `${unreededMessagesCount}\u00a0${notificationText}\u00a0\u00a0`;

      setDocumentTitle(title);
    }
  }, [unreededMessagesCount, isApplicationTabActive, setDocumentTitle]);

  return null;
};

export default DocumentTitle;
