import React, { FC } from 'react';
import styled from 'styled-components';
import Button from './Button';
import { IIconButton, Size } from './types';

const PADDING: Record<Size, string> = {
  small: '1px 0',
  medium: '10px 10px',
  large: '10px 10px',
};

export const StyledIconButton = styled(Button)`
  padding: ${({ size = 'medium' }) => PADDING[size]};
`;

export const IconButton: FC<IIconButton> = (props) => <StyledIconButton {...props} />;
