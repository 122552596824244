export const SPACING = {
  none: '0px',
  xxs: '4px',
  xs: '8px',
  s: '12px',
  m: '18px',
  l: '24px',
  xl: '30px',
  xxl: '36px',
} as const;

export const SHADOWS = {
  none: '0px',
  bottomS:
    'rgba(28, 41, 61, 0.05) 0px 0px 8px, rgba(28, 41, 61, 0.06) 0px 2px 4px',
  bottomM:
    'rgba(28, 41, 61, 0.05) 0px 0px 16px, rgba(28, 41, 61, 0.06) 0px 4px 8px',
  bottomL:
    'rgba(28, 41, 61, 0.05) 0px 0px 20px, rgba(28, 41, 61, 0.06) 0px 12px 20px',
  bottomXL:
    'rgba(28, 41, 61, 0.05) 0px 0px 32px, rgba(28, 41, 61, 0.06) 0px 32px 32px',
  topS: 'rgba(28, 41, 61, 0.05) 0px 0px 8px, rgba(28, 41, 61, 0.06) 0px -2px 4px',
  topM: 'rgba(28, 41, 61, 0.05) 0px 0px 16px, rgba(28, 41, 61, 0.06) 0px -4px 8px',
  topL: 'rgba(28, 41, 61, 0.05) 0px 0px 20px, rgba(28, 41, 61, 0.06) 0px -12px 20px',
  topXL:
    'rgba(28, 41, 61, 0.05) 0px 0px 32px, rgba(28, 41, 61, 0.06) 0px -32px 32px',
} as const;

export const BACKDROP = {
  none: 'transparent',
  fade: 'rgba(0, 0, 0, 0.2)',
};

export const COLORS_OLD = {
  primary: '#7700FF',
  primary2: '#FF4F12',
  secondary: '#29CCA3',
  secondary2: '#2F9AFF',
  black: '#101828',
  white: '#fff',
  darkGray: 'rgba(16, 24, 40, 0.5)',
  gray: 'rgba(16, 24, 40, 0.25)',
  lightGray: 'rgba(16, 24, 40, 0.1)',
  red: 'rgba(255, 12, 12, 0.8)',
  superLightGray: 'rgba(16, 24, 40, 0.05)',
  error: '#E0190E',
  warning: '#F2A100',
  neutral: '#858FA3',
} as const;

export const SHAPE = {
  geometric: '0px',
  rounded: '8px',
  circular: '24px',
} as const;
