import { FC, memo } from "react";
import { ProjectImage } from "./Projects.styled";
import { getContrastColor, getInitialsFromName } from "@Widgets/Sidebar/utils";
import { ProjectAvatarProps } from "./types";

const ProjectAvatar: FC<ProjectAvatarProps> = ({ name, icon, iconColor }) => {
  const backgroundImage = icon ? `url("/uploads/icons/${icon}")` : "";
  const initialsProject = !icon ? getInitialsFromName(name) : "";
  const contrastColor = getContrastColor(iconColor ?? "#0055FF");

  return (
    <ProjectImage
      icon={icon}
      iconColor={iconColor}
      contrastColor={contrastColor}
      backgroundImage={backgroundImage}>
      {initialsProject}
    </ProjectImage>
  );
};

export default memo(ProjectAvatar);
