import React, { FC } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { DragAndDropProps, slotsProps } from './types';
import useDragAndDrop from './useDragAndDrop';

const defaultDragContainer = React.forwardRef<
  HTMLDivElement,
  slotsProps['dragContainer']
>(({ children, draggableProps, dragHandleProps }, ref) => (
  <div ref={ref} {...draggableProps} {...dragHandleProps}>
    {children}
  </div>
));

const defaultDropContainer = React.forwardRef<
  HTMLDivElement,
  slotsProps['dropContainer']
>(({ children }, ref) => <div ref={ref}>{children}</div>);

const DragAndDrop: FC<DragAndDropProps> = (props) => {
  const { children, onChange, slots = {} } = props;
  const { providerProps, rootProps } = useDragAndDrop({ onChange });

  const DraggableContainer = slots.dragContainer || defaultDragContainer;
  const DroppableContainer = slots.dropContainer || defaultDropContainer;

  const items = React.Children.map(children, (child, index) => {
    return (
      <Draggable key={index} draggableId={`id-${index}`} index={index}>
        {(draggableProvider, draggableState) => (
          <DraggableContainer
            ref={draggableProvider.innerRef}
            draggableProps={draggableProvider.draggableProps}
            dragHandleProps={draggableProvider.dragHandleProps}
            draggableStateProps={draggableState}
          >
            {child}
          </DraggableContainer>
        )}
      </Draggable>
    );
  });

  return (
    <DragDropContext {...providerProps}>
      <div {...rootProps}>
        <Droppable droppableId="droppable">
          {(droppableProvider, droppableStateProps) => (
            <DroppableContainer
              ref={droppableProvider.innerRef}
              droppableStateProps={droppableStateProps}
            >
              {items}
              {droppableProvider.placeholder}
            </DroppableContainer>
          )}
        </Droppable>
      </div>
    </DragDropContext>
  );
};

defaultDragContainer.displayName = 'defaultDragContainer';
defaultDropContainer.displayName = 'defaultDropContainer';

export default DragAndDrop;
