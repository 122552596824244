import  { useCallback } from 'react';

import { useActionsSidebar } from '@Widgets/Sidebar/ducks/Sidebar.reducer';
import { SidebarToggleIcon, UserPanelIconWrapper } from '@Widgets/Header/Header.styled';

const SidebarToggle = () => {
  const sidebarActions = useActionsSidebar();

  const handleClick = useCallback(() => {
    sidebarActions.toggleShowSidebar();
  }, [sidebarActions]);

  return (
    <UserPanelIconWrapper onClick={handleClick}>
      <SidebarToggleIcon />
    </UserPanelIconWrapper>
  );
};

export default SidebarToggle;
