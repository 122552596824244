/* eslint-disable max-len */
import React, { FC } from 'react';
import { IconProps } from '../types';

export const FolderOpened: FC<IconProps> = ({
  size = '20',
  color = '#445371',
  transform,
  viewBox = '0 0 20 20',
  className,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    transform={transform}
    viewBox={viewBox}
    className={className}
    fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.0182 4.96863C2.6316 4.96863 2.3182 5.28203 2.3182 5.66863V14.3314C2.3182 14.718 2.6316 15.0314 3.0182 15.0314H9.00001V16.6314H3.0182C1.74795 16.6314 0.718201 15.6016 0.718201 14.3314V5.66863C0.718201 4.39837 1.74795 3.36863 3.0182 3.36863H5.42693C5.91469 3.36863 6.38983 3.52369 6.78369 3.81142L7.55734 4.37661C7.67721 4.46418 7.82182 4.51137 7.97027 4.51137H14.9818C16.2521 4.51137 17.2818 5.54112 17.2818 6.81137V10.5714H15.6818V6.81137C15.6818 6.42477 15.3684 6.11137 14.9818 6.11137H7.97027C7.48251 6.11137 7.00737 5.9563 6.61351 5.66858L5.83985 5.10339C5.71998 5.01582 5.57538 4.96863 5.42693 4.96863H3.0182Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.52554 10.9031C2.7902 10.2028 3.46067 9.73944 4.2093 9.73944H17.0349C18.2936 9.73944 19.1636 10.9983 18.7186 12.1758L17.4745 15.4677C17.2098 16.168 16.5394 16.6314 15.7907 16.6314H2.96517C1.70643 16.6314 0.836407 15.3725 1.28141 14.195L2.52554 10.9031ZM4.2093 11.3394C4.12612 11.3394 4.05162 11.3909 4.02222 11.4687L2.77808 14.7607C2.72864 14.8915 2.82531 15.0314 2.96517 15.0314H15.7907C15.8739 15.0314 15.9484 14.9799 15.9778 14.9021L17.2219 11.6102C17.2714 11.4793 17.1747 11.3394 17.0349 11.3394H4.2093Z"
      fill={color}
    />
  </svg>
);
