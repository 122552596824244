import styled from "styled-components";
import Icon, { StyledIconProps } from "@suz/ui-kit/Icon";
import { SearchButtonProps } from "../../types";

export const SearchIcon = styled(Icon).attrs<StyledIconProps>({
  icon: "Search",
  color: "#0055ff",
})`
  margin-right: 8px;
`;

export const SearchField = styled.span`
  font-size: 14px;
`;

export const SearchHotKeys = styled.span`
  margin-left: auto;
  font-size: 14px;
`;

export const SearchWrapper = styled.button<SearchButtonProps>`
  display: flex;
  width: 260px;
  height: 40px;
  padding: 8px 16px;
  margin: 8px;
  color: #0055ff;
  background-color: #fff;
  border: 1px solid #e6eeff;
  box-shadow: 0px 1px 11px 0px #0055ff28;
  border-radius: 8px;
  font-weight: 500;
  line-height: 142.857%;
  cursor: pointer;
  transition: all 0.2s ease-out;

  &:hover {
    background-color: #f2f7ff;
  }

  &:active {
    background-color: #d1e0ff;
  }

  &:disabled {
    color: #d0d4dc;
    background-color: #fff;
    border: 1px solid #d0d4dc;
    box-shadow: none;
    cursor: not-allowed;

    ${SearchIcon} {
      path {
        fill: #d0d4dc;
      }
    }
  }
`;
