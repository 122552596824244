/* eslint-disable max-len */
import React, { FC } from 'react';
import { IconProps } from '../types';

export const ArrowLDown: FC<IconProps> = ({
  size = '20',
  color = '#445371',
  transform,
  viewBox = '0 0 20 20',
  className,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    transform={transform}
    viewBox={viewBox}
    className={className}
    fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.57742 6.91074C3.90286 6.5853 4.4305 6.5853 4.75593 6.91074L10 12.1548L15.2441 6.91074C15.5695 6.5853 16.0972 6.5853 16.4226 6.91074C16.748 7.23618 16.748 7.76382 16.4226 8.08925L10.5893 13.9226C10.433 14.0789 10.221 14.1667 10 14.1667C9.779 14.1667 9.56704 14.0789 9.41076 13.9226L3.57742 8.08925C3.25198 7.76382 3.25198 7.23618 3.57742 6.91074Z"
      fill={color}
    />
  </svg>
);
