/* eslint-disable max-len */
import React, { FC } from 'react';
import { IconProps } from '../types';

export const Command: FC<IconProps> = ({
  size = '20',
  color = '#747479',
  transform,
  viewBox = '0 0 20 20',
  className,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    transform={transform}
    viewBox={viewBox}
    className={className}
    fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.6667 5.00001C11.6667 3.15906 13.1591 1.66667 15.0001 1.66667C16.841 1.66667 18.3334 3.15906 18.3334 5.00001C18.3334 6.84095 16.841 8.33334 15.0001 8.33334H13.3334V11.6667H15.0001C16.841 11.6667 18.3334 13.1591 18.3334 15C18.3334 16.841 16.841 18.3333 15.0001 18.3333C13.1591 18.3333 11.6667 16.841 11.6667 15V13.3333H8.33341V15C8.33341 16.841 6.84103 18.3333 5.00008 18.3333C3.15913 18.3333 1.66675 16.841 1.66675 15C1.66675 13.1591 3.15913 11.6667 5.00008 11.6667H6.66675V8.33334H5.00008C3.15913 8.33334 1.66675 6.84095 1.66675 5.00001C1.66675 3.15906 3.15913 1.66667 5.00008 1.66667C6.84103 1.66667 8.33341 3.15906 8.33341 5.00001V6.66667H11.6667V5.00001ZM15.0001 3.33334C14.0796 3.33334 13.3334 4.07953 13.3334 5.00001V6.66667H15.0001C15.9206 6.66667 16.6667 5.92048 16.6667 5.00001C16.6667 4.07953 15.9206 3.33334 15.0001 3.33334ZM8.33341 11.6667V8.33334H11.6667V11.6667H8.33341ZM5.00008 13.3333C4.07961 13.3333 3.33341 14.0795 3.33341 15C3.33341 15.9205 4.07961 16.6667 5.00008 16.6667C5.92056 16.6667 6.66675 15.9205 6.66675 15V13.3333H5.00008ZM13.3334 13.3333V15C13.3334 15.9205 14.0796 16.6667 15.0001 16.6667C15.9206 16.6667 16.6667 15.9205 16.6667 15C16.6667 14.0795 15.9206 13.3333 15.0001 13.3333H13.3334ZM3.33341 5.00001C3.33341 4.07953 4.07961 3.33334 5.00008 3.33334C5.92056 3.33334 6.66675 4.07953 6.66675 5.00001V6.66667H5.00008C4.07961 6.66667 3.33341 5.92048 3.33341 5.00001Z"
      fill={color}
    />
  </svg>
);
