import React, { FC, useEffect, useState, useCallback, memo } from 'react';
import { usePopper } from 'react-popper';

import Icon from '../Icon';

import { IDropDown, DIRECTIONS } from './types';
import {
  DropDownWrapper,
  DropDownText,
  DropDownIcon,
  DropDownContentWrapper,
  DropDownContent,
} from './DropDown.styled';

const DropDown: FC<IDropDown> = ({
  variant = 'filled',
  disabled = false,
  placeholder,
  rightIcon,
  leftIcon,
  direction = 'bottomLeft',
  onClick,
  contentHeight = 'auto',
  contentWidth = 'auto',
  children
}) => {
  const [referenceElem, setReferenceElem] = useState<HTMLElement | null>(null);
  const [popperElem, setPopperElem] = useState<HTMLElement | null>(null);
  const [open, setOpen] = useState<boolean>(false);

  const { styles, attributes } = usePopper(referenceElem, popperElem, {
    placement: DIRECTIONS[direction],
  });

  const handleClick = useCallback(() => {
    setOpen(true);
    onClick?.();
  },[setOpen, onClick]);

  const handleClose = useCallback((e: MouseEvent) => {
    if (
      document.documentElement.contains(e.target as Node) &&
      popperElem && !popperElem.contains(e.target as Node) && popperElem !== e.target
    ) {
      setOpen(false);
    }
  },[popperElem, setOpen]);

  useEffect(() => {
    open && document.addEventListener('click', handleClose);
    return () => {
      document.removeEventListener('click', handleClose);
    };
  }, [open, handleClose]);

  return (
    <DropDownWrapper
      ref={setReferenceElem}
      onClick={handleClick}
      variant={variant}
      active={open}
      disabled={disabled}
    >
      {leftIcon && <Icon icon={leftIcon} />}
      <DropDownText variant={variant} active={open} disabled={disabled}>{placeholder}</DropDownText>
      
      <DropDownIcon active={open} variant={variant} disabled={disabled} />
      {open && 
        <DropDownContentWrapper
          ref={setPopperElem}
          $contentHeight={contentHeight}
          $contentWidth={contentWidth}
          style={styles.popper}
          {...attributes.popper}
        >
          {children}
        </DropDownContentWrapper>}
    </DropDownWrapper>
  )
}

export default memo(DropDown);