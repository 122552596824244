/* eslint-disable max-len */
import React, { FC } from 'react';
import { IconProps } from '../types';

export const SortAscending: FC<IconProps> = ({
  size = '20',
  color = '#445371',
  transform,
  viewBox = '0 0 20 20',
  className,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    transform={transform}
    viewBox={viewBox}
    className={className}
    fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.5 14.1667C2.5 13.7064 2.8731 13.3333 3.33333 13.3333H8.33333C8.79357 13.3333 9.16667 13.7064 9.16667 14.1667C9.16667 14.6269 8.79357 15 8.33333 15H3.33333C2.8731 15 2.5 14.6269 2.5 14.1667Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.5 10C2.5 9.53976 2.8731 9.16667 3.33333 9.16667H10.8333C11.2936 9.16667 11.6667 9.53976 11.6667 10C11.6667 10.4602 11.2936 10.8333 10.8333 10.8333H3.33333C2.8731 10.8333 2.5 10.4602 2.5 10Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 8.33333C15.4602 8.33333 15.8333 8.70643 15.8333 9.16667V15.8333C15.8333 16.2936 15.4602 16.6667 15 16.6667C14.5398 16.6667 14.1667 16.2936 14.1667 15.8333V9.16667C14.1667 8.70643 14.5398 8.33333 15 8.33333Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9107 12.7441C12.2362 12.4186 12.7638 12.4186 13.0893 12.7441L15 14.6548L16.9107 12.7441C17.2362 12.4186 17.7638 12.4186 18.0893 12.7441C18.4147 13.0695 18.4147 13.5972 18.0893 13.9226L15.5893 16.4226C15.2638 16.748 14.7362 16.748 14.4107 16.4226L11.9107 13.9226C11.5853 13.5972 11.5853 13.0695 11.9107 12.7441Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.5 5.83333C2.5 5.3731 2.8731 5 3.33333 5H13.3333C13.7936 5 14.1667 5.3731 14.1667 5.83333C14.1667 6.29357 13.7936 6.66667 13.3333 6.66667H3.33333C2.8731 6.66667 2.5 6.29357 2.5 5.83333Z"
      fill={color}
    />
  </svg>
);
