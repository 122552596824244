/* eslint-disable max-len */
import React, { FC } from 'react';
import { IconProps } from '../types';

export const FiltersSquare: FC<IconProps> = ({
  size = '20',
  color = '#445371',
  transform,
  viewBox = '0 0 20 20',
  className,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    transform={transform}
    viewBox={viewBox}
    className={className}
    fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.5 15C7.5 14.5398 7.8731 14.1667 8.33333 14.1667H17.5C17.9602 14.1667 18.3333 14.5398 18.3333 15C18.3333 15.4602 17.9602 15.8333 17.5 15.8333H8.33333C7.8731 15.8333 7.5 15.4602 7.5 15Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.66667 15C1.66667 14.5398 2.03976 14.1667 2.5 14.1667H5C5.46024 14.1667 5.83333 14.5398 5.83333 15C5.83333 15.4602 5.46024 15.8333 5 15.8333H2.5C2.03976 15.8333 1.66667 15.4602 1.66667 15Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 12.5C5.46024 12.5 5.83333 12.8731 5.83333 13.3333V16.6666C5.83333 17.1269 5.46024 17.5 5 17.5C4.53976 17.5 4.16667 17.1269 4.16667 16.6666L4.16667 13.3333C4.16667 12.8731 4.53976 12.5 5 12.5Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.8333 10C15.8333 9.53977 16.2064 9.16667 16.6667 9.16667H17.5C17.9602 9.16667 18.3333 9.53977 18.3333 10C18.3333 10.4602 17.9602 10.8333 17.5 10.8333H16.6667C16.2064 10.8333 15.8333 10.4602 15.8333 10Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.66667 10C1.66667 9.53977 2.03976 9.16667 2.5 9.16667H13.3333C13.7936 9.16667 14.1667 9.53977 14.1667 10C14.1667 10.4602 13.7936 10.8333 13.3333 10.8333H2.5C2.03976 10.8333 1.66667 10.4602 1.66667 10Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.3333 7.49998C13.7936 7.49998 14.1667 7.87307 14.1667 8.33331V11.6666C14.1667 12.1269 13.7936 12.5 13.3333 12.5C12.8731 12.5 12.5 12.1269 12.5 11.6666L12.5 8.33331C12.5 7.87307 12.8731 7.49998 13.3333 7.49998Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.8333 5C10.8333 4.53976 11.2064 4.16667 11.6667 4.16667H17.5C17.9602 4.16667 18.3333 4.53976 18.3333 5C18.3333 5.46024 17.9602 5.83334 17.5 5.83334H11.6667C11.2064 5.83334 10.8333 5.46024 10.8333 5Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.66667 5C1.66667 4.53976 2.03976 4.16667 2.5 4.16667H8.33333C8.79357 4.16667 9.16667 4.53976 9.16667 5C9.16667 5.46024 8.79357 5.83334 8.33333 5.83334H2.5C2.03976 5.83334 1.66667 5.46024 1.66667 5Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.33333 2.49998C8.79357 2.49998 9.16667 2.87307 9.16667 3.33331V6.66664C9.16667 7.12688 8.79357 7.49998 8.33333 7.49998C7.8731 7.49998 7.5 7.12688 7.5 6.66664V3.33331C7.5 2.87307 7.8731 2.49998 8.33333 2.49998Z"
      fill={color}
    />
  </svg>
);
