import { createSelector } from "@reduxjs/toolkit";
import { SideBarRootState } from "@/widgets/Sidebar/types";
import { SubmenuLoadingPayload } from "../types";

export const categoriesState = (state: SideBarRootState) => {
  return state.sideBar.submenu;
};

export const getLoadingStatusCategories = ({
  parentId,
}: SubmenuLoadingPayload) =>
  createSelector(categoriesState, (state) =>
    state.isLoading.find((i) => i === `[SubMenu] fetchList_${parentId}`)
  );

export const getCategorySlice = (id: number, isArchiveChild?: boolean) =>
  createSelector(categoriesState, (state) => {
    return isArchiveChild
      ? state.categoriesSlices[-4].find((item) => item.id === id)?.categories
      : state.categoriesSlices[id];
  });

export const getTopLevelCategories = () =>
  createSelector(categoriesState, ({ categoriesSlices: { 0: categories } }) => {
    const specialIds = [-4, -3];
    const archive = categories.find(({ id }) => id === specialIds[0]);
    const drafts = categories.find(({ id }) => id === specialIds[1]);
    const filteredCategories = categories.filter(
      ({ id }) => !specialIds.includes(id)
    );

    const resultList = [];
    if (archive) resultList.push(archive);
    if (drafts) resultList.push(drafts);

    return [...resultList, ...filteredCategories];
  });

export const getCategoryOpened = () =>
  createSelector(categoriesState, (state) => state.categoryOpenedIds);
