/* eslint-disable max-len */
import React, { FC } from 'react';
import { IconProps } from '../types';

export const PersonCircle: FC<IconProps> = ({
  size = '20',
  color = '#445371',
  transform,
  viewBox = '0 0 20 20',
  className,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    transform={transform}
    viewBox={viewBox}
    className={className}
    fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 3.33333C6.31812 3.33333 3.33335 6.3181 3.33335 10C3.33335 13.6819 6.31812 16.6667 10 16.6667C13.6819 16.6667 16.6667 13.6819 16.6667 10C16.6667 6.3181 13.6819 3.33333 10 3.33333ZM1.66669 10C1.66669 5.39762 5.39765 1.66666 10 1.66666C14.6024 1.66666 18.3334 5.39762 18.3334 10C18.3334 14.6024 14.6024 18.3333 10 18.3333C5.39765 18.3333 1.66669 14.6024 1.66669 10Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.99989 15C8.5195 15 7.19023 15.6423 6.27352 16.6661C5.96652 17.009 5.43968 17.0381 5.0968 16.7311C4.75393 16.4241 4.72485 15.8972 5.03186 15.5543C6.25135 14.1924 8.02585 13.3333 9.99989 13.3333C11.9739 13.3333 13.7484 14.1924 14.9679 15.5543C15.2749 15.8972 15.2458 16.4241 14.903 16.7311C14.5601 17.0381 14.0333 17.009 13.7262 16.6661C12.8095 15.6423 11.4803 15 9.99989 15Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 7.5C9.07955 7.5 8.33335 8.24619 8.33335 9.16666C8.33335 10.0871 9.07955 10.8333 10 10.8333C10.9205 10.8333 11.6667 10.0871 11.6667 9.16666C11.6667 8.24619 10.9205 7.5 10 7.5ZM6.66669 9.16666C6.66669 7.32571 8.15907 5.83333 10 5.83333C11.841 5.83333 13.3334 7.32571 13.3334 9.16666C13.3334 11.0076 11.841 12.5 10 12.5C8.15907 12.5 6.66669 11.0076 6.66669 9.16666Z"
      fill={color}
    />
  </svg>
);
