/* eslint-disable max-len */
import React, { FC } from 'react';
import { IconProps } from '../types';

export const EditM: FC<IconProps> = ({
  size = '20',
  color = '#445371',
  transform,
  viewBox = '0 0 20 20',
  className,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    transform={transform}
    viewBox={viewBox}
    className={className}
    fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.1548 3.33334C12.8057 2.68246 13.861 2.68246 14.5118 3.33334L16.6667 5.48816C17.3175 6.13903 17.3175 7.19431 16.6667 7.84518L7.25592 17.2559C7.09964 17.4122 6.88768 17.5 6.66667 17.5H3.33333C2.8731 17.5 2.5 17.1269 2.5 16.6667V13.3333C2.5 13.1123 2.5878 12.9004 2.74408 12.7441L12.1548 3.33334ZM15.4882 6.66667L13.3333 4.51185L4.16667 13.6785V15.8333H6.32149L15.4882 6.66667Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.41074 6.07741C9.73618 5.75197 10.2638 5.75197 10.5893 6.07741L13.9226 9.41074C14.248 9.73618 14.248 10.2638 13.9226 10.5893C13.5972 10.9147 13.0695 10.9147 12.7441 10.5893L9.41074 7.25592C9.08531 6.93049 9.08531 6.40285 9.41074 6.07741Z"
      fill={color}
    />
  </svg>
);
