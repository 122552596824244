import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import { useActionsUser } from "./ducks/User.reducer";
import SkeletonUser from "./SkeletonUser";
import {
  UserWrapper,
  UserPhoto,
  UserName,
  StyledButton,
  StyledText,
  ExitIcon,
} from "./User.styled";
import { ArrowDownIcon } from "@Widgets/Header/Header.styled";
import { getIsLoadingUser, getUser } from "../../ducks/Profile.selector";
import { useNavigate } from "react-router";

const User = () => {
  const user = useSelector(getUser());
  const isLoading = useSelector(getIsLoadingUser());

  const userActions = useActionsUser();

  useEffect(() => {
    userActions.fetchUser();
  }, [userActions]);

  const navigate = useNavigate();

  const returnToLogin = () => {
    navigate({
      pathname: "/login",
    });
  };

  const handleLogout = () =>
    userActions.logoutUser({
      onSuccess: returnToLogin,
    });

  return (
    <SkeletonUser isLoading={isLoading || !user}>
      <>
        <UserWrapper>
          <UserPhoto />
          <UserName>
            {user?.firstname} {user?.lastname}
          </UserName>
          <ArrowDownIcon />
        </UserWrapper>

        <StyledButton onClick={handleLogout}>
          <ExitIcon />
          <StyledText>Выход</StyledText>
        </StyledButton>
      </>
    </SkeletonUser>
  );
};

export default User;
