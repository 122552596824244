import styled, { css } from 'styled-components';
import Icon, { StyledIconProps } from '../Icon';
import { COLORS } from '@suz/ui-utils/constants/Colors';

import { 
  StyledDropDown,
  StyledDropDownContent, 
  ViewWrapperConfig,
  ViewTextConfig,
  } from './types';

export const getViewWrapperConfig: (disabled?: boolean, active?: boolean, filled?: boolean) 
=> ViewWrapperConfig = (disabled, active, filled) => {
  return {
    filled: {
      fontSize: '14px',
      lineHeight: '20px',
      color: disabled ? COLORS.neutral100 : filled ? COLORS.neutral900 : COLORS.neutral400,
      border: disabled ? 'none' : active ? `1px solid ${COLORS.accent500}` : `1px solid ${COLORS.neutral300}`,
      backgroundColor: disabled ? COLORS.accent500 : COLORS.accent25,
      width: '252px',
      height: '40px',
      borderRadius: '8px',
      opacity: disabled ? '0.5' : '1',
      pointerEvents: disabled ? 'none' : 'auto',
      hover: !disabled && !active ? `1px solid ${COLORS.accent200}` : '',
    },
    outline: {
      fontSize: '14px',
      lineHeight: '20px',
      color: disabled ? COLORS.neutral100 : filled ? COLORS.neutral900 : COLORS.neutral400,
      border: disabled ? `1px solid ${COLORS.neutral75}` :  active ? `1px solid ${COLORS.accent500}` : `1px solid ${COLORS.neutral300}`,
      backgroundColor: disabled ? COLORS.neutral0 : COLORS.neutral0,
      width: '252px',
      height: '40px',
      borderRadius: '8px',
      opacity: disabled ? '0.5' : '1',
      pointerEvents: disabled ? 'none' : 'auto',
      hover: !disabled && !active ? `1px solid ${COLORS.accent200}` : '',
    },
    filter: {
      fontSize: '13px',
      lineHeight: '16px',
      color: active ? COLORS.accent500 : COLORS.neutral600,
      border: active ? `1px solid ${COLORS.accent400}` : `1px solid ${COLORS.neutral100}`,
      backgroundColor: active ? COLORS.accent50 : COLORS.neutral0,
      width: 'auto',
      height: '32px',
      borderRadius: '20px',
      opacity: '1',
      pointerEvents: 'auto',
      hover: !active ? COLORS.neutral50 : '',
    },
  }
}

export const getViewTextConfig: () => ViewTextConfig = () => {
  return {
    filled: {
      fontSize: '14px',
      lineHeight: '20px',
    },
    outline: {
      fontSize: '14px',
      lineHeight: '20px',
    },
    filter: {
      fontSize: '13px',
      lineHeight: '16px',
    },
  }
}

export const DropDownWrapper = styled.div<StyledDropDown>`
  display: flex;
  align-items: center;
  border: ${({ variant, disabled, active }) => getViewWrapperConfig(disabled, active)[variant].border};
  border-radius: ${({ variant }) => getViewWrapperConfig()[variant].borderRadius};
  padding: 8px 12px;
  height: ${({ variant }) => getViewWrapperConfig()[variant].height};
  width: ${({ variant }) => getViewWrapperConfig()[variant].width};
  color: ${({ variant, disabled, active, filled }) => getViewWrapperConfig(disabled, active, filled)[variant].color};
  background-color: ${({ variant, disabled, active }) => getViewWrapperConfig(disabled, active)[variant].backgroundColor};
  opacity: ${({ variant, disabled }) => getViewWrapperConfig(disabled)[variant].opacity};
  pointer-events: ${({ variant, disabled }) => getViewWrapperConfig(disabled)[variant].pointerEvents};
  cursor: pointer;


  &:hover {
    ${({ variant, disabled, active }) => variant !== 'filter' ?
      css`
        border: ${getViewWrapperConfig(disabled, active)[variant].hover};
      ` :
      css`
        background-color: ${getViewWrapperConfig(disabled, active)[variant].hover};
      `
    }
  }
`

export const DropDownText = styled.span<StyledDropDown>`
  font-size: ${({ variant }) => getViewTextConfig()[variant].fontSize};
  font-style: normal;
  font-weight: 500;
  line-height: ${({ variant }) => getViewTextConfig()[variant].lineHeight};
  overflow: hidden;
  text-wrap: nowrap;
  margin-right: 4px;
  max-width: 200px;
`

export const DropDownIcon = styled(Icon).attrs<StyledDropDown>(
  ({ active, variant, disabled }) => ({
    icon: active ? 'ArrowLUp' : 'ArrowLDown',
    size: variant !== 'filter' ? '20' : '16',
    color: variant == 'filter' && active ? '#37F' : disabled && variant !== 'filter' ? '#F6F6F8' : '#445371'
 }))<StyledIconProps>`
    margin-left: auto;
`

export const DropDownContentWrapper = styled.div<StyledDropDownContent>`
    position: relative;
    overflow: hidden scroll;
    height: ${({ $contentHeight }) => $contentHeight ? `${$contentHeight}px` : 'auto'};
    width: ${({ $contentWidth }) => $contentWidth ? `${$contentWidth}px` : 'auto'};
    border-radius: 8px;
    background: ${COLORS.neutral0};
    box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.25);
    z-index: 1;
    margin-top: 2px;

    &::-webkit-scrollbar-track
    {
      -webkit-box-shadow: inset 0 0 6px rgba(255,255,255,0.3);
      background-color: ${COLORS.neutral0};
    }

    &::-webkit-scrollbar
    {
      width: 8px;
      background-color: ${COLORS.neutral0};
    }

    &::-webkit-scrollbar-thumb
    {
      width: 4px;
      border: 2px solid ${COLORS.neutral0};
      border-radius: 8px;
      background-color: ${COLORS.neutral100};
    }
`

export const DropDownContent = styled.div`
  position: absolute;
  background: ${COLORS.neutral0};

`