import styled from "styled-components";

export const ProjectsMenuWrapper = styled.div`
  display: block;
  position: sticky;
  top: 0;
  left: 0;
  width: 276px;
`;

export const HeaderProjectsWrapper = styled.div`
  border-bottom: 1px solid #e4e5ea;
  width: 100%;
`;
