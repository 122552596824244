import styled from 'styled-components';
import { IChipStyles, SHAPES } from './types';
import { COLORS_OLD } from '@suz/ui-utils/constants/defaultValues';

const gradient = 'linear-gradient(rgba(16, 24, 40, 0.05), rgba(16, 24, 40, 0.05))';

export const Text = styled.span`
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const Amount = styled.span`
  position: relative;
  top: -4px;
  flex-shrink: 0;
  margin-left: 4px;
`;

export const ChipWrapper = styled.button<IChipStyles>`
  display: inline-flex;
  align-items: center;
  max-width: 100%;
  height: 40px;
  padding: 0 16px;
  color: ${COLORS_OLD.black};
  line-height: 40px;
  border: 0px;
  border-radius: ${({ shape = 'rounded' }) => SHAPES[shape]};
  outline: none;
  background-color: ${({ color = 'primary', selected, disabled }) => {
    if (disabled) return COLORS_OLD.lightGray;
    return selected ? COLORS_OLD[color] : COLORS_OLD.lightGray;
  }};
  cursor: pointer;
  &:hover {
    background-image: ${({ selected, disabled }) => selected || disabled ? 'none' : gradient};
  }
  ${Text},
  ${Amount} {
    color: ${({ selected, disabled }) => {
    if (disabled) return COLORS_OLD.gray;
    return selected ? '#fff' : COLORS_OLD.black;
  }};
  }
`;
