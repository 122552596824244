import styled from "styled-components";
import ButtonBase from "@/uikit/components/ButtonBase";
import Icon, { StyledIconProps } from "@suz/ui-kit/Icon";
import { FONTS } from "@suz/ui-utils/constants/Fonts";
import { COLORS } from "@/uikit/constants/Colors";

export const UserWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 40px;
`;

export const UserPhoto = styled.img`
  min-width: 36px;
  min-height: 36px;
  display: block;
  background: #666;
  border: none;
  border-radius: 12px;
`;

export const UserName = styled.span`
  font-size: ${FONTS.body4strong.fs};
  line-height: ${FONTS.body4strong.lh};
  font-weight: ${FONTS.body4strong.weight};
  margin: 0 4px;
`;

export const StyledButton = styled(ButtonBase)`
  width: 73px;
  background-color: ${COLORS.neutral0};
  border: none;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  gap: 4px;
  padding: 0;
  margin-left: 40px;
`;

export const ExitIcon = styled(Icon).attrs<StyledIconProps>({
  icon: "Exit",
  size: "24",
})`
  min-width: 24px;
  min-height: 24px;
`;

export const StyledText = styled.span`
  color: ${COLORS.neutral500};
  font-size: ${FONTS.body4strong.fs};
  line-height: ${FONTS.body4strong.lh};
  font-weight: ${FONTS.body4strong.weight};
`;
