import { useMemo } from "react";
import { useDispatch } from "react-redux";
import {
  PayloadAction,
  bindActionCreators,
  createSlice,
} from "@reduxjs/toolkit";
import { ActiveMenuItem, SidebarParamsState } from "../types";

const NAMESPACE = "[Sidebar]";

const initialState: SidebarParamsState = {
  sidebarShow: true,
  sidebarScrollPosition: 0,
  isSubmenuTitleBottomLocked: false,
  menuBlockClientHeigth: 960,
  subMenuBlockClientHeigth: 0,
  activeMenuItem: { isSubmenu: false, id: 1 },
};

const SidebarSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    toggleShowSidebar(state) {
      state.sidebarShow = !state.sidebarShow;
    },
    setScrollPosition(state, { payload }: PayloadAction<number>) {
      state.sidebarScrollPosition = payload;
    },
    toggleSubmenuTitleBottomLock(state, { payload }: PayloadAction<boolean>) {
      state.isSubmenuTitleBottomLocked = payload;
    },
    setMenuBlockClientHeigth(state, { payload }: PayloadAction<number>) {
      state.menuBlockClientHeigth = payload;
    },
    setSubMenuBlockClientHeigth(state, { payload }: PayloadAction<number>) {
      state.subMenuBlockClientHeigth = payload;
    },
    setActiveMenuItem(state, { payload }: PayloadAction<ActiveMenuItem>) {
      state.activeMenuItem = payload;
    },
    setActiveMainPage(state) {
      state.activeMenuItem = initialState.activeMenuItem;
    },
  },
});

export const actionsSidebar = {
  ...SidebarSlice.actions,
};

export const useActionsSidebar = () => {
  const dispatch = useDispatch();

  return useMemo(
    () => bindActionCreators(actionsSidebar, dispatch),
    [dispatch]
  );
};

export default SidebarSlice.reducer;
