import { useMemo } from "react";
import { useDispatch } from "react-redux";
import {
  PayloadAction,
  bindActionCreators,
  createSlice,
} from "@reduxjs/toolkit";
import { DocumentTitleState } from "./types";

import concat from "lodash/concat";
import uniq from "lodash/uniq";

const NAMESPACE = "[DocumentTitle]";

const initialState: DocumentTitleState = {
  currentTitle: document.title,
  prevTitle: document.title,
  unreededMessagesIds: [],
  isApplicationTabActive: true,
};

const DocumentTitleSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    setDocumentTitle: (state, { payload: currentTitle }: PayloadAction<string>) => ({
      ...state,
      currentTitle,
    }),
    setBrowserTabActivity: (
      state,
      { payload: isApplicationTabActive }: PayloadAction<boolean>
    ) => ({
      ...state,
      isApplicationTabActive,
    }),
    setUnreededMessagesIds: (
      state,
      { payload: unreededMessagesIds }: PayloadAction<string[]>
    ) => ({
      ...state,
      prevTitle: state.unreededMessagesIds.length
        ? state.prevTitle
        : state.currentTitle,
      unreededMessagesIds: uniq(
        concat(state.unreededMessagesIds, unreededMessagesIds)
      ),
    }),
    clearUnreededMessagesIds: (state) => ({
      ...state,
      unreededMessagesIds: [],
    }),
  },
});

export const actionsDocumentTitle = {
  ...DocumentTitleSlice.actions,
};

export const useActionsDocumentTitle = () => {
  const dispatch = useDispatch();

  return useMemo(
    () => bindActionCreators(actionsDocumentTitle, dispatch),
    [dispatch]
  );
};

export default DocumentTitleSlice.reducer;
