/* eslint-disable max-len */
import React, { FC } from 'react';
import { IconProps } from '../types';

export const CloudUpload: FC<IconProps> = ({
  size = '20',
  color = '#445371',
  transform,
  viewBox = '0 0 20 20',
  className,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    transform={transform}
    viewBox={viewBox}
    className={className}
    fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.18789 6.73238C5.3301 4.70489 7.50414 3.33333 10 3.33333C13.124 3.33333 15.7451 5.48149 16.4686 8.38146C18.4683 8.68738 20 10.4148 20 12.5C20 14.8012 18.1345 16.6667 15.8333 16.6667H5C2.23858 16.6667 0 14.4281 0 11.6667C0 9.18169 1.81237 7.12062 4.18789 6.73238ZM10 5C8.00365 5 6.27888 6.16996 5.47708 7.8653C5.3477 8.13887 5.08016 8.3208 4.77818 8.34057C3.04106 8.45427 1.66667 9.9003 1.66667 11.6667C1.66667 13.5076 3.15905 15 5 15H15.8333C17.214 15 18.3333 13.8807 18.3333 12.5C18.3333 11.1193 17.214 10 15.8333 10C15.8186 10 15.8037 10.0001 15.7888 10.0004C15.3687 10.0077 15.0088 9.70106 14.9494 9.28511C14.6031 6.8626 12.5183 5 10 5Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 7.5C10.4602 7.5 10.8333 7.8731 10.8333 8.33333V13.3333C10.8333 13.7936 10.4602 14.1667 10 14.1667C9.53976 14.1667 9.16667 13.7936 9.16667 13.3333V8.33333C9.16667 7.8731 9.53976 7.5 10 7.5Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.53775 7.63996C9.81767 7.45335 10.1823 7.45335 10.4623 7.63996L12.9622 9.30663C13.3452 9.56192 13.4487 10.0793 13.1934 10.4623C12.9381 10.8452 12.4207 10.9487 12.0378 10.6934L10 9.33488L7.96225 10.6934C7.57931 10.9487 7.06192 10.8452 6.80662 10.4622C6.55133 10.0793 6.65481 9.56192 7.03775 9.30662L9.53775 7.63996Z"
      fill={color}
    />
  </svg>
);
