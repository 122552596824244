/* eslint-disable max-len */
import React, { FC } from 'react';
import { IconProps } from '../types';

export const ArrowMUp: FC<IconProps> = ({
  size = '20',
  color = '#445371',
  transform,
  viewBox = '0 0 20 20',
  className,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    transform={transform}
    viewBox={viewBox}
    className={className}
    fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.41075 7.74408C9.73619 7.41864 10.2638 7.41864 10.5893 7.74408L13.9226 11.0774C14.248 11.4028 14.248 11.9305 13.9226 12.2559C13.5972 12.5814 13.0695 12.5814 12.7441 12.2559L10 9.51184L7.25593 12.2559C6.9305 12.5814 6.40286 12.5814 6.07742 12.2559C5.75198 11.9305 5.75198 11.4028 6.07742 11.0774L9.41075 7.74408Z"
      fill={color}
    />
  </svg>
);
