import { call, put, all, takeLatest, select } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";

import apiClient from "@Helpers/apiClient";
import { actionsSubMenu } from "./SubMenu.reducer";
import { SubmenuFetchPayload, SubmenuResponse } from "../types";
import { Item } from "../components/SubmenuSection/types";
import { getCategorySlice } from "./SubMenu.selector";
import { compareWithStringify } from "@/widgets/Sidebar/utils";

function* fetch({
  type,
  payload: { projectId, parentCategoryId },
}: PayloadAction<SubmenuFetchPayload>) {
  yield put(actionsSubMenu.startLoading(`${type}_${parentCategoryId}`));

  const isArchiveCategory = parentCategoryId === -4;
  const SUB_MENU_URL = `/v2/projects/${projectId}/${isArchiveCategory ? "archive" : "categories"}`;

  const params = parentCategoryId !== undefined && {
    parentId: parentCategoryId,
  };

  if (parentCategoryId === undefined) {
    console.error(
      "В случае отправки запроса без parentId дерево категорий приходит целиком в другом формате, чем предусмотрено в компонентах"
    );
  }

  try {
    const { data: responseCategories }: SubmenuResponse = yield call(
      apiClient.get,
      SUB_MENU_URL,
      params,
    );

    const prevCategory: Item[] = yield select(getCategorySlice(projectId));

    const isCategoryChanged = compareWithStringify({
      first: prevCategory,
      second: responseCategories,
    });

    if (isCategoryChanged) {
      if (parentCategoryId) {
        yield put(
          actionsSubMenu.addSubcategoryList({
            categories: responseCategories,
            parentId: parentCategoryId,
          })
        );
      } else {
        yield put(actionsSubMenu.mountList({ categories: responseCategories }));
      }
    }
  } catch (e) {
    if (e instanceof Error) {
      console.error("Ошибка при запросе списка категорий", e.message);
    } else {
      console.error("Неизвестная ошибка при запросе списка категорий", e);
    }
  } finally {
    yield put(actionsSubMenu.finishLoading(`${type}_${parentCategoryId}`));
  }
}

function* fetchSubMenu() {
  yield takeLatest(actionsSubMenu.fetchList, fetch);
}

export default function* subMenuSaga() {
  yield all([fetchSubMenu()]);
}
