export const FONTS = {
  display: {
    fs: '42px',
    lh: '52px',
    weight: '700',
  },
  heading1: {
    fs: '32px',
    lh: '42px',
    weight: '700',
  },
  heading2: {
    fs: '28px',
    lh: '36px',
    weight: '700',
  },
  heading3: {
    fs: '24px',
    lh: '28px',
    weight: '700',
  },
  heading4: {
    fs: '20px',
    lh: '24px',
    weight: '700',
  },
  heading5: {
    fs: '18px',
    lh: '20px',
    weight: '700',
  },
  subheading1: {
    fs: '16px',
    lh: '20px',
    weight: '700',
  },
  subheading2strong: {
    fs: '14px',
    lh: '16px',
    weight: '700',
  },
  subheading2: {
    fs: '14px',
    lh: '20px',
    weight: '600',
  },
  subheading3strong: {
    fs: '13px',
    lh: '16px',
    weight: '700',
  },
  subheading3: {
    fs: '13px',
    lh: '16px',
    weight: '600',
  },
  subheading4strong: {
    fs: '12px',
    lh: '16px',
    weight: '600',
  },
  subheading4: {
    fs: '12px',
    lh: '16px',
    weight: '600',
  },
  subheading5strong: {
    fs: '10px',
    lh: '12px',
    weight: '700',
  },
  subheading5: {
    fs: '10px',
    lh: '12px',
    weight: '600',
  },
  body1strong: {
    fs: '20px',
    lh: '24px',
    weight: '500',
  },
  body1: {
    fs: '20px',
    lh: '24px',
    weight: '400',
  },
  body2strong: {
    fs: '18px',
    lh: '20px',
    weight: '500',
  },
  body2: {
    fs: '18px',
    lh: '20px',
    weight: '400',
  },
  body3strong: {
    fs: '16px',
    lh: '20px',
    weight: '500',
  },
  body3: {
    fs: '16px',
    lh: '20px',
    weight: '400',
  },
  body4strong: {
    fs: '14px',
    lh: '20px',
    weight: '500',
  },
  body4: {
    fs: '14px',
    lh: '20px',
    weight: '400',
  },
  body5strong: {
    fs: '13px',
    lh: '16px',
    weight: '500',
  },
  body5: {
    fs: '13px',
    lh: '16px',
    weight: '400',
  },
  caption1strong: {
    fs: '12px',
    lh: '16px',
    weight: '500',
  },
  caption1: {
    fs: '12px',
    lh: '16px',
    weight: '400',
  },
  caption2strong: {
    fs: '10px',
    lh: '12px',
    weight: '500',
  },
  caption2: {
    fs: '10px',
    lh: '12px',
    weight: '400',
  },
} as const;
