import React, { FC, lazy, Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import NotFound from "./pages/NotFound";

import DocumentTitle from "./widgets/DocumentTitle";
import Loader from "@Features/Loader";

import GlobalStyle from "./GlobalStyle";
import { AppWrapper, AppWorkspace } from "./App.styled";

import Sidebar from "./widgets/Sidebar/Sidebar";
import Header from "./widgets/Header/Header";

import RedirectToLogin from "@/utils/hocs/RedirectToLogin";

const ProjectPage = lazy(() => import("./pages/ProjectPage"));
const LoginPage = lazy(() => import("./pages/LoginPage"));

const App: FC = () => {
  return (
    <BrowserRouter>
      <GlobalStyle />
      <Routes>
        <Route path="/" element={<RedirectToLogin />} />
        <Route path="login/*" element={<LoginPage />} />
        <Route
          path="projects/:projectId/*"
          element={
            <RedirectToLogin>
              <AppWrapper>
                <Sidebar />
                <AppWorkspace>
                  <Header />
                  <Suspense fallback={<Loader />}></Suspense>
                  <DocumentTitle />
                  <ProjectPage />
                </AppWorkspace>
              </AppWrapper>
            </RedirectToLogin>
          }
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};
export default App;
