import { useEffect } from "react";
import { useSelector } from "react-redux";
import { UserPanelIconWrapper, NotificationsIcon } from "../../Header.styled";
import { Badge } from "@/uikit/components";
import { useWS } from "@/ws";
import { getUserId } from "../../ducks/Profile.selector";

const Notifications = () => {
  const userId = useSelector(getUserId());
  const { data, sendData } = useWS("/user/messages");

  useEffect(() => {
    sendData({ path: "/app/echo", body: userId });
  }, [sendData, userId]);

  return (
    <UserPanelIconWrapper>
      <Badge
        count={data?.length || 0}
        Anchor={<NotificationsIcon />}
      />
    </UserPanelIconWrapper>
  );
};

export default Notifications;
