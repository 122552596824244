// @ts-nocheck
import { handleActions } from 'redux-actions';
import {
  fetchUpdatesList, 
  mountUpdatesList,
  fetchMoreUpdates,
  mountMoreUpdates,
  filteringUpdates,
  fetchFilteringUpdates,
  mountFilteringUpdates,
  markedUpdateAsRead,
  abordUpdates,
  catchUpdatesError
} from './Updates.actions';

const initialState = {
  isLoading: false,
  updatesList: [],
  pageNumber: 0,
  categoryId: null,
  empty: false,
  isContentLoading: false,
  last: false,
};

const UpdatesReducers = handleActions(
  {
    [fetchUpdatesList]: (state) => ({ ...state, isLoading: true }),
    [mountUpdatesList]: (state, { payload: { content, empty } }) => ({
      ...state,
      updatesList: content,
      isLoading: false,
      empty: empty,
    }),
    [filteringUpdates]: (state, { payload: categoryId }) => ({ 
      ...state, 
      categoryId: categoryId,  
    }),
    [fetchFilteringUpdates]: (state) => ({ 
      ...state,

      isLoading: true 
    }),
    [mountFilteringUpdates]: (state, { payload: { content, empty } }) => ({
      ...state,
      updatesList: content,
      isLoading: false,
      empty: empty,
    }),
    [fetchMoreUpdates]: (state, { payload: { pageNumber, categoryId }} ) => ({ 
      ...state,
      pageNumber: pageNumber,
      categoryId: categoryId,
      isContentLoading: true 
    }),
    [mountMoreUpdates]: (state, { payload: { content, number, last, categoryId } }) => ({
      ...state,
      updatesList: [...state.updatesList, ...content],
      pageNumber: number,
      isContentLoading: false,
      categoryId: categoryId,
      last: last,
    }),
    [markedUpdateAsRead]: (state) => ({ 
      ...initialState
    }),
    [abordUpdates]: (state) => ({ 
      ...initialState
    }),
    [catchUpdatesError]: (state) => ({
      ...initialState,
    }),
  },
  initialState,
);

export default UpdatesReducers;