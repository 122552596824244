
import React from 'react';

import { SettingsIcon, UserPanelIconWrapper } from '../../Header.styled';

const Settings = () => {
  return (
    <UserPanelIconWrapper>
      <SettingsIcon />
    </UserPanelIconWrapper>
  );
};

export default Settings;
