import {
  FC,
  useRef,
  useState,
  memo,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import { useSelector } from "react-redux";

import { useActionsProjects } from "../../ducks/Projects.reducer";
import {
  getLoadingProjectsStatus,
  getProjectsList,
} from "../../ducks/Projects.selector";
import Popover from "@suz/ui-kit/Popover";
import {
  ProjectsWrapper,
  PopperListWrapper,
  ProjectsSection,
  AddProjectButtonStyled,
} from "./Projects.styled";
import ScrollBox from "./ScrollBox";
import ProjectHeader from "./ProjectHeader";
import ProjectItem from "./ProjectItem";
import DragAndDrop from "./DragAndDrop";
import { getScrollBoxWidth } from "@Widgets/Sidebar/utils";
import {
  IProject,
  PositionChangeProps,
  ProjectEditProps,
  ProjectSelectProps,
} from "./types";
import { useImagesErrorChecker } from "@Hooks/useImageErrorChecker";
import { useActionsSidebar } from "@/widgets/Sidebar/ducks/Sidebar.reducer";
import { useActionsSubMenu } from "@/widgets/Sidebar/components/SubMenu/ducks/SubMenu.reducer";
// import { hasGlobalAdminAccess } from '../../../../utils';

const Projects: FC<{ activeProject: IProject }> = ({ activeProject }) => {
  const [open, setOpen] = useState<boolean>(false);

  const handleTogglePopper = useCallback(() => {
    setOpen((prevOpen) => !prevOpen);
  }, []);

  const handleHeaderClick = useCallback(() => {
    if (!open) handleTogglePopper();
  }, [open, handleTogglePopper]);

  const projects = useSelector(getProjectsList());
  const isLoadingProjects = useSelector(getLoadingProjectsStatus());
  const iconsImgErrors = useImagesErrorChecker(
    projects,
    "icon",
    (imagePath) => `/uploads/icons/${imagePath}`
  );
  const checkedIcon = (id: number, icon?: string) =>
    iconsImgErrors[id] ? undefined : icon;

  const isGlobalAdmin = false;
  // кнопки временно отключены для всех пользователей при подготовке к ПСИ
  // const isGlobalAdmin = hasGlobalAdminAccess();

  const projectsActions = useActionsProjects();
  const actionsSidebar = useActionsSidebar();
  const actionsSubMenu = useActionsSubMenu();

  useEffect(() => {
    if (!isLoadingProjects && (open || !projects.length))
      projectsActions.fetchProjects();
  }, [projectsActions, isLoadingProjects, open, projects.length]);

  const handlePositionChange: PositionChangeProps = useCallback(
    (changedProjects) => {
      projectsActions.changeOrderProjects(changedProjects);
    },
    [projectsActions]
  );

  const popperAnchorRef = useRef<HTMLDivElement | null>(null);

  const handleProjectSelect: ProjectSelectProps = useCallback(
    (projectId, isActive) => {
      if (!isActive) {
        projectsActions.fetchSelectedProject({ projectId: +projectId });
        actionsSidebar.setActiveMainPage();
        actionsSubMenu.resetCategoryOpened();
      }
      setOpen(false);
    },
    [projectsActions, actionsSidebar, actionsSubMenu]
  );

  const handleProjectCreate = () => {
    setOpen(false);
    alert("Будет реализовано создание");
  };

  const handleProjectEdit: ProjectEditProps = useCallback((projectId) => {
    setOpen(false);
    alert("Будет реализовано редактирование");
  }, []);

  const isEmptyProjects = !Array.isArray(projects) || projects?.length === 0;

  let scrollBoxWidth = useMemo(
    () => getScrollBoxWidth(projects, isGlobalAdmin),
    [projects, isGlobalAdmin]
  );

  return (
    <ProjectsWrapper ref={popperAnchorRef} onClick={handleHeaderClick}>
      <ProjectHeader
        project={{
          ...activeProject,
          icon: checkedIcon(activeProject.id, activeProject.icon),
        }}
      />
      <Popover
        open={open}
        onClose={handleTogglePopper}
        anchorEl={popperAnchorRef.current}
        marginThreshold={0}>
        <PopperListWrapper isEmptyProjects={isEmptyProjects}>
          <ProjectsSection>
            <div>ПРОЕКТЫ</div>
            {isGlobalAdmin && (
              <AddProjectButtonStyled onClick={handleProjectCreate} />
            )}
          </ProjectsSection>
          {!isLoadingProjects && !isEmptyProjects && (
            <ScrollBox width={scrollBoxWidth}>
              <DragAndDrop projects={projects} onChange={handlePositionChange}>
                {projects.map(({ id, icon, ...projectData }) => (
                  <ProjectItem
                    key={id}
                    id={id}
                    icon={checkedIcon(id, icon)}
                    isActive={activeProject?.id === id}
                    isGlobalAdmin={isGlobalAdmin}
                    onClickProjectEdit={handleProjectEdit}
                    onSelectProject={handleProjectSelect}
                    {...projectData}
                  />
                ))}
              </DragAndDrop>
            </ScrollBox>
          )}
        </PopperListWrapper>
      </Popover>
    </ProjectsWrapper>
  );
};

export default memo(Projects);
