import { MenuItemMapped } from "./types";

export enum MENU_ITEMS {
  MAIN = 1,
  FAVORITES = 2,
  USERS = 3,
  REPORTS = 4,
  FEEDBACK = 5,
  SECTION_INFO = 6,
  DOCUMENTS = 7,
  PARTICIPANTS = 8,
  SECTION_PROJECT_SETTINGS = 9,
  PROJECT_FILTERS = 10,
  PROJECT_FILTER_SETTINGS = 11,
  SEARCH_SETTINGS = 12,
  GLOSSARY_SETTINGS = 13,
  GLOSSARY_VIEW = 14,
  TAGS_SETTINGS = 15,
  ACHIEVEMENT_SETTINGS = 16,
  ARTICLE_TEMPLATES = 17,
  DIAGRAM_TEMPLATES = 18,
  CATEGORIES_ORDER_SETTINGS = 19,
  SEARCH_SYNONYMS = 20,
  SEARCH_GLOSSARY = 21,
  SECTION_PROJECT_ADMINISTRATION = 22,
  COMMENTS = 23,
  TESTS = 24,
  LINKS_MAP = 25,
  PROJECT_STATISTICS = 26,
  REPORT_COUNT_NEW_USERS_BY_CITIES = 27,
  REPORT_COUNT_USERS_BY_PROJECTS = 28,
  REPORT_COUNT_SAVED_POSTS_BY_PROJECT_FILTERS = 29,
  REPORT_COUNT_AVERAGE_SAVED_POSTS_BY_PROJECT_FILTERS = 30,
  REPORT_COUNT_SAVED_POSTS_BY_PROJECT = 31,
}

export const menuItemMapping: Record<number, MenuItemMapped> = {
  [MENU_ITEMS.MAIN]: {
    title: "Главная",
    linkEnd: "main",
    icon: "Home",
  },
  [MENU_ITEMS.FAVORITES]: {
    title: "Избранное",
    linkEnd: "favorite",
    icon: "Favorites",
  },
  [MENU_ITEMS.USERS]: {
    title: "Сотрудники",
    linkEnd: "users",
    icon: "PersonTwo",
  },
  [MENU_ITEMS.REPORTS]: {
    title: "Отчеты",
    linkEnd: "reports",
    icon: "Report",
  },
  [MENU_ITEMS.FEEDBACK]: {
    title: "Обратная связь",
    linkEnd: "feedback",
    icon: "ChatCircle",
  },
  [MENU_ITEMS.SECTION_INFO]: {
    title: "Информация",
    linkEnd: "section-info",
    isSection: true,
  },
  [MENU_ITEMS.DOCUMENTS]: {
    title: "Документы",
    linkEnd: "documents",
    icon: "Sheet",
  },
  [MENU_ITEMS.PARTICIPANTS]: {
    title: "Участники",
    linkEnd: "participants",
    icon: "PersonThree",
  },
  [MENU_ITEMS.SECTION_PROJECT_SETTINGS]: {
    title: "Настройки проекта",
    linkEnd: "section-project-settings",
    isSection: true,
  },
  [MENU_ITEMS.PROJECT_FILTERS]: {
    title: "Проектные фильтры",
    linkEnd: "project-filters",
    icon: "Filters",
  },
  [MENU_ITEMS.PROJECT_FILTER_SETTINGS]: {
    title: "Настройка проектных фильтров",
    linkEnd: "project-filters",
  },
  [MENU_ITEMS.SEARCH_SETTINGS]: {
    title: "Настройка поиска",
    linkEnd: "search/settings",
  },
  [MENU_ITEMS.GLOSSARY_SETTINGS]: {
    title: "Настройка глоссария",
    linkEnd: "glossary/settings",
  },
  [MENU_ITEMS.GLOSSARY_VIEW]: {
    title: "Просмотр глоссария",
    linkEnd: "glossary/view",
  },
  [MENU_ITEMS.TAGS_SETTINGS]: {
    title: "Настройка тегов",
    linkEnd: "tags",
  },
  [MENU_ITEMS.ACHIEVEMENT_SETTINGS]: {
    title: "Настройка достижений",
    linkEnd: "achievements",
  },
  [MENU_ITEMS.ARTICLE_TEMPLATES]: {
    title: "Настройка шаблонов статей",
    linkEnd: "article-templates",
  },
  [MENU_ITEMS.DIAGRAM_TEMPLATES]: {
    title: "Шаблоны блок-схем",
    linkEnd: "diagram-templates",
  },
  [MENU_ITEMS.CATEGORIES_ORDER_SETTINGS]: {
    title: "Настройка структуры категорий",
    linkEnd: "categories/order-settings",
  },
  [MENU_ITEMS.SEARCH_SYNONYMS]: {
    title: "Синонимы поиска",
    linkEnd: "search/settings/synonyms",
  },
  [MENU_ITEMS.SEARCH_GLOSSARY]: {
    title: "Словарь синонимов",
    linkEnd: "search/settings/synonyms",
  },
  [MENU_ITEMS.SECTION_PROJECT_ADMINISTRATION]: {
    title: "Администрирование проекта",
    linkEnd: "section-project-administration",
    isSection: true,
  },
  [MENU_ITEMS.COMMENTS]: {
    title: "Обработка комментариев",
    linkEnd: "comments",
  },
  [MENU_ITEMS.TESTS]: {
    title: "Результаты тестов",
    linkEnd: "tests",
  },
  [MENU_ITEMS.LINKS_MAP]: {
    title: "Карта ссылок",
    linkEnd: "links",
  },
  [MENU_ITEMS.PROJECT_STATISTICS]: {
    title: "Статистика проекта",
    linkEnd: "statistics",
    icon: "ChatBarVertical",
  },
  [MENU_ITEMS.REPORT_COUNT_NEW_USERS_BY_CITIES]: {
    parentId: 4,
    title: "Количество добавленных пользователей по городам",
    linkEnd: "reports/count-new-users-by-cities",
  },
  [MENU_ITEMS.REPORT_COUNT_USERS_BY_PROJECTS]: {
    parentId: 4,
    title: "Количество участников в проектах",
    linkEnd: "reports/count-users-by-projects",
  },
  [MENU_ITEMS.REPORT_COUNT_SAVED_POSTS_BY_PROJECT_FILTERS]: {
    parentId: 4,
    title: "Количество сохраненных статей в проектах по фильтрам",
    linkEnd: "reports/count-saved-posts-by-project-filters",
  },
  [MENU_ITEMS.REPORT_COUNT_AVERAGE_SAVED_POSTS_BY_PROJECT_FILTERS]: {
    parentId: 4,
    title: "Среднее количество сохраненных статей в проекте по фильтрам",
    linkEnd: "reports/count-average-saved-posts-by-project-filters",
  },
  [MENU_ITEMS.REPORT_COUNT_SAVED_POSTS_BY_PROJECT]: {
    parentId: 4,
    title: "Количество сохраненных статей в проектах",
    linkEnd: "reports/count-saved-posts-by-project",
  },
};
