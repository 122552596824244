
import { useEffect, useContext, useRef } from 'react';
import { WSContext } from './WsContext';

export const useWS = (url) => {
  const { conected, websocket, data, setData, setWatchers, watchers } = useContext(WSContext);
  const timerId = useRef();
  const sendData = ({ headers = {}, body, path = '' }) => {
    timerId.current = null;

    if (websocket?.current?.connected) {
      websocket?.current?.send(path || url, headers, body);
    } else {
      timerId.current = setTimeout(() => {
        sendData({ headers, body, path });
      }, 300);
    }
  };
  const setCurrentData = (payload) => {
    setData({
      ...data,
      [url]: payload,
    });
  };

  useEffect(() => {
    setWatchers((prevState) => {
      const value = Number.isNaN(parseInt(prevState[url])) ? 0 : prevState[url];
      return {
        ...prevState,
        [url]: value + 1,
      };
    });
  }, []);

  useEffect(() => {
    if (!conected) {
      return;
    }

    websocket?.current?.subscribe(
      url,
      ({ body }) => {
        const payload = JSON.parse(body);
        const isArrayPayload = Array.isArray(payload);
        const getFinalPayload = (store) => {
          const storeData = store[url];

          if (!storeData) {
            return payload;
          }

          return isArrayPayload ? [...storeData, ...payload] : payload;
        };

        setData((prevState) => ({
          ...prevState,
          [url]: getFinalPayload(prevState),
        }));
      },
      { id: url },
    );
  }, [conected]);

  useEffect(
    () => () => {
      setWatchers((prevState) => {
        const nextWatchers = prevState[url] - 1;

        if (nextWatchers === 0) {
          websocket?.current?.unsubscribe(url);
        }

        return {
          ...prevState,
          [url]: nextWatchers,
        };
      });

      if (timerId.current) {
        clearTimeout(timerId.current);
      }
    },
    [],
  );

  return {
    sendData,
    data: data[url],
    store: data,
    setData: setCurrentData,
  };
};
