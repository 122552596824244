import React, { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

const RedirectToLogin: FC<{ children?: React.ReactNode }> = ({ children }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!Cookies.get("WSUSERID")) {
      navigate({ pathname: "/login" });
    }
  }, []);

  return <>{children}</>;
};

export default RedirectToLogin;
