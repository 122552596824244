import { useState } from 'react';
import { DropResult } from 'react-beautiful-dnd';
import { DragAndDropParams } from './types';

const useDragAndDrop = (params: DragAndDropParams) => {
  const { onChange } = params;

  const [disableDrag, setDisableDrag] = useState(false);

  const onDragEnd = ({ destination, source }: DropResult) => {
    setDisableDrag(false);
    const newIndex = destination?.index;
    const oldIndex = source?.index;

    if (newIndex === undefined) return;

    onChange?.({ oldIndex, newIndex });
  };

  const onDragStart = () => {};

  return {
    rootProps: {},
    providerProps: {
      onDragEnd,
      onDragStart,
    },
    itemProps: {
      disableDrag,
    },
  };
};

export default useDragAndDrop;
