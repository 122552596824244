import React, { FC, forwardRef, memo, createContext, useContext } from "react";
import DragAndDrop from "@suz/ui-kit/DragAndDrop";

import {
  DragAndDropIcon,
  DragAndDropWrapper,
  DragIconContainer,
} from "./Projects.styled";
import {
  DragContainerProps,
  DropContainerProps,
  DragAndDropProps,
  PositionChangeParams,
} from "./types";

export const DraggingContext = createContext(false);
export const useDraggingOver = () => useContext(DraggingContext);

const DragContainer = forwardRef<HTMLDivElement, DragContainerProps>(
  ({ children, draggableProps, dragHandleProps, draggableStateProps }, ref) => (
    <div
      ref={ref}
      {...draggableProps}
      style={{
        ...draggableProps.style,
      }}
    >
      <DragIconContainer {...dragHandleProps}>
        <DragAndDropIcon />
      </DragIconContainer>
      {children}
    </div>
  )
);

const DropContainer = forwardRef<HTMLDivElement, DropContainerProps>(
  ({ children, droppableStateProps }, ref) => {
    return (
      <DraggingContext.Provider value={droppableStateProps.isDraggingOver}>
        <DragAndDropWrapper ref={ref}>{children}</DragAndDropWrapper>
      </DraggingContext.Provider>
    );
  }
);

const DragAndDropContainer: FC<DragAndDropProps> = ({
  projects,
  onChange,
  children,
}) => {
  const onPositionChange = ({
    oldIndex,
    newIndex,
  }: PositionChangeParams): void => {
    if (oldIndex !== newIndex) {
      const changedProjects = [...projects];
      const [removed] = changedProjects.splice(oldIndex, 1);
      if (removed === undefined) return;

      changedProjects.splice(newIndex, 0, removed);

      onChange?.(changedProjects);
    }
  };

  return (
    <DragAndDrop
      onChange={onPositionChange}
      slots={{
        dragContainer: DragContainer,
        dropContainer: DropContainer,
      }}
    >
      {children}
    </DragAndDrop>
  );
};

export default memo(DragAndDropContainer);
