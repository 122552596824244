import { useMemo } from "react";
import { useDispatch } from "react-redux";
import {
  createAction,
  createSlice,
  PayloadAction,
  bindActionCreators,
} from "@reduxjs/toolkit";

const initialState = {
  isLoading: [] as string[],
  isError: false,
};

const NAMESPACE = "[Auth]";

export type Login = { login: string };

export type LoginData = {
  body: Login & { pass: string };
  success: Function;
};

export type LdapCallbacks = {
  success: Function;
  error: Function;
};

export type ResetData = Login & { onSuccess: (email: string) => void };

const login = createAction<LoginData>(`${NAMESPACE}/login`);
const ldap = createAction<LdapCallbacks>(`${NAMESPACE}/ldap`);
const resetPassword = createAction<ResetData>(`${NAMESPACE}/resetPassword`);

const LoginSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    startLoading: (state, { payload }: PayloadAction<string>) => {
      state.isLoading.push(payload);
    },
    finishLoading: (state, { payload }: PayloadAction<string>) => {
      state.isLoading = state.isLoading.filter((item) => item !== payload);
    },
    setError: (state, { payload }: PayloadAction<boolean>) => {
      state.isError = payload;
    },
  },
});

export const actionsLogin = {
  ...LoginSlice.actions,
  login,
  ldap,
  resetPassword,
};

export const useActionsLogin = () => {
  const dispatch = useDispatch();

  return useMemo(() => bindActionCreators(actionsLogin, dispatch), [dispatch]);
};

export default LoginSlice.reducer;
