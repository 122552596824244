/* eslint-disable max-len */
import React, { FC } from 'react';
import { IconProps } from '../types';

export const PersonOne: FC<IconProps> = ({
  size = '20',
  color = '#445371',
  transform,
  viewBox = '0 0 20 20',
  className,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    transform={transform}
    viewBox={viewBox}
    className={className}
    fill="none">
    <path
      d="M4.16669 17.5C4.16669 14.2783 6.77836 11.6667 10 11.6667C13.2217 11.6667 15.8334 14.2783 15.8334 17.5M13.3334 5.83333C13.3334 7.67428 11.841 9.16667 10 9.16667C8.15907 9.16667 6.66669 7.67428 6.66669 5.83333C6.66669 3.99238 8.15907 2.5 10 2.5C11.841 2.5 13.3334 3.99238 13.3334 5.83333Z"
      stroke={color}
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
