/* eslint-disable max-len */
import React, { FC } from 'react';
import { IconProps } from '../types';

export const CheckBig: FC<IconProps> = ({
  size = '20',
  color = '#445371',
  transform,
  viewBox = '0 0 20 20',
  className,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    transform={transform}
    viewBox={viewBox}
    className={className}
    fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.435 5.29289C17.8256 5.68342 17.8256 6.31658 17.435 6.70711L8.94975 15.1924C8.55922 15.5829 7.92606 15.5829 7.53553 15.1924L3.29289 10.9497C2.90237 10.5592 2.90237 9.92606 3.29289 9.53553C3.68342 9.14501 4.31658 9.14501 4.70711 9.53553L8.24264 13.0711L16.0208 5.29289C16.4113 4.90237 17.0445 4.90237 17.435 5.29289Z"
      fill={color}
    />
  </svg>
);
