/* eslint-disable max-len */
import React, { FC } from 'react';
import { IconProps } from '../types';

export const ChatSquare: FC<IconProps> = ({
  size = '20',
  color = '#445371',
  transform,
  viewBox = '0 0 20 20',
  className,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    transform={transform}
    viewBox={viewBox}
    className={className}
    fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.66667 5C1.66667 4.07953 2.41286 3.33333 3.33333 3.33333H16.6667C17.5871 3.33333 18.3333 4.07952 18.3333 5V13.3333C18.3333 14.2538 17.5871 15 16.6667 15H6.95899L4.37449 17.0676C3.28322 17.9406 1.66667 17.1637 1.66667 15.7661V5ZM16.6667 5H3.33333V15.7661L5.91783 13.6986C6.21335 13.4621 6.58053 13.3333 6.95899 13.3333H16.6667V5Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.0893 6.91074C13.4147 7.23618 13.4147 7.76382 13.0893 8.08926L9.75592 11.4226C9.43049 11.748 8.90285 11.748 8.57741 11.4226L6.91075 9.75592C6.58531 9.43048 6.58531 8.90285 6.91075 8.57741C7.23618 8.25197 7.76382 8.25197 8.08926 8.57741L9.16667 9.65482L11.9107 6.91074C12.2362 6.58531 12.7638 6.58531 13.0893 6.91074Z"
      fill={color}
    />
  </svg>
);
