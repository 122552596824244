/* eslint-disable max-len */
import React, { FC } from 'react';
import { IconProps } from '../types';

export const LikeFilled: FC<IconProps> = ({
  size = '20',
  color = '#445371',
  transform,
  viewBox = '0 0 20 20',
  className,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    transform={transform}
    viewBox={viewBox}
    className={className}
    fill="none">
    <path
      d="M3.33333 17.5H4.16667V6.66667H3.33333C2.89131 6.66667 2.46738 6.84227 2.15482 7.15483C1.84226 7.46739 1.66667 7.89131 1.66667 8.33334V15.8333C1.66667 16.2754 1.84226 16.6993 2.15482 17.0118C2.46738 17.3244 2.89131 17.5 3.33333 17.5ZM16.6667 6.66667H10.8333L11.7683 3.86C11.8518 3.60952 11.8745 3.34279 11.8346 3.0818C11.7948 2.82081 11.6935 2.57302 11.5391 2.35883C11.3848 2.14465 11.1817 1.97021 10.9467 1.84987C10.7117 1.72953 10.4515 1.66674 10.1875 1.66667H10L5.83333 6.19834V17.5H15L18.26 10.3367L18.3333 10V8.33334C18.3333 7.89131 18.1577 7.46739 17.8452 7.15483C17.5326 6.84227 17.1087 6.66667 16.6667 6.66667Z"
      fill={color}
    />
  </svg>
);
