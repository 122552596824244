import styled, { css } from "styled-components";
import { NavLink } from "react-router-dom";

import Icon, { StyledIconProps } from "@suz/ui-kit/Icon";
import ToggleSubCategoryButton from "../ToggleCategoryButton";
import Badge from "@/uikit/components/Badge/Badge";
import {
  MenuCounterProps,
  MenuItemContainerProps,
  MenuItemWrapperProps,
  MenuWrapperProps,
  ToggleMenuButtonProps,
} from "./types";

export const MenuWrapper = styled.div<MenuWrapperProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: ${({ lvl }) => `${12 * lvl}px`};
  width: 100%;
  max-width: 396px;
  margin: 0;
`;

export const MenuItemIcon = styled(Icon).attrs<StyledIconProps>(({ icon }) => ({
  icon: icon ?? "ReportAccept",
  color: "#445371",
}))`
  min-width: 20px;
`;

export const MenuToggleIcon = styled(Icon).attrs<ToggleMenuButtonProps>(
  ({ isOpen }) => ({
    icon: isOpen ? "ArrowMUp" : "ArrowMDown",
    color: "#69758e",
  })
)`
  margin: 0 8px 0 0;
  min-width: 20px;
`;

export const ToggleMenuTitle = styled.span`
  font-size: 14px;
  font-weight: 500;
  line-height: 142.857%;
  text-align: left;
  color: #445371;
`;

export const MenuCounter = styled(Badge).attrs({
  color: "secondary",
})<MenuCounterProps>`
  margin-left: auto;
`;

export const ToggleMenuButton = styled.button`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 16px;
  border-radius: 10px;
  position: relative;
  align-self: stretch;
  border-radius: 8px;
  background-color: #f9fafb;
  outline: none;
  border: none;
  box-sizing: border-box;
  margin: 0px 8px 4px 8px;
  cursor: pointer;

  &:hover,
  &:active {
    background-color: #eceef1;
  }

  &:active {
    ${ToggleMenuTitle} {
      color: #303a4f;
    }

    ${MenuToggleIcon} {
      path {
        fill: #445371;
      }
    }
  }
`;

export const MenuSectionTitle = styled.span`
  display: flex;
  width: 100%;
  height: 32px;
  color: #69758e;
  padding: 12px 24px 4px;
  font-size: 12px;
  font-weight: 600;
  line-height: 130%;
  user-select: none;
`;

export const ToggleMenuCategoryButton = styled(ToggleSubCategoryButton)`
  display: inline-flex;
  left: 0;
`;

export const MenuItemTitle = styled.h3`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: #303a4f;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 142.857%;
`;

export const MenuItemWrapper = styled.div<MenuItemWrapperProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0;
  margin-bottom: ${({ isLastSectionBlock }) =>
    isLastSectionBlock ? 0 : "4px"};
`;

export const MenuItemContainer = styled(NavLink)<MenuItemContainerProps>`
  position: relative;
  display: flex;
  align-items: center;
  align-self: stretch;
  margin: 0 8px;
  padding: 8px 16px;
  border-radius: 8px;
  gap: 8px;
  cursor: pointer;

  ${({ isActive }) =>
    isActive
      ? css`
          background-color: #e6eeff;

          ${MenuItemTitle} {
            color: #0055ff;
          }

          ${MenuItemIcon} {
            path {
              fill: #0055ff;
            }
          }

          ${ToggleMenuCategoryButton} {
            path {
              fill: #3377ff;
            }
          }
        `
      : css`
          &:hover {
            background-color: #eceef1;
            transition: all 0.2s ease-out;

            ${MenuItemIcon} {
              path {
                fill: #303a4f;
              }
            }
          }
        `}
`;
