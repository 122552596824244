import { COLORS } from '@suz/ui-utils/constants/Colors';
import styled from 'styled-components';

export const ButtonWrap = styled.button<{isAccent?: boolean}>`
  display: inline-block;
  padding: 0;
  width: 24px;
  height: 24px;
  font-size: 24px;
  line-height: 24px;
  text-align: center;
  color: ${({ isAccent }) => isAccent ? COLORS.accent400 : COLORS.neutral400};
  outline: none;
  border: none;
  border-radius: 8px;
  background-color: transparent;
  box-sizing: border-box;
  cursor: pointer;
  transition: color 0.2s ease-out, background-color 0.2s ease-out;
  &:hover {
    color: ${({ isAccent }) => isAccent ? COLORS.accent500 : COLORS.neutral500};
    background-color: ${({ isAccent }) => isAccent ? COLORS.accent75 : COLORS.neutral75};
  }
  &:active {
    color: ${({ isAccent }) => isAccent ? COLORS.accent500 : COLORS.neutral500};
    background-color: transparent;
  }
`;
