/* eslint-disable max-len */
import React, { FC } from 'react';
import { IconProps } from '../types';

export const ChatBarVertical: FC<IconProps> = ({
  size = '20',
  color = '#445371',
  transform,
  viewBox = '0 0 20 20',
  className,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    transform={transform}
    viewBox={viewBox}
    className={className}
    fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.7 10C1.7 9.09793 2.43127 8.36667 3.33333 8.36667H7.5C7.94182 8.36667 8.3 8.72484 8.3 9.16667V16.6667C8.3 17.1085 7.94182 17.4667 7.5 17.4667H2.5C2.05817 17.4667 1.7 17.1085 1.7 16.6667V10ZM3.33333 9.96667C3.31492 9.96667 3.3 9.98159 3.3 10V15.8667H6.7V9.96667H3.33333Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.7 6.66667C11.7 6.22484 12.0582 5.86667 12.5 5.86667H16.6667C17.5687 5.86667 18.3 6.59793 18.3 7.5V16.6667C18.3 17.1085 17.9418 17.4667 17.5 17.4667H12.5C12.0582 17.4667 11.7 17.1085 11.7 16.6667V6.66667ZM13.3 7.46667V15.8667H16.7V7.5C16.7 7.48159 16.6851 7.46667 16.6667 7.46667H13.3Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.7 4.16667C6.7 3.2646 7.43126 2.53333 8.33333 2.53333H11.6667C12.5687 2.53333 13.3 3.2646 13.3 4.16667V16.6667C13.3 17.1085 12.9418 17.4667 12.5 17.4667L7.5 17.4667C7.05817 17.4667 6.7 17.1085 6.7 16.6667V4.16667ZM8.33333 4.13333C8.31492 4.13333 8.3 4.14826 8.3 4.16667V15.8667H11.7V4.16667C11.7 4.14826 11.6851 4.13333 11.6667 4.13333H8.33333Z"
      fill={color}
    />
  </svg>
);
