import { useMemo } from "react";
import { useDispatch } from "react-redux";
import {
  PayloadAction,
  bindActionCreators,
  createAction,
  createSlice,
} from "@reduxjs/toolkit";

import { MenuFetchPayload, MenuItemProps, MenuState } from "../types";

const initialState: MenuState = {
  isLoading: false,
  isUnfoldMenu: false,
  menuItemOpenedId: null,
  menu: [],
  notificationsFeedbackCount: 0,
  error: null,
};

const NAMESPACE = "[Menu]";
const FETCH_MENU = `${NAMESPACE}/fetchMenu`;
const FETCH_FEEDBACK_NOTIFICATIONS_COUNT = `${NAMESPACE}/fetchFeedbackNotificationsCount`;

const fetchMenu = createAction<MenuFetchPayload>(FETCH_MENU);
const fetchNotificationsFeedback = createAction(FETCH_FEEDBACK_NOTIFICATIONS_COUNT);

const MenuSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    setLoading(state, { payload }: PayloadAction<boolean>) {
      state.isLoading = payload;
    },
    mountMenu(state, { payload }: PayloadAction<MenuItemProps[]>) {
      state.menu = payload;
    },
    setError(state, { payload }: PayloadAction<string | null>) {
      state.error = payload;
    },
    openMainSidebarMenu(state) {
      state.isUnfoldMenu = true;
    },
    closeMainSidebarMenu(state) {
      state.isUnfoldMenu = false;
    },
    toggleMenuItemOpened(state, { payload }: PayloadAction<number | null>) {
      state.menuItemOpenedId = payload;
    },
    setNotificationsFeedbackCount(state, { payload }: PayloadAction<number>) {
      state.notificationsFeedbackCount = payload;
    },
  },
});

export const actionsMenu = {
  ...MenuSlice.actions,
  fetchMenu,
  fetchNotificationsFeedback,
};

export const useActionsMenu = () => {
  const dispatch = useDispatch();

  return useMemo(() => bindActionCreators(actionsMenu, dispatch), [dispatch]);
};

export default MenuSlice.reducer;
