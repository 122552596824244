import styled from 'styled-components';
import { COLORS } from '@suz/ui-utils/constants/Colors';
import { StyledTooltipProps, StyledArrowProps } from './types';
import { Shape, Placement, Size } from '@suz/ui-utils/types/general';

const SHAPE: Record<Shape, string> = {
  circular: '32px',
  rounded: '4px',
  geometric: '0',
};

const FONT_SIZE: Record<Size, string> = {
  large: '18px',
  medium: '14px',
  small: '12px',
};

const TRANSFORM: Record<Placement, string> = {
  'top': '0 100%',
  'top-end': '0 100%',
  'top-start': '0 100%',
  'bottom': '0 100%',
  'bottom-end': '0 100%',
  'bottom-start': '0 100%',
  'left': '0 0',
  'left-end': '0 0',
  'left-start': '0 0',
  'right': '100% 100%',
  'right-end': '100% 100%',
  'right-start': '100% 100%',
};

export const StyledTooltip = styled.div<StyledTooltipProps>`
  position: relative;
  border-radius: ${({ shape = 'rounded' }) => SHAPE[shape]};
  padding: 4px 8px;
  background-color: #747479;
  color: ${COLORS.neutral0};
  font-family: Inter, sans-serif;
  font-size: ${({ fontSize = 'large' }) => FONT_SIZE[fontSize]};
  font-weight: 400;
  line-height: 150%;
  margin-top: ${({ placement }) => (placement.indexOf('bottom') !== -1 ? '10px' : 0)};
  margin-bottom: ${({ placement }) => (placement.indexOf('top') !== -1 ? '10px' : 0)};
  margin-left: ${({ placement }) => (placement.indexOf('right') !== -1 ? '10px' : 0)};
  margin-right: ${({ placement }) => (placement.indexOf('left') !== -1 ? '10px' : 0)};
`;

const StyledArrow = styled.div<StyledArrowProps>`
  overflow: hidden;
  position: absolute;
  width: 1em;
  height: 0.71em;
  box-sizing: border-box;
  color: #747479;

  :before {
    content: "";
    margin: auto;
    display: block;
    width: 100%;
    height: 100%;
    background-color: #747479;
    transform: rotate(45deg);
    transform-origin: ${({ placement }) => TRANSFORM[placement]};
  }
`;

export const StyledTopArrow = styled(StyledArrow)`
  transform: rotate(180deg);
  left: calc(50% - 5px);
  margin-bottom: -0.69em;
  bottom: 0;
`;

export const StyledBottomArrow = styled(StyledArrow)`
  left: calc(50% - 5px);
  margin-top: -0.69em;
  top: 0;
`;

export const StyledLeftArrow = styled(StyledArrow)`
  right: 0;
  margin-right: -0.71em;
  height: 1em;
  width: 0.71em;
  top: calc(50% - 5px);
`;

export const StyledRightArrow = styled(StyledArrow)`
  margin-left: -0.71em;
  height: 1em;
  width: 0.71em;
  top: calc(50% - 5px);
  left: 0;
`;
