import { Archive } from './Archive'; 
import { Archives } from './Archives';
import { ArrowLDown } from './ArrowLDown';
import { ArrowLleft } from './ArrowLleft';
import { ArrowLRight } from './ArrowLRight';
import { ArrowLUp } from './ArrowLUp';
import { ArrowMDown } from './ArrowMDown';
import { ArrowMLeft } from './ArrowMLeft';
import { ArrowMRight } from './ArrowMRight';
import { ArrowMUp } from './ArrowMUp';
import { ArrowSDown } from './ArrowSDown';
import { ArrowSLeft } from './ArrowSLeft';
import { ArrowSRight } from './ArrowSRight';
import { ArrowSUp } from './ArrowSUp';
import { ArrowsUpDown } from './ArrowsUpDown';
import { Blank } from './Blank';
import { Calendar } from './Calendar';
import { CancelL } from './CancelL';
import { CancelM } from './CancelM';
import { CancelS } from './CancelS';
import { ChatBarVertical } from './ChatBarVertical';
import { ChatCircle } from './ChatCircle';
import { ChatSquare } from './ChatSquare';
import { CheckAll } from './CheckAll';
import { CheckBig } from './CheckBig';
import { CloudUpload } from './CloudUpload';
import { Command } from './Command';
import { Communication } from './Communication';
import { Delete } from './Delete';
import { Download } from './Download';
import { Draft } from './Draft';
import { Drafts } from './Drafts';
import { DragVertical } from './DragVertical';
import { EditM } from './EditM';
import { EditS } from './EditS';
import { Exit } from './Exit';
import { EyeM } from './EyeM';
import { EyeS } from './EyeS';
import { EyeClosed } from './EyeClosed';
import { Favorites } from './Favorites';
import { Files } from './Files';
import { Filters } from './Filters';
import { FiltersSquare } from './FiltersSquare';
import { Folder } from './Folder';
import { FolderOpened } from './FolderOpened';
import { Group } from './Group';
import { GroupAdd } from './GroupAdd';
import { Heart } from './Heart';
import { HeartFilled } from './HeartFilled';
import { Home } from './Home';
import { Like } from './Like';
import { LikeFilled } from './LikeFilled';
import { LinkM } from './LinkM';
import { LinkS } from './LinkS';
import { Lock } from './Lock';
import { Move } from './Move';
import { News } from './News';
import { Notifications } from './Notifications';
import { PersonCircle } from './PersonCircle';
import { PersonOne } from './PersonOne';
import { PersonThree } from './PersonThree';
import { PersonTwo } from './PersonTwo';
import { Pin } from './Pin';
import { Placeholder } from './Placeholder';
import { Plus } from './Plus';
import { PlusCircle } from './PlusCircle';
import { Print } from './Print';
import { Report } from './Report';
import { ReportAccept } from './ReportAccept';
import { SchemeFilled } from './SchemeFilled';
import { SchemeM } from './SchemeM';
import { SchemeSFilled } from './SchemeSFilled';
import { Search } from './Search';
import { Settings } from './Settings';
import { Sheet } from './Sheet';
import { Sidebar } from './Sidebar';
import { SortAscending } from './SortAscending';
import { StarFilled } from './StarFilled';
import { Tag } from './Tag';
import { TagAdd } from './TagAdd';

export const ICONS = {
  Archive, 
  Archives,
  ArrowLDown,
  ArrowLleft,
  ArrowLRight,
  ArrowLUp,
  ArrowMDown,
  ArrowMLeft,
  ArrowMRight,
  ArrowMUp,
  ArrowSDown,
  ArrowSLeft,
  ArrowSRight,
  ArrowSUp,
  ArrowsUpDown,
  Blank,
  Calendar,
  CancelL,
  CancelM,
  CancelS,
  ChatBarVertical,
  ChatCircle,
  ChatSquare,
  CheckAll,
  CheckBig,
  CloudUpload,
  Command,
  Communication,
  Delete,
  Download,
  Draft,
  Drafts,
  DragVertical,
  EditM,
  EditS,
  Exit,
  EyeM,
  EyeS,
  EyeClosed,
  Favorites,
  Files,
  Filters,
  FiltersSquare,
  Folder,
  FolderOpened,
  Group,
  GroupAdd,
  Heart,
  HeartFilled,
  Home,
  Like,
  LikeFilled,
  LinkM,
  LinkS,
  Lock,
  Move,
  News,
  Notifications,
  PersonCircle,
  PersonOne,
  PersonThree,
  PersonTwo,
  Pin,
  Placeholder,
  Plus,
  PlusCircle,
  Print,
  Report,
  ReportAccept,
  SchemeFilled,
  SchemeM,
  SchemeSFilled,
  Search,
  Settings,
  Sheet,
  Sidebar,
  SortAscending,
  StarFilled,
  Tag,
  TagAdd
};