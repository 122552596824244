/* eslint-disable max-len */
import React, { FC } from 'react';
import { IconProps } from '../types';

export const EditS: FC<IconProps> = ({
  size = '16',
  color = '#69758E',
  transform,
  viewBox = '0 0 16 16',
  className,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    transform={transform}
    viewBox={viewBox}
    className={className}
    fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.72386 2.66665C10.2446 2.14595 11.0888 2.14595 11.6095 2.66665L13.3333 4.39051C13.854 4.91121 13.854 5.75542 13.3333 6.27612L5.80474 13.8047C5.67971 13.9297 5.51014 14 5.33333 14H2.66667C2.29848 14 2 13.7015 2 13.3333V10.6666C2 10.4898 2.07024 10.3203 2.19526 10.1952L9.72386 2.66665ZM12.3905 5.33331L10.6667 3.60946L3.33333 10.9428V12.6666H5.05719L12.3905 5.33331Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.5286 4.86191C7.78895 4.60156 8.21106 4.60156 8.47141 4.86191L11.1381 7.52857C11.3984 7.78892 11.3984 8.21103 11.1381 8.47138C10.8777 8.73173 10.4556 8.73173 10.1953 8.47138L7.5286 5.80472C7.26825 5.54437 7.26825 5.12226 7.5286 4.86191Z"
      fill={color}
    />
  </svg>
);
