import styled from "styled-components";
import { ITabStyles } from "./types";
import { COLORS } from "@suz/ui-utils/constants/Colors";
import { SPACING } from "@suz/ui-utils/constants/defaultValues";
import Icon from "@suz/ui-kit/Icon";

export const Wrap = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
`;

export const Tab = styled.div<ITabStyles>`
  position: relative;
  display: ${({ display = "inline-flex" }) => display};
  max-width: 240px;
  min-height: auto;
  padding: ${({ p = `${SPACING.none} ${SPACING.xs}` }) => p};
  margin: ${({ m = SPACING.none }) => m};
  color: ${({ isTabActive }) =>
    isTabActive ? COLORS.neutral700 : COLORS.neutral200};
  text-align: center;
  box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  text-overflow: ellipsis;
  border: 1px solid ${COLORS.neutral75};
  border-bottom: none;
  background-color: ${({ isTabActive }) =>
    isTabActive ? COLORS.neutral0 : COLORS.neutral25};
  cursor: pointer;
  transition: all 0.2s ease-out;
  &:hover {
    color: ${COLORS.neutral700};
  }
`;

export const IconWrap = styled(Icon)`
  height: 16px;
  margin-top: 2px;
  margin-right: 4px;
  font-size: 16px;
`;
