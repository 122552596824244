import { ActiveColor, Shape } from '@suz/ui-utils/types/general';
import { CSSProperties, HTMLAttributes } from 'react';

export const SHAPES = {
  geometric: '0px',
  rounded: '8px',
  circular: '24px',
};

export interface IChipStyles {
  shape?: Shape;
  color?: ActiveColor;
  selected?: boolean;
  disabled?: boolean;
}

export interface IChip extends Omit<HTMLAttributes<HTMLButtonElement>, 'onClick'> {
  /** Устанавливает атрибут selected  */
  selected?: boolean;
  /** Устанавливает атрибут disabled */
  disabled?: boolean;
  /** Значение счетчика */
  amount: string | string;
  /** Цвет активного чипа */
  color?: ActiveColor;
  /** Задает скругление */
  shape?: Shape;
  /** id элемента */
  id?: string;
  /** Имя элемента */
  name?: string;
  /** Стили контейнера */
  style?: CSSProperties;
  /** onClick callback */
  onClick?: (
    selected: boolean,
    amount?: number | string,
    name?: string,
    id?: string
  ) => void;
}
