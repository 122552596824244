import styled from 'styled-components';
import { COLORS } from '@suz/ui-utils/constants/Colors';
import { SPACING, SHAPE } from '@suz/ui-utils/constants/defaultValues';
import { StyledInputProps, SectionProps } from './types';
import { FONTS } from '@suz/ui-utils/constants/Fonts';

const getSectionBorderColor = ({ active, error }: SectionProps) => {
  return active || error
    ? COLORS[active ? 'accent500' : 'error400']
    : COLORS.neutral75;
};

export const Section = styled.section<SectionProps>`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: ${SPACING.xs};
  box-sizing: border-box;
  min-height: 40px;
  width: 100%;
  min-width: 250px;
  padding: 7px ${SPACING.s};
  border: 1px solid ${getSectionBorderColor};
  border-radius: ${SHAPE.rounded};
  background-color: ${({ disable }) =>
    disable ? COLORS.neutral25 : COLORS.neutral0};
  pointer-events: ${({ disable }) => (disable ? 'none' : 'auto')};
  cursor: ${({ disable }) => (disable ? 'not-allowed' : 'pointer')};

  &:hover {
    border-color: ${({ active }) =>
    active ? COLORS.accent500 : COLORS.accent300};
  }
`;

const { body4strong } = FONTS;
export const StyledInput = styled.input<StyledInputProps>`
  display: block;
  width: 100%;
  box-sizing: border-box;
  border: none;
  background: none;
  outline: none;

  font-family: Inter, sans-serif;
  font-size: ${body4strong.fs};
  line-height: ${body4strong.lh};
  font-weight: ${body4strong.weight};
  color: ${COLORS.neutral900};
`;

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const ClearWrap = styled.div`
  cursor: pointer
`;
