/* eslint-disable max-len */
import React, { FC } from 'react';
import { IconProps } from '../types';

export const Placeholder: FC<IconProps> = ({
  size = '20',
  color = '#445371',
  transform,
  viewBox = '0 0 20 20',
  className,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    transform={transform}
    viewBox={viewBox}
    className={className}
    fill="none">
    <g clipPath="url(#clip0_1_1215)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0V20H20V0H0ZM17.1548 1.66667H2.84515L10 8.82151L17.1548 1.66667ZM18.3333 2.8452L11.1785 10L18.3333 17.1548V2.8452ZM8.82149 10L1.66667 2.8452V17.1548L8.82149 10ZM2.8452 18.3333L10 11.1785L17.1548 18.3333H2.8452Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_1_1215">
        <rect width={size} height={size} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
