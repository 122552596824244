import React, { FC } from 'react';
import { IText } from './types';
import { Span } from './Text.styled';
import { FONTS } from '@suz/ui-utils/constants/Fonts';

const Text: FC<IText> = ({
  type = 'display',
  color,
  children,
}) => {
  const { fs, lh, weight } = FONTS[type];
  return (
    <Span
      fs={fs}
      lh={lh}
      weight={weight}
      color={color}
    >
      {children}
    </Span>
  );
};

export default Text;
