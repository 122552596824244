/* eslint-disable max-len */
import React, { FC } from 'react';
import { IconProps } from '../types';

export const Exit: FC<IconProps> = ({
  size = '20',
  color = '#445371',
  transform,
  viewBox = '0 0 20 20',
  className,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    transform={transform}
    viewBox={viewBox}
    className={className}
    fill="none">
    <path
      d="M11.6666 2.5C12.5871 2.5 13.3333 3.24619 13.3333 4.16667V5.83333C13.3333 6.29357 12.9602 6.66667 12.4999 6.66667C12.0397 6.66667 11.6666 6.29357 11.6666 5.83333V4.16667L4.16659 4.16667V15.8333H11.6666V14.1667C11.6666 13.7064 12.0397 13.3333 12.4999 13.3333C12.9602 13.3333 13.3333 13.7064 13.3333 14.1667V15.8333C13.3333 16.7538 12.5871 17.5 11.6666 17.5H4.16659C3.24611 17.5 2.49992 16.7538 2.49992 15.8333V4.16667C2.49992 3.24619 3.24611 2.5 4.16659 2.5H11.6666Z"
      fill={color}
    />
    <path
      d="M14.756 6.91073C14.4306 6.5853 13.9029 6.5853 13.5775 6.91073C13.2521 7.23617 13.2521 7.76381 13.5775 8.08925L14.6549 9.16666L7.50008 9.16666C7.03984 9.16666 6.66675 9.53975 6.66675 9.99999C6.66675 10.4602 7.03984 10.8333 7.50008 10.8333H14.6549L13.5775 11.9107C13.2521 12.2362 13.2521 12.7638 13.5775 13.0892C13.9029 13.4147 14.4306 13.4147 14.756 13.0892L17.256 10.5892C17.4123 10.433 17.5001 10.221 17.5001 9.99999C17.5001 9.77898 17.4123 9.56701 17.256 9.41073L14.756 6.91073Z"
      fill={color}
    />
  </svg>
);
