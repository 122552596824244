import styled from 'styled-components';
import { BACKDROP } from '@suz/ui-utils/constants/defaultValues';
import { BackdropProps } from './types';

export const ModalWrap = styled.div`
  position: fixed;
  z-index: 1300;
  inset: 0px;
`;

export const Backdrop = styled.div<BackdropProps>`
  position: fixed;
  display: ${({ open }) => (open ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: ${({ invisible }) =>
    invisible ? BACKDROP.none : BACKDROP.fade};
  z-index: -1;
`;
