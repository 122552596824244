import styled from 'styled-components';
import { ITextStyles } from './types';
import { COLORS } from '@suz/ui-utils/constants/Colors';

export const Span = styled.span<ITextStyles>`
  display: inline-block;
  font-family: Inter;
  font-size: ${({ fs }) => fs};
  line-height: ${({ lh }) => lh};
  font-weight: ${({ weight }) => weight};
  color: ${({ color }) => color ? COLORS[color] : 'inherit'};
`;
