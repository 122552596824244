/* eslint-disable max-len */
import React, { FC } from 'react';
import { IconProps } from '../types';

export const ArrowSRight: FC<IconProps> = ({
  size = '16',
  color = '#69758E',
  transform,
  viewBox = '0 0 16 16',
  className,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    transform={transform}
    viewBox={viewBox}
    className={className}
    fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.2442 5.24409C6.56964 4.91865 7.09727 4.91865 7.42271 5.24409L9.92271 7.74409C10.2481 8.06952 10.2481 8.59716 9.92271 8.9226L7.42271 11.4226C7.09727 11.748 6.56964 11.748 6.2442 11.4226C5.91876 11.0972 5.91876 10.5695 6.2442 10.2441L8.15494 8.33334L6.2442 6.4226C5.91876 6.09716 5.91876 5.56952 6.2442 5.24409Z"
      fill={color}
    />
  </svg>
);
