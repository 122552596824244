import useControlledState from '@suz/ui-utils/hooks/useControlledState';
import { UseModalParams } from './types';

const resolveContainer = (
  container: Element | (() => Element | null) | null | undefined
): Element | null | undefined =>
  typeof container === 'function' ? container() : container;

const useModal = (params: UseModalParams) => {
  const { open, onClose, disableEscapeKeyDown, container } = params;

  const [mountNode] = useControlledState<Element | null>(
    resolveContainer(container) || document.body
  );

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key !== 'Escape' || event.which === 229) {
      return;
    }

    if (!disableEscapeKeyDown) {
      // Swallow the event, in case someone is listening for the escape key on the body.
      event.stopPropagation();

      onClose?.(event, 'escapeKeyDown');
    }
  };

  const handleBackdropClick = (event: React.MouseEvent) => {
    if (event.target !== event.currentTarget) {
      return;
    }

    onClose?.(event, 'backdropClick');
  };

  return {
    mountNode,
    rootProps: {
      onkeydown: handleKeyDown,
    },
    backdropProps: {
      open,
      onClick: handleBackdropClick,
    },
  };
};

export default useModal;
