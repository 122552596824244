/* eslint-disable max-len */
import React, { FC } from 'react';
import { IconProps } from '../types';

export const EyeM: FC<IconProps> = ({
  size = '20',
  color = '#445371',
  transform,
  viewBox = '0 0 20 20',
  className,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    transform={transform}
    viewBox={viewBox}
    className={className}
    fill="none">
    <path
      d="M18.2666 9.66667C16.5833 5.75833 13.4166 3.33333 9.99995 3.33333C6.58329 3.33333 3.41662 5.75833 1.73329 9.66667C1.6874 9.7718 1.66372 9.88528 1.66372 10C1.66372 10.1147 1.6874 10.2282 1.73329 10.3333C3.41662 14.2417 6.58329 16.6667 9.99995 16.6667C13.4166 16.6667 16.5833 14.2417 18.2666 10.3333C18.3125 10.2282 18.3362 10.1147 18.3362 10C18.3362 9.88528 18.3125 9.7718 18.2666 9.66667ZM9.99995 15C7.35829 15 4.85829 13.0917 3.41662 10C4.85829 6.90833 7.35829 5 9.99995 5C12.6416 5 15.1416 6.90833 16.5833 10C15.1416 13.0917 12.6416 15 9.99995 15ZM9.99995 6.66667C9.34068 6.66667 8.69622 6.86216 8.14805 7.22843C7.59989 7.59471 7.17265 8.1153 6.92035 8.72439C6.66806 9.33347 6.60205 10.0037 6.73067 10.6503C6.85929 11.2969 7.17675 11.8908 7.64293 12.357C8.1091 12.8232 8.70305 13.1407 9.34965 13.2693C9.99625 13.3979 10.6665 13.3319 11.2756 13.0796C11.8847 12.8273 12.4052 12.4001 12.7715 11.8519C13.1378 11.3037 13.3333 10.6593 13.3333 10C13.3333 9.11594 12.9821 8.2681 12.357 7.64298C11.7319 7.01785 10.884 6.66667 9.99995 6.66667ZM9.99995 11.6667C9.67032 11.6667 9.34808 11.5689 9.074 11.3858C8.79992 11.2026 8.5863 10.9423 8.46015 10.6378C8.33401 10.3333 8.301 9.99815 8.36531 9.67485C8.42962 9.35155 8.58835 9.05457 8.82144 8.82149C9.05453 8.5884 9.3515 8.42967 9.6748 8.36536C9.9981 8.30105 10.3332 8.33405 10.6378 8.4602C10.9423 8.58635 11.2026 8.79997 11.3857 9.07405C11.5689 9.34813 11.6666 9.67036 11.6666 10C11.6666 10.442 11.491 10.8659 11.1785 11.1785C10.8659 11.4911 10.442 11.6667 9.99995 11.6667Z"
      fill={color}
    />
  </svg>
);
