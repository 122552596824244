import { FC } from 'react';
import { useSelector } from 'react-redux';

import { getLoadingSelectedProjectStatus } from './ducks/Projects.selector';
import { getLoadingStatusMenu } from '../Menu/ducks/Menu.selector';

import Projects from './components/Projects/Projects';
import Search from './components/Search';
import { ProjectsMenuWrapper, HeaderProjectsWrapper } from './ProjectsMenu.styled';
import Loader from '../Loader';
import { ProjectsMenuProps } from './types';

const ProjectsMenu: FC<ProjectsMenuProps> = ({ project }) => {
  const isLoadingMenu = useSelector(getLoadingStatusMenu());
  const isLoadingSelectedProject = useSelector(getLoadingSelectedProjectStatus());

  return (
    <ProjectsMenuWrapper>
      <HeaderProjectsWrapper>
        <Loader
          isLoading={!!isLoadingSelectedProject || !project?.id}
          height={44}
          styles={{ margin: '8px 0' }}
        >
          <Projects activeProject={project} />
        </Loader>
      </HeaderProjectsWrapper>

      <Loader isLoading={isLoadingMenu} styles={{ margin: '8px 0' }}>
        <Search />
      </Loader>
    </ProjectsMenuWrapper>
  );
};

export default ProjectsMenu;
