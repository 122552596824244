/* eslint-disable max-len */
import React, { FC } from 'react';
import { IconProps } from '../types';

export const ArrowsUpDown: FC<IconProps> = ({
  size = '20',
  color = '#445371',
  transform,
  viewBox = '0 0 20 20',
  className,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    transform={transform}
    viewBox={viewBox}
    className={className}
    fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.07742 11.9107C6.40286 11.5853 6.9305 11.5853 7.25593 11.9107L10 14.6548L12.7441 11.9107C13.0695 11.5853 13.5972 11.5853 13.9226 11.9107C14.248 12.2362 14.248 12.7638 13.9226 13.0893L10.5893 16.4226C10.433 16.5789 10.221 16.6667 10 16.6667C9.779 16.6667 9.56703 16.5789 9.41075 16.4226L6.07742 13.0893C5.75198 12.7638 5.75198 12.2362 6.07742 11.9107Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.41075 3.57741C9.73619 3.25197 10.2638 3.25197 10.5893 3.57741L13.9226 6.91074C14.248 7.23618 14.248 7.76382 13.9226 8.08926C13.5972 8.41469 13.0695 8.41469 12.7441 8.08926L10 5.34518L7.25593 8.08926C6.9305 8.41469 6.40286 8.41469 6.07742 8.08926C5.75198 7.76382 5.75198 7.23618 6.07742 6.91074L9.41075 3.57741Z"
      fill={color}
    />
  </svg>
);
