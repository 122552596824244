import { FC, memo, useCallback } from "react";
import { useSelector } from "react-redux";
import {
  ToggleMenuButton,
  ToggleMenuTitle,
  MenuToggleIcon,
  MenuCounter,
} from "./Menu.styled";
import { useActionsMenu } from "./ducks/Menu.reducer";
import { getMenuUnfolded } from "./ducks/Menu.selector";
import { useActionsSidebar } from "../../ducks/Sidebar.reducer";
import { MenuToggleButtonProps } from "./types";

const MenuToggleButton: FC<MenuToggleButtonProps> = ({
  notificationsCount = 0,
}) => {
  const isUnfoldMenu = useSelector(getMenuUnfolded());
  const { openMainSidebarMenu, closeMainSidebarMenu } = useActionsMenu();
  const { toggleSubmenuTitleBottomLock } = useActionsSidebar();

  const handleToggle = useCallback(() => {
    if (isUnfoldMenu) {
      closeMainSidebarMenu();
      toggleSubmenuTitleBottomLock(false);
    } else {
      toggleSubmenuTitleBottomLock(true);
      openMainSidebarMenu();
    }
  }, [
    isUnfoldMenu,
    closeMainSidebarMenu,
    openMainSidebarMenu,
    toggleSubmenuTitleBottomLock,
  ]);

  const title = `${isUnfoldMenu ? "Показать меньше" : "Показать больше"}`;

  return (
    <ToggleMenuButton onClick={handleToggle}>
      <MenuToggleIcon isOpen={isUnfoldMenu} />
      <ToggleMenuTitle>{title}</ToggleMenuTitle>
      {!isUnfoldMenu && <MenuCounter count={notificationsCount} />}
    </ToggleMenuButton>
  );
};

export default memo(MenuToggleButton);
