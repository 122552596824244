export const ICONS = {
  s_down: 'e900',
  s_left: 'e901',
  s_right: 'e902',
  s_up: 'e903',
  m_cancel: 'e904',
  l_cancel: 'e905',
  link: 'e906',
  scheme: 'e907',
  participants: 'e908',
  in_circle: 'e909',
  one_person: 'e90a',
  staff: 'e90b',
  calendar: 'e90c',
  chart_bar_vertical: 'e90d',
  'feedback-new': 'e90e',
  chat: 'e90f',
  check_all: 'e910',
  check: 'e911',
  command: 'e913',
  communication: 'e914',
  drag_vertical: 'e915',
  edit: 'e916',
  exit: 'e917',
  eye: 'e918',
  closed_eye: 'e919',
  star_filled: 'e93a',
  favorite: 'e93b',
  'project-filters': 'e91a',
  filters_1: 'e91b',
  home: 'e91c',
  like: 'e91e',
  lock: 'e91f',
  download: 'e920',
  notifications: 'e921',
  pin: 'e924',
  placeholder: 'e925',
  plus_in_circle: 'e926',
  plus: 'e927',
  search: 'e928',
  settings: 'e929',
  sidebar: 'e92a',
  sort_ascending: 'e92b',
  archive: 'e92c',
  archive_1: 'e92d',
  blank: 'e92e',
  cloud_upload: 'e92f',
  draft: 'e930',
  drafts: 'e931',
  files: 'e932',
  news: 'e933',
  folder_closed: 'e934',
  folder_opened: 'e935',
  'file-folder': 'e936',
  reports: 'e937',
  documents: 'e938',
  statistic: 'e939',
  l_down: 'e93d',
  l_left: 'e93e',
  l_right: 'e93f',
  l_up: 'e940',
  m_down: 'e945',
  m_left: 'e946',
  m_right: 'e947',
  m_up: 'e948',
  unfold_more: 'e949',
  delete: 'e950',
} as const;
