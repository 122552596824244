import axios, { AxiosError, AxiosResponse, AxiosRequestConfig } from "axios";
import qs from "qs";
import axiosRetry from "axios-retry";
import delay from "lodash/delay";
import isString from "lodash/isString";
import get from "lodash/get";

const REQUEST_DELAY = 9000;

const IS_DEV = false;

const client = axios.create();

const catchReqErr = (err: AxiosError) => {
  const { status = 500 } = err;

  const isServerResolved = status >= 400 && status < 500;

  delay(() => {
    if (IS_DEV) {
      console.warn("Ошибка запроса", `Код ошибки: ${status}`);
    } else if (!isServerResolved) {
      console.error(
        "Ошибка соединения",
        "Не удалось установить соединение с сервером",
        {
          timeOut: 0,
        }
      );
    }
  }, 200);

  return Promise.reject(err);
};

client.defaults.headers["Content-Type"] = "application/json";

client.interceptors.response.use(
  (response) => {
    if (!response.data) {
      return response;
    }
    const {
      data: { code, message },
      config: { url, params, method, data = {} },
    } = response;

    const currData = isString(data) ? JSON.parse(data) : [];

    const query = method === "post" ? currData : params;

    if (code >= 400 && code < 600 && IS_DEV) {
      console.groupCollapsed(`%c Ошибка запроса`, "color:red; font-size:14px;");
      console.log(`%cКод ошибки: ${code}`, "font-size:12px;");
      console.log("%cТело запроса", "font-size:12px;", query);
      console.log(`%cПуть запроса: ${url}`, "font-size:12px;");
      console.log(`%cТекст ошибки: ${message}`, "font-size:12px;");
      console.error(`%cОтладка`, "color:black; font-size:12px;");
      console.groupEnd();
    }

    return response;
  },
  (err) => {
    const parsedErr = JSON.parse(JSON.stringify(err));
    const {
      message,
      config: { url, method, data = {}, params },
    } = parsedErr;
    const currData = isString(data) ? JSON.parse(data) : [];
    const query = method === "post" ? currData : params;

    if (IS_DEV) {
      console.groupCollapsed(`%c Ошибка запроса`, "color:red; font-size:14px;");
      console.log(`%cПуть запроса: ${url}`, "font-size:12px;");
      console.log("%cТело запроса", "font-size:12px;", query);
      console.log(`%cТекст ошибки: ${message}`, "font-size:12px;");
      console.error(`%cОтладка`, "color:black; font-size:12px;");
      console.groupEnd();
    }

    return Promise.reject(err);
  }
);

// axiosRetry(client, {
//   retries: 7,
//   retryDelay: (retryCount) => {
//     console.warn("Попытка соединения", "Соединение с сервером потеряно", {
//       timeOut: 0,
//     });

//     return retryCount * REQUEST_DELAY;
//   },
//   retryCondition: (err) => {
//     if (err && err.response) {
//       const {
//         response: { status },
//       } = err;

//       if (status >= 400 && status < 600 && IS_DEV) {
//         console.warn("Ошибка запроса", `Код ошибки: ${status}`);
//       }
//       if ((status >= 400 && status < 500) || [502, 504].includes(status)) {
//         return false;
//       }
//       return true;
//     }

//     return false;
//   },
// });

const apiClient = {
  get(path: string, params = {}, config: AxiosRequestConfig = {}) {
    const options: AxiosRequestConfig = {
      params,
      method: "GET",
      url: path,
      paramsSerializer(paramsSerialize) {
        return qs.stringify(paramsSerialize, { arrayFormat: "brackets" });
      },
      ...config,
    };
    return client(options)
      .then(({ data, status }) => {
        return { data, responseStatus: status };
      })
      .catch(catchReqErr);
  },
  post(path: string, data = {}, params = {}, paramsSerializer = {}) {
    const defOptions = {
      data,
      params,
      method: "POST",
      url: path,
    };

    const options: AxiosRequestConfig = defOptions;
    if (paramsSerializer) options.paramsSerializer = paramsSerializer;

    return client(options)
      .then(({ data, status }) => ({ ...data, status }))
      .catch(catchReqErr);
  },
  put(path: string, data = {}, params = {}) {
    const options: AxiosRequestConfig = {
      params,
      data,
      method: "PUT",
      url: path,
      paramsSerializer(paramsSerialize) {
        return qs.stringify(paramsSerialize, { arrayFormat: "brackets" });
      },
    };
    return client(options)
      .then(
        (response) =>
          response && {
            status: response.status,
            ...response.data,
            httpStatus: response.status,
          }
      )
      .catch(catchReqErr);
  },
  delete(path: string, params = {}, data = {}) {
    const options: AxiosRequestConfig = {
      method: "DELETE",
      url: path,
      params,
      data,
    };
    return client(options)
      .then((response) => response)
      .catch(catchReqErr);
  },
  patch(path: string, params = {}, data = {}) {
    const options: AxiosRequestConfig = {
      method: "PATCH",
      url: path,
      params,
      data,
    };
    return client(options)
      .then((response) => response)
      .catch(catchReqErr);
  },
};

export default apiClient;
