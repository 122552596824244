/* eslint-disable max-len */
import React, { FC } from 'react';
import { IconProps } from '../types';

export const ArrowMRight: FC<IconProps> = ({
  size = '20',
  color = '#445371',
  transform,
  viewBox = '0 0 20 20',
  className,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    transform={transform}
    viewBox={viewBox}
    className={className}
    fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.74408 6.07741C8.06951 5.75198 8.59715 5.75198 8.92259 6.07741L12.2559 9.41075C12.5814 9.73618 12.5814 10.2638 12.2559 10.5893L8.92259 13.9226C8.59715 14.248 8.06951 14.248 7.74408 13.9226C7.41864 13.5972 7.41864 13.0695 7.74408 12.7441L10.4882 10L7.74408 7.25592C7.41864 6.93049 7.41864 6.40285 7.74408 6.07741Z"
      fill={color}
    />
  </svg>
);
