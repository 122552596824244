import React, { forwardRef } from 'react';
import { createPortal } from 'react-dom';

import { PopperProps } from './types';

import usePopper from './usePopper';

const Popper = forwardRef<HTMLDivElement, PopperProps>((props) => {
  const {
    open,
    placement = 'bottom-start',
    container,
    anchorEl,
    children,
    styles
  } = props;
  const { isOpen, rootProps, anchor } = usePopper({
    open,
    placement,
    anchorEl,
    children,
    container,
  });
  return isOpen ? (
    <>
      {anchor
        ? createPortal(
          <div {...rootProps} style={{...rootProps.style, ...styles}}>
            {typeof children === 'function' ? children() : children}
          </div>,
          anchor
        )
        : anchor}
    </>
  ) : null;
});

Popper.displayName = 'Popper';

export default Popper;
