/* eslint-disable max-len */
import React, { FC } from 'react';
import { IconProps } from '../types';

export const Group: FC<IconProps> = ({
  size = '18',
  color = '#69758E',
  transform,
  viewBox = '0 0 18 18',
  className,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    transform={transform}
    viewBox={viewBox}
    className={className}
    fill="none">
    <path
      d="M1.5 1.5V14.8333C1.5 15.2754 1.67559 15.6993 1.98816 16.0118C2.30072 16.3244 2.72464 16.5 3.16667 16.5H16.5"
      stroke={color}
      strokeWidth="2"
      strokeMiterlimit="5.759"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.83333 10.6667L8.16667 7.33334L11.5 10.6667L16.5 5.66667"
      stroke={color}
      strokeWidth="2"
      strokeMiterlimit="5.759"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14 5.66667H16.5V8.16667"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
