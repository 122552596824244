import React from 'react';

import { ButtonBaseProps } from './types';

const ButtonBase = React.forwardRef<HTMLButtonElement, ButtonBaseProps>(
  (props, ref) => {
    const { children, ...other } = props;

    return (
      <button ref={ref} {...other}>
        {children}
      </button>
    );
  }
);

ButtonBase.displayName = 'ButtonBase';

export default ButtonBase;
