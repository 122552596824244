import { createAction } from 'redux-actions';

const prefix = '[News]';

export const fetchNewsList = createAction(`${prefix} fetchNewsList`);
export const mountNewsList = createAction(`${prefix} mountNewsList`);
export const fetchMoreNews = createAction(`${prefix} fetchMoreNews`);
export const mountMoreNews = createAction(`${prefix} mountMoreNews`);
export const fetchNews = createAction(`${prefix} fetchNews`);
export const mountNews = createAction(`${prefix} mountNews`);
export const markNewsAsRead = createAction(`${prefix} markNewsAsRead`);
export const markedNewsAsRead = createAction(`${prefix} markedNewsAsRead`);
export const abordNewsPreviewModal = createAction(`${prefix} abordNewsPreviewModal`);
export const abordNews = createAction(`${prefix} abordNews`);
export const catchNewsError = createAction(`${prefix} catchNewsError`);