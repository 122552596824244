/* eslint-disable max-len */
import React, { FC } from 'react';
import { IconProps } from '../types';

export const ArrowMLeft: FC<IconProps> = ({
  size = '20',
  color = '#445371',
  transform,
  viewBox = '0 0 20 20',
  className,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    transform={transform}
    viewBox={viewBox}
    className={className}
    fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.2559 6.07741C12.5814 6.40285 12.5814 6.93049 12.2559 7.25592L9.51184 10L12.2559 12.7441C12.5814 13.0695 12.5814 13.5972 12.2559 13.9226C11.9305 14.248 11.4028 14.248 11.0774 13.9226L7.74408 10.5893C7.41864 10.2638 7.41864 9.73618 7.74408 9.41075L11.0774 6.07741C11.4028 5.75198 11.9305 5.75198 12.2559 6.07741Z"
      fill={color}
    />
  </svg>
);
