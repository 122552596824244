import styled from 'styled-components';
import { COLORS } from '@suz/ui-utils/constants/Colors';
import { Size, Variant, StyledButtonProps, Colors } from './types';
import { Shape } from '@suz/ui-utils/types/general';

const FONT_SIZE: Record<Size, string> = {
  small: '12px',
  medium: '14px',
  large: '16px',
};

const LIGHT_FONT_COLOR: Record<Colors, string> = {
  accent: COLORS.neutral0,
  neutral: COLORS.neutral0
};
const DARK_FONT_COLOR: Record<Colors, string> = {
  accent: COLORS.accent100,
  neutral: COLORS.neutral900
};
const HOVER_FONT_COLOR: Record<Colors, string> = {
  accent: COLORS.accent100,
  neutral: COLORS.neutral200
};

const PADDING: Record<Size, string> = {
  small: '4px 8px',
  medium: '10px 12px',
  large: '10px 12px',
};

const SHAPE: Record<Shape, string> = {
  geometric: '0px',
  rounded: '8px',
  circular: '32px',
};

type ViewConfig = Record<Variant, Record<ConfigKeys, string>>;
type ConfigKeys =
  | 'color'
  | 'border'
  | 'backgroundColor'
  | 'hoverColor'
  | 'hoverBorder'
  | 'hoverBackgroundColor'
  | 'pressedColor'
  | 'pressedBorder'
  | 'pressedBackgroundColor';

export const getViewConfig: (color: Colors, disabled?: boolean, ) => ViewConfig = (color, disabled) => {
  return {
    primary: {
      color: disabled ? COLORS.accent100 : LIGHT_FONT_COLOR[color],
      border: '1px solid transparent',
      backgroundColor: disabled ? COLORS.accent25 : COLORS.accent500,
      hoverColor: disabled ? COLORS.accent100 : HOVER_FONT_COLOR[color],
      hoverBorder: '1px solid transparent',
      hoverBackgroundColor: disabled ? COLORS.accent25 : COLORS.accent600,
      pressedColor: disabled ? COLORS.accent100 : LIGHT_FONT_COLOR[color],
      pressedBorder: '1px solid transparent',
      pressedBackgroundColor: disabled ? COLORS.accent25 : COLORS.accent700,
    },
    secondary: {
      color: disabled ? COLORS.accent100 : DARK_FONT_COLOR[color],
      border: '1px solid transparent',
      backgroundColor: disabled ? COLORS.accent25 : COLORS.accent50,
      hoverColor: disabled ? COLORS.accent100 : HOVER_FONT_COLOR[color],
      hoverBorder: '1px solid transparent',
      hoverBackgroundColor: disabled ? COLORS.accent25 : COLORS.accent100,
      pressedColor: disabled ? COLORS.accent100 : DARK_FONT_COLOR[color],
      pressedBorder: '1px solid transparent',
      pressedBackgroundColor: disabled ? COLORS.accent25 : COLORS.accent200,
    },
    outline: {
      color: disabled ? COLORS.accent100 : DARK_FONT_COLOR[color],
      border: `1px solid ${COLORS.accent500}`,
      backgroundColor: disabled ? COLORS.neutral0 : COLORS.neutral0,
      hoverColor: disabled ? COLORS.accent100 : HOVER_FONT_COLOR[color],
      hoverBorder: `1px solid ${COLORS.accent500}`,
      hoverBackgroundColor: disabled ? COLORS.neutral0 : COLORS.accent50,
      pressedColor: disabled ? COLORS.accent100 : DARK_FONT_COLOR[color],
      pressedBorder: `1px solid ${COLORS.accent500}`,
      pressedBackgroundColor: disabled ? COLORS.neutral0 : COLORS.accent100,
    },
    link: {
      color: disabled ? COLORS.accent100 : DARK_FONT_COLOR[color],
      border: '1px solid transparent',
      backgroundColor: disabled ? COLORS.neutral0 : COLORS.neutral0,
      hoverColor: disabled ? COLORS.accent100 : HOVER_FONT_COLOR[color],
      hoverBorder: '1px solid transparent',
      hoverBackgroundColor: disabled ? COLORS.neutral0 : COLORS.accent50,
      pressedColor: disabled ? COLORS.accent100 : DARK_FONT_COLOR[color],
      pressedBorder: '1px solid transparent',
      pressedBackgroundColor: disabled ? COLORS.neutral0 : COLORS.accent75,
    },
  };
};

export const StyledButton = styled.div<StyledButtonProps>`
  background-color: ${({ variant = 'primary', disabled, color = 'accent' }) =>
    getViewConfig(color, disabled)[variant].backgroundColor};
  color: ${({ variant = 'primary', disabled, color = 'accent' }) =>
    getViewConfig(color, disabled)[variant].color};
  outline: none;
  border: ${({ variant = 'primary', disabled, color = 'accent' }) =>
    getViewConfig(color, disabled)[variant].border};
  text-decoration: none;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${({ disabled }) => `${disabled ? 'not-allowed' : 'pointer'}`};
  max-width: 100%;
  white-space: nowrap;
  border-radius: ${({ shape = 'rounded' }) => SHAPE[shape]};
  gap: 6px;
  padding: ${({ size = 'medium' }) => PADDING[size]};
  font-size: ${({ size = 'medium' }) => FONT_SIZE[size]};
  font-weight: 500;
  line-height: 1.4em;

  &:hover {
    color: ${({ variant = 'primary', disabled, color = 'accent' }) =>
    getViewConfig(color, disabled)[variant].hoverColor};
    border: ${({ variant = 'primary', disabled, color = 'accent' }) =>
    getViewConfig(color, disabled)[variant].hoverBorder};
    background-color: ${({ variant = 'primary', disabled, color = 'accent' }) =>
    getViewConfig(color, disabled)[variant].hoverBackgroundColor};
  }

  &:active {
    color: ${({ variant = 'primary', disabled, color = 'accent' }) =>
    getViewConfig(color, disabled)[variant].pressedColor};
    border: ${({ variant = 'primary', disabled, color = 'accent' }) =>
    getViewConfig(color, disabled)[variant].pressedBorder};
    background-color: ${({ variant = 'primary', disabled, color = 'accent' }) =>
    getViewConfig(color, disabled)[variant].pressedBackgroundColor};
  }
`;

export const ButtonText = styled.span``;
