import { call, put, all, takeEvery } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";

import { actionsLogin, LdapCallbacks } from "./Login.reducer";

const LDAP_URL = "/ldap";

function* LdapSaga({ type, payload }: PayloadAction<LdapCallbacks>) {
  yield put(actionsLogin.startLoading(type));

  const { success, error } = payload;
  try {
    // @ts-ignore
    const response = yield call(fetch, LDAP_URL);
    if (response.status === 200) {
      success();
    } else {
      error();
    }
  } catch (e) {
    error();
    console.error("Ошибка при попытке аутентификации", e);
  } finally {
    yield put(actionsLogin.finishLoading(type));
  }
}

export default function* fetchLdap() {
  yield takeEvery([actionsLogin.ldap], LdapSaga);
}
