import React, { FC } from "react";
import { IIconButton } from "./types";
import { ButtonWrap } from "./IconButton.styled";
import Icon from "../Icon";

const IconButton: FC<IIconButton> = ({
  icon = "ArrowMDown",
  isAccent,
  ...props
}) => {
  return (
    <ButtonWrap {...props} isAccent={isAccent}>
      <Icon icon={icon} />
    </ButtonWrap>
  );
};

export default IconButton;
