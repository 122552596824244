/* eslint-disable max-len */
import React, { FC } from 'react';
import { IconProps } from '../types';

export const PersonTwo: FC<IconProps> = ({
  size = '20',
  color = '#445371',
  transform,
  viewBox = '0 0 20 20',
  className,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    transform={transform}
    viewBox={viewBox}
    className={className}
    fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.3556 13.3319C13.4611 12.8839 13.9098 12.6063 14.3578 12.7119C16.4443 13.2035 18.3334 14.6328 18.3334 16.6667C18.3334 17.1269 17.9603 17.5 17.5 17.5C17.0398 17.5 16.6667 17.1269 16.6667 16.6667C16.6667 15.7978 15.773 14.7577 13.9756 14.3341C13.5276 14.2286 13.25 13.7798 13.3556 13.3319Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.42674 15.003C3.6709 15.5069 3.33335 16.1115 3.33335 16.6667C3.33335 17.1269 2.96026 17.5 2.50002 17.5C2.03978 17.5 1.66669 17.1269 1.66669 16.6667C1.66669 15.3809 2.44843 14.3188 3.50224 13.6163C4.56187 12.9098 5.97773 12.5 7.50002 12.5C9.02231 12.5 10.4382 12.9098 11.4978 13.6163C12.5516 14.3188 13.3334 15.3809 13.3334 16.6667C13.3334 17.1269 12.9603 17.5 12.5 17.5C12.0398 17.5 11.6667 17.1269 11.6667 16.6667C11.6667 16.1115 11.3291 15.5069 10.5733 15.003C9.82329 14.503 8.73916 14.1667 7.50002 14.1667C6.26088 14.1667 5.17675 14.503 4.42674 15.003Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.6667 4.16667C11.6667 3.70643 12.0398 3.33334 12.5 3.33334C14.8012 3.33334 16.6667 5.19882 16.6667 7.5C16.6667 9.80119 14.8012 11.6667 12.5 11.6667C12.0398 11.6667 11.6667 11.2936 11.6667 10.8333C11.6667 10.3731 12.0398 10 12.5 10C13.8807 10 15 8.88071 15 7.5C15 6.11929 13.8807 5 12.5 5C12.0398 5 11.6667 4.62691 11.6667 4.16667Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.50002 5C6.11931 5 5.00002 6.11929 5.00002 7.5C5.00002 8.88071 6.11931 10 7.50002 10C8.88073 10 10 8.88071 10 7.5C10 6.11929 8.88073 5 7.50002 5ZM3.33335 7.5C3.33335 5.19882 5.19883 3.33334 7.50002 3.33334C9.80121 3.33334 11.6667 5.19882 11.6667 7.5C11.6667 9.80119 9.80121 11.6667 7.50002 11.6667C5.19883 11.6667 3.33335 9.80119 3.33335 7.5Z"
      fill={color}
    />
  </svg>
);
