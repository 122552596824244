/* eslint-disable max-len */
import React, { FC } from 'react';
import { IconProps } from '../types';

export const ArrowSUp: FC<IconProps> = ({
  size = '16',
  color = '#69758E',
  transform,
  viewBox = '0 0 16 16',
  className,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    transform={transform}
    viewBox={viewBox}
    className={className}
    fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.74396 6.24408C8.06939 5.91864 8.59703 5.91864 8.92247 6.24408L11.4225 8.74408C11.7479 9.06951 11.7479 9.59715 11.4225 9.92259C11.097 10.248 10.5694 10.248 10.244 9.92259L8.33321 8.01184L6.42247 9.92259C6.09703 10.248 5.56939 10.248 5.24396 9.92259C4.91852 9.59715 4.91852 9.06951 5.24396 8.74408L7.74396 6.24408Z"
      fill={color}
    />
  </svg>
);
