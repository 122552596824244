/* eslint-disable max-len */
import React, { FC } from 'react';
import { IconProps } from '../types';

export const EyeS: FC<IconProps> = ({
  size = '16',
  color = '#69758E',
  transform,
  viewBox = '0 0 16 16',
  className,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    transform={transform}
    viewBox={viewBox}
    className={className}
    fill="none">
    <path
      d="M14.6134 7.73317C13.2667 4.6065 10.7334 2.6665 8.00004 2.6665C5.26671 2.6665 2.73338 4.6065 1.38671 7.73317C1.35 7.81728 1.33105 7.90807 1.33105 7.99984C1.33105 8.09161 1.35 8.18239 1.38671 8.2665C2.73338 11.3932 5.26671 13.3332 8.00004 13.3332C10.7334 13.3332 13.2667 11.3932 14.6134 8.2665C14.6501 8.18239 14.669 8.09161 14.669 7.99984C14.669 7.90807 14.6501 7.81728 14.6134 7.73317ZM8.00004 11.9998C5.88671 11.9998 3.88671 10.4732 2.73338 7.99984C3.88671 5.5265 5.88671 3.99984 8.00004 3.99984C10.1134 3.99984 12.1134 5.5265 13.2667 7.99984C12.1134 10.4732 10.1134 11.9998 8.00004 11.9998ZM8.00004 5.33317C7.47263 5.33317 6.95706 5.48957 6.51852 5.78259C6.07999 6.0756 5.7382 6.49208 5.53637 6.97935C5.33453 7.46662 5.28172 8.0028 5.38462 8.52008C5.48751 9.03736 5.74149 9.51252 6.11443 9.88546C6.48737 10.2584 6.96252 10.5124 7.4798 10.6153C7.99709 10.7182 8.53326 10.6653 9.02053 10.4635C9.5078 10.2617 9.92428 9.91989 10.2173 9.48136C10.5103 9.04283 10.6667 8.52725 10.6667 7.99984C10.6667 7.29259 10.3858 6.61432 9.88566 6.11422C9.38556 5.61412 8.70729 5.33317 8.00004 5.33317ZM8.00004 9.33317C7.73634 9.33317 7.47855 9.25497 7.25928 9.10846C7.04002 8.96196 6.86912 8.75372 6.7682 8.51008C6.66729 8.26645 6.64088 7.99836 6.69233 7.73972C6.74378 7.48108 6.87077 7.2435 7.05724 7.05703C7.24371 6.87056 7.48128 6.74357 7.73992 6.69212C7.99857 6.64068 8.26665 6.66708 8.51029 6.768C8.75392 6.86891 8.96216 7.03981 9.10867 7.25908C9.25518 7.47834 9.33338 7.73613 9.33338 7.99984C9.33338 8.35346 9.1929 8.6926 8.94285 8.94265C8.6928 9.19269 8.35367 9.33317 8.00004 9.33317Z"
      fill={color}
    />
  </svg>
);
