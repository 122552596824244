/* eslint-disable max-len */
import React, { FC } from 'react';
import { IconProps } from '../types';

export const ArrowSDown: FC<IconProps> = ({
  size = '16',
  color = '#69758E',
  transform,
  viewBox = '0 0 16 16',
  className,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    transform={transform}
    viewBox={viewBox}
    className={className}
    fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.24396 6.24407C5.56939 5.91863 6.09703 5.91863 6.42247 6.24407L8.33321 8.15481L10.244 6.24407C10.5694 5.91863 11.097 5.91863 11.4225 6.24407C11.7479 6.56951 11.7479 7.09714 11.4225 7.42258L8.92247 9.92258C8.76619 10.0789 8.55423 10.1667 8.33321 10.1667C8.1122 10.1667 7.90024 10.0789 7.74396 9.92258L5.24396 7.42258C4.91852 7.09714 4.91852 6.56951 5.24396 6.24407Z"
      fill={color}
    />
  </svg>
);
