/* eslint-disable max-len */
import React, { FC } from 'react';
import { IconProps } from '../types';

export const Sheet: FC<IconProps> = ({
  size = '20',
  color = '#445371',
  transform,
  viewBox = '0 0 20 20',
  className,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    transform={transform}
    viewBox={viewBox}
    className={className}
    fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 1.7L11.3026 1.7C11.7576 1.7 12.1919 1.88978 12.501 2.22363L16.1985 6.21686C16.478 6.51877 16.6333 6.91509 16.6333 7.32656L16.6333 16.6667C16.6333 17.5687 15.9021 18.3 15 18.3H5C4.09794 18.3 3.36667 17.5687 3.36667 16.6667L3.36667 3.33333C3.36667 2.43127 4.09794 1.7 5 1.7ZM11.3026 3.3L5 3.3C4.98159 3.3 4.96667 3.31492 4.96667 3.33333L4.96667 16.6667C4.96667 16.6851 4.98159 16.7 5 16.7H15C15.0184 16.7 15.0333 16.6851 15.0333 16.6667L15.0333 7.32656C15.0333 7.31816 15.0302 7.31007 15.0245 7.30391L11.327 3.31069C11.3207 3.30387 11.3119 3.3 11.3026 3.3Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.7 14.1667C6.7 13.7248 7.05818 13.3667 7.5 13.3667H12.5C12.9418 13.3667 13.3 13.7248 13.3 14.1667C13.3 14.6085 12.9418 14.9667 12.5 14.9667H7.5C7.05818 14.9667 6.7 14.6085 6.7 14.1667Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.7 11.6667C6.7 11.2248 7.05818 10.8667 7.5 10.8667H12.5C12.9418 10.8667 13.3 11.2248 13.3 11.6667C13.3 12.1085 12.9418 12.4667 12.5 12.4667H7.5C7.05818 12.4667 6.7 12.1085 6.7 11.6667Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.8333 1.7C11.2752 1.7 11.6333 2.05817 11.6333 2.5L11.6333 6.66666C11.6333 6.68507 11.6483 6.7 11.6667 6.7L15.8333 6.7C16.2752 6.7 16.6333 7.05817 16.6333 7.5C16.6333 7.94182 16.2752 8.3 15.8333 8.3L11.6667 8.3C10.7646 8.3 10.0333 7.56873 10.0333 6.66666V2.5C10.0333 2.05817 10.3915 1.7 10.8333 1.7Z"
      fill={color}
    />
  </svg>
);
