/* eslint-disable max-len */
import React, { FC } from 'react';
import { IconProps } from '../types';

export const Draft: FC<IconProps> = ({
  size = '20',
  color = '#445371',
  transform,
  viewBox = '0 0 20 20',
  className,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    transform={transform}
    viewBox={viewBox}
    className={className}
    fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.83333 1.66666L12.1359 1.66666C12.6002 1.66666 13.0434 1.86032 13.3588 2.20098L17.0563 6.19421C17.3415 6.50229 17.5 6.90669 17.5 7.32655V16.6667C17.5 17.5871 16.7538 18.3333 15.8333 18.3333H10.8333C10.3731 18.3333 10 17.9602 10 17.5C10 17.0398 10.3731 16.6667 10.8333 16.6667H15.8333L15.8333 7.32655L12.1359 3.33333L5.83333 3.33333L5.83333 9.16666C5.83333 9.6269 5.46024 10 5 10C4.53976 10 4.16667 9.6269 4.16667 9.16666L4.16667 3.33333C4.16667 2.41286 4.91286 1.66666 5.83333 1.66666Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.99408 8.57741C9.31951 8.25197 9.84715 8.25197 10.1726 8.57741L12.2559 10.6607C12.5814 10.9862 12.5814 11.5138 12.2559 11.8393L6.00592 18.0893C5.84964 18.2455 5.63768 18.3333 5.41667 18.3333H3.33333C2.8731 18.3333 2.5 17.9602 2.5 17.5V15.4167C2.5 15.1957 2.5878 14.9837 2.74408 14.8274L8.99408 8.57741ZM4.16667 15.7618V16.6667H5.07149L10.4882 11.25L9.58333 10.3452L4.16667 15.7618Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.91074 11.0774C7.23618 10.752 7.76382 10.752 8.08926 11.0774L9.75592 12.7441C10.0814 13.0695 10.0814 13.5972 9.75592 13.9226C9.43049 14.248 8.90285 14.248 8.57741 13.9226L6.91074 12.2559C6.58531 11.9305 6.58531 11.4028 6.91074 11.0774Z"
      fill={color}
    />
  </svg>
);
