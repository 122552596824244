/* eslint-disable max-len */
import React, { FC } from 'react';
import { IconProps } from '../types';

export const ArrowLRight: FC<IconProps> = ({
  size = '20',
  color = '#445371',
  transform,
  viewBox = '0 0 20 20',
  className,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    transform={transform}
    viewBox={viewBox}
    className={className}
    fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.91073 3.57741C7.23617 3.25198 7.76381 3.25198 8.08925 3.57741L13.9226 9.41075C14.248 9.73618 14.248 10.2638 13.9226 10.5893L8.08925 16.4226C7.76381 16.748 7.23617 16.748 6.91073 16.4226C6.5853 16.0972 6.5853 15.5695 6.91073 15.2441L12.1548 10L6.91073 4.75592C6.5853 4.43049 6.5853 3.90285 6.91073 3.57741Z"
      fill={color}
    />
  </svg>
);
