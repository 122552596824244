import styled, { css } from "styled-components";

import ReactCustomScrollbar from "@Features/ScrollTemplate";
import Icon, { StyledIconProps } from "@suz/ui-kit/Icon";
import {
  ChevroneIconProps,
  SideBarWrapperProps,
  ToggleCategoryButtonProps,
} from "./types";

export const SidebarWrapper = styled.div<SideBarWrapperProps>`
  display: flex;
  position: relative;
  flex-direction: column;
  height: 100%;
  background-color: #f9fafb;
  font-family: Inter, sans-serif;
  font-weight: 400;
  transition: all 0.3s ease-out;
  ${({ sidebarShow }) =>
    sidebarShow
      ? css`
          width: 276px;
          opacity: 1;
        `
      : css`
          width: 0px;
          opacity: 0;
        `}
`;

export const MenusWrapper = styled.div`
  height: 100%;
  overflow: hidden;
`;

export const SidebarScroll = styled(ReactCustomScrollbar)`
  .scrollbar {
    &__track.vertical {
      height: 100%;
      right: 0;
      margin: 0 7px;
    }

    &__thumb.vertical {
      background-color: #858fa380;
      border-radius: 16px;

      &:hover {
        background-color: #4453717f;
      }
    }

    &__content {
      padding: 0;
    }
  }
`;

export const ClearButton = styled.button`
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 0;
  text-align: inherit;
  font: inherit;
  border-radius: 0;
  appearance: none;
  cursor: pointer;
  height: 20px;
`;

export const EditButton = styled(ClearButton)`
  display: none;
`;

export const ToggleCategoryButton = styled(
  ClearButton
)<ToggleCategoryButtonProps>`
  position: absolute;
  left: ${({ lvl }) => `${8 * lvl}px`};
  display: flex;
  align-items: center;
  height: 20px;
`;

export const ChevroneIcon = styled(Icon).attrs<StyledIconProps>({
  icon: "ArrowSRight",
  size: "16",
  color: "#858fa3",
})<ChevroneIconProps>`
  transition: rotate 0.3s linear;
  rotate: ${({ isOpenMenu }) => (isOpenMenu ? "90deg" : "0")};
  min-width: 16px;
`;
