/* eslint-disable max-len */
import React, { FC } from 'react';
import { IconProps } from '../types';

export const Drafts: FC<IconProps> = ({
  size = '20',
  color = '#445371',
  transform,
  viewBox = '0 0 20 20',
  className,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    transform={transform}
    viewBox={viewBox}
    className={className}
    fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.5 4.16667C2.5 3.24619 3.24619 2.5 4.16667 2.5H8.33333C8.79357 2.5 9.16667 2.8731 9.16667 3.33334C9.16667 3.79357 8.79357 4.16667 8.33333 4.16667H4.16667V15.8333H15.8333V11.6667C15.8333 11.2064 16.2064 10.8333 16.6667 10.8333C17.1269 10.8333 17.5 11.2064 17.5 11.6667V15.8333C17.5 16.7538 16.7538 17.5 15.8333 17.5H4.16667C3.24619 17.5 2.5 16.7538 2.5 15.8333V4.16667Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.2441 1.07741C15.5695 0.751977 16.0972 0.751977 16.4226 1.07741L18.9226 3.57741C19.248 3.90285 19.248 4.43049 18.9226 4.75592L11.4226 12.2559C11.2663 12.4122 11.0543 12.5 10.8333 12.5H8.33333C7.8731 12.5 7.5 12.1269 7.5 11.6667V9.16667C7.5 8.94565 7.5878 8.73369 7.74408 8.57741L15.2441 1.07741ZM9.16667 9.51185V10.8333H10.4882L17.1548 4.16667L15.8333 2.84518L9.16667 9.51185Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.7441 3.57741C13.0695 3.25198 13.5972 3.25198 13.9226 3.57741L16.4226 6.07741C16.748 6.40285 16.748 6.93049 16.4226 7.25592C16.0972 7.58136 15.5695 7.58136 15.2441 7.25592L12.7441 4.75592C12.4186 4.43049 12.4186 3.90285 12.7441 3.57741Z"
      fill={color}
    />
  </svg>
);
