/* eslint-disable max-len */
import React, { FC } from 'react';
import { IconProps } from '../types';

export const PersonThree: FC<IconProps> = ({
  size = '20',
  color = '#445371',
  transform,
  viewBox = '0 0 20 20',
  className,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    transform={transform}
    viewBox={viewBox}
    className={className}
    fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.48249 15.6135C6.86199 15.9858 6.66669 16.384 6.66669 16.6667C6.66669 17.1269 6.29359 17.5 5.83335 17.5C5.37312 17.5 5.00002 17.1269 5.00002 16.6667C5.00002 15.5686 5.73746 14.7168 6.625 14.1843C7.52912 13.6419 8.72436 13.3333 10 13.3333C11.2757 13.3333 12.4709 13.6419 13.375 14.1843C14.2626 14.7168 15 15.5686 15 16.6667C15 17.1269 14.6269 17.5 14.1667 17.5C13.7064 17.5 13.3334 17.1269 13.3334 16.6667C13.3334 16.384 13.1381 15.9858 12.5176 15.6135C11.9136 15.2511 11.0255 15 10 15C8.97449 15 8.0864 15.2511 7.48249 15.6135Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.1939 11.6633C14.3106 11.2181 14.7661 10.9518 15.2113 11.0685C16.0434 11.2866 16.7971 11.6558 17.3591 12.1612C17.9233 12.6686 18.3334 13.3552 18.3334 14.1666C18.3334 14.6268 17.9603 14.9999 17.5 14.9999C17.0398 14.9999 16.6667 14.6268 16.6667 14.1666C16.6667 13.9528 16.5625 13.6863 16.2446 13.4005C15.9245 13.1126 15.4283 12.8484 14.7887 12.6807C14.3435 12.564 14.0772 12.1085 14.1939 11.6633Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.80612 11.6633C5.92282 12.1085 5.65653 12.564 5.21134 12.6807C4.57178 12.8484 4.07552 13.1126 3.7554 13.4005C3.43754 13.6863 3.33335 13.9528 3.33335 14.1666C3.33335 14.6268 2.96026 14.9999 2.50002 14.9999C2.03978 14.9999 1.66669 14.6268 1.66669 14.1666C1.66669 13.3552 2.0767 12.6686 2.64095 12.1612C3.20294 11.6558 3.95668 11.2866 4.7887 11.0685C5.2339 10.9518 5.68941 11.2181 5.80612 11.6633Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 7.5C9.07955 7.5 8.33335 8.24619 8.33335 9.16667C8.33335 10.0871 9.07955 10.8333 10 10.8333C10.9205 10.8333 11.6667 10.0871 11.6667 9.16667C11.6667 8.24619 10.9205 7.5 10 7.5ZM6.66669 9.16667C6.66669 7.32572 8.15907 5.83334 10 5.83334C11.841 5.83334 13.3334 7.32572 13.3334 9.16667C13.3334 11.0076 11.841 12.5 10 12.5C8.15907 12.5 6.66669 11.0076 6.66669 9.16667Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.3334 5C12.906 5 12.5179 5.15979 12.2224 5.42419C11.8795 5.73113 11.3527 5.70195 11.0457 5.35901C10.7388 5.01607 10.768 4.48924 11.1109 4.1823C11.7001 3.65497 12.4802 3.33334 13.3334 3.33334C15.1743 3.33334 16.6667 4.82572 16.6667 6.66667C16.6667 7.65397 16.2364 8.54187 15.5558 9.15104C15.2128 9.45798 14.686 9.42879 14.3791 9.08585C14.0721 8.74291 14.1013 8.21608 14.4443 7.90915C14.7866 7.60278 15 7.1602 15 6.66667C15 5.74619 14.2538 5 13.3334 5Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.33335 6.66667C3.33335 4.82572 4.82574 3.33334 6.66669 3.33334C7.51988 3.33334 8.29994 3.65497 8.88911 4.1823C9.23205 4.48924 9.26124 5.01607 8.9543 5.35901C8.64736 5.70195 8.12053 5.73113 7.77759 5.42419C7.48217 5.15979 7.09408 5 6.66669 5C5.74621 5 5.00002 5.74619 5.00002 6.66667C5.00002 7.1602 5.21348 7.60278 5.55578 7.90915C5.89872 8.21608 5.9279 8.74291 5.62097 9.08585C5.31403 9.42879 4.7872 9.45798 4.44426 9.15104C3.76365 8.54187 3.33335 7.65397 3.33335 6.66667Z"
      fill={color}
    />
  </svg>
);
