import { call, put, all, takeLatest } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";

import apiClient from "@Helpers/apiClient";
import { actionsLogin, ResetData } from "./Login.reducer";

const LOGIN_URL = "/v2/restore";

function* resetSaga({ type, payload }: PayloadAction<ResetData>) {
  yield put(actionsLogin.startLoading(type));
  const { login, onSuccess } = payload;
  try {
    // @ts-ignore
    const response = yield call(
      apiClient.post,
      LOGIN_URL,
      {},
      { user_login: login }
    );
    if (response.status === 200 && response.code === 200) {
      onSuccess(response.message);
    } else {
      yield put(actionsLogin.setError(true));
    }
  } catch (e) {
    yield put(actionsLogin.setError(true));
    console.error("Ошибка при попытке восстановления пароля", e);
  } finally {
    yield put(actionsLogin.finishLoading(type));
  }
}

export default function* fetchLogin() {
  yield takeLatest(actionsLogin.resetPassword, resetSaga);
}
