/* eslint-disable max-len */
import React, { FC } from 'react';
import { IconProps } from '../types';

export const LinkS: FC<IconProps> = ({
  size = '16',
  color = '#445371',
  transform,
  viewBox = '0 0 16 16',
  className,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    transform={transform}
    viewBox={viewBox}
    className={className}
    fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.3572 5.64318C10.6176 5.90353 10.6176 6.32564 10.3572 6.58599L6.58599 10.3572C6.32564 10.6176 5.90353 10.6176 5.64318 10.3572C5.38283 10.0969 5.38283 9.67476 5.64318 9.41441L9.41441 5.64318C9.67476 5.38283 10.0969 5.38283 10.3572 5.64318Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.1715 7.05724C5.43185 7.31759 5.43185 7.7397 5.1715 8.00005L4.22869 8.94286C3.44764 9.72391 3.44764 10.9902 4.22869 11.7713C5.00974 12.5523 6.27607 12.5523 7.05712 11.7713L7.99993 10.8285C8.26028 10.5681 8.68239 10.5681 8.94274 10.8285C9.20308 11.0888 9.20308 11.5109 8.94274 11.7713L7.99993 12.7141C6.69818 14.0158 4.58763 14.0158 3.28588 12.7141C1.98413 11.4123 1.98413 9.3018 3.28588 8.00005L4.22869 7.05724C4.48904 6.79689 4.91115 6.79689 5.1715 7.05724Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.7713 4.22869C10.9902 3.44764 9.72391 3.44764 8.94286 4.22869L8.00005 5.1715C7.7397 5.43185 7.31759 5.43185 7.05724 5.1715C6.79689 4.91115 6.79689 4.48904 7.05724 4.22869L8.00005 3.28588C9.3018 1.98413 11.4123 1.98413 12.7141 3.28588C14.0158 4.58763 14.0158 6.69818 12.7141 7.99993L11.7713 8.94274C11.5109 9.20308 11.0888 9.20308 10.8285 8.94274C10.5681 8.68239 10.5681 8.26028 10.8285 7.99993L11.7713 7.05712C12.5523 6.27607 12.5523 5.00974 11.7713 4.22869Z"
      fill={color}
    />
  </svg>
);
