import { all } from "redux-saga/effects";

import fetchProjectsSaga from "./FetchProjects.saga";
import changeOrderProjectsSaga from "./ChangeOrderProjects.saga";
import selectProjectSaga from "./SelectedProject.saga";

export default function* projectsSaga() {
  yield all([
    fetchProjectsSaga(),
    changeOrderProjectsSaga(),
    selectProjectSaga(),
  ]);
}
