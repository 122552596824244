/* eslint-disable max-len */
import React, { FC } from 'react';
import { IconProps } from '../types';

export const CheckAll: FC<IconProps> = ({
  size = '20',
  color = '#445371',
  transform,
  viewBox = '0 0 20 20',
  className,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    transform={transform}
    viewBox={viewBox}
    className={className}
    fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.8625 6.27967C18.188 6.60511 18.188 7.13274 17.8625 7.45818L10.7915 14.5292C10.466 14.8547 9.93838 14.8547 9.61294 14.5292L6.07741 10.9937C5.75197 10.6683 5.75197 10.1406 6.07741 9.8152C6.40285 9.48977 6.93049 9.48977 7.25592 9.8152L10.2022 12.7615L16.684 6.27967C17.0094 5.95423 17.5371 5.95423 17.8625 6.27967Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.6932 6.277C14.0201 6.60095 14.0225 7.12858 13.6985 7.45549L11.0086 10.17C10.6846 10.4969 10.157 10.4993 9.8301 10.1754C9.50318 9.85141 9.50078 9.32378 9.82474 8.99686L12.5147 6.28235C12.8386 5.95544 13.3663 5.95304 13.6932 6.277ZM1.91074 9.8152C2.23618 9.48977 2.76382 9.48977 3.08926 9.8152L6.62479 13.3507C6.95023 13.6762 6.95023 14.2038 6.62479 14.5292C6.29935 14.8547 5.77172 14.8547 5.44628 14.5292L1.91074 10.9937C1.58531 10.6683 1.58531 10.1406 1.91074 9.8152Z"
      fill={color}
    />
  </svg>
);
