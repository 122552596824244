/* eslint-disable max-len */
import React, { FC } from 'react';
import { IconProps } from '../types';

export const LinkM: FC<IconProps> = ({
  size = '20',
  color = '#69758E',
  transform,
  viewBox = '0 0 20 20',
  className,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    transform={transform}
    viewBox={viewBox}
    className={className}
    fill="none">
    <path
      d="M7.5 12.5L12.5 7.50001M9.16667 5.00001L9.5525 4.55334C10.334 3.77194 11.3939 3.33301 12.499 3.33308C13.6042 3.33316 14.664 3.77225 15.4454 4.55376C16.2268 5.33526 16.6657 6.39516 16.6657 7.5003C16.6656 8.60544 16.2265 9.66528 15.445 10.4467L15 10.8333M10.8333 15L10.5025 15.445C9.71187 16.2268 8.64482 16.6653 7.53292 16.6653C6.42101 16.6653 5.35396 16.2268 4.56333 15.445C4.17363 15.0597 3.86424 14.6009 3.65309 14.0951C3.44194 13.5894 3.33321 13.0468 3.33321 12.4988C3.33321 11.9507 3.44194 11.4081 3.65309 10.9024C3.86424 10.3967 4.17363 9.93784 4.56333 9.55251L5 9.16667"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
