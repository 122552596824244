import { combineReducers } from 'redux';

import NewsReducers from '../components/News/ducks/News.reducer';
import UpdatesReducers from '../components/Updates/ducks/Updates.reducer';

const MainReducers = combineReducers({
    news: NewsReducers,
    updates: UpdatesReducers,
})

export default MainReducers;