/* eslint-disable max-len */
import React, { FC } from 'react';
import { IconProps } from '../types';

export const Lock: FC<IconProps> = ({
  size = '20',
  color = '#445371',
  transform,
  viewBox = '0 0 20 20',
  className,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    transform={transform}
    viewBox={viewBox}
    className={className}
    fill="none">
    <path
      d="M14.1667 7.5V5.83333C14.1667 4.72827 13.7277 3.66846 12.9463 2.88706C12.1649 2.10565 11.1051 1.66667 10 1.66667C8.89493 1.66667 7.83512 2.10565 7.05372 2.88706C6.27232 3.66846 5.83333 4.72827 5.83333 5.83333V7.5C5.17029 7.5 4.53441 7.76339 4.06557 8.23223C3.59673 8.70107 3.33333 9.33696 3.33333 10V15.8333C3.33333 16.4964 3.59673 17.1323 4.06557 17.6011C4.53441 18.0699 5.17029 18.3333 5.83333 18.3333H14.1667C14.8297 18.3333 15.4656 18.0699 15.9344 17.6011C16.4033 17.1323 16.6667 16.4964 16.6667 15.8333V10C16.6667 9.33696 16.4033 8.70107 15.9344 8.23223C15.4656 7.76339 14.8297 7.5 14.1667 7.5ZM7.5 5.83333C7.5 5.17029 7.76339 4.53441 8.23223 4.06557C8.70107 3.59673 9.33696 3.33333 10 3.33333C10.663 3.33333 11.2989 3.59673 11.7678 4.06557C12.2366 4.53441 12.5 5.17029 12.5 5.83333V7.5H7.5V5.83333ZM15 15.8333C15 16.0543 14.9122 16.2663 14.7559 16.4226C14.5996 16.5789 14.3877 16.6667 14.1667 16.6667H5.83333C5.61232 16.6667 5.40036 16.5789 5.24408 16.4226C5.0878 16.2663 5 16.0543 5 15.8333V10C5 9.77899 5.0878 9.56703 5.24408 9.41075C5.40036 9.25447 5.61232 9.16667 5.83333 9.16667H14.1667C14.3877 9.16667 14.5996 9.25447 14.7559 9.41075C14.9122 9.56703 15 9.77899 15 10V15.8333Z"
      fill={color}
    />
  </svg>
);
