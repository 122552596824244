/* eslint-disable max-len */
import React, { FC } from 'react';
import { IconProps } from '../types';

export const ChatCircle: FC<IconProps> = ({
  size = '20',
  color = '#445371',
  transform,
  viewBox = '0 0 20 20',
  className,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    transform={transform}
    viewBox={viewBox}
    className={className}
    fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.66669 10C1.66669 5.39763 5.39765 1.66667 10 1.66667C14.6024 1.66667 18.3334 5.39763 18.3334 10C18.3334 14.6024 14.6024 18.3333 10 18.3333C8.50574 18.3333 7.10136 17.9393 5.88737 17.2493L2.76355 18.2906C2.4641 18.3904 2.13396 18.3124 1.91077 18.0893C1.68757 17.8661 1.60964 17.5359 1.70945 17.2365L2.75073 14.1126C2.06069 12.8987 1.66669 11.4943 1.66669 10ZM10 3.33333C6.31812 3.33333 3.33336 6.3181 3.33336 10C3.33336 11.3132 3.71217 12.5353 4.36617 13.5661C4.50039 13.7776 4.53231 14.0384 4.45308 14.276L3.81764 16.1824L5.72397 15.5469C5.96166 15.4677 6.22238 15.4996 6.43394 15.6339C7.46472 16.2878 8.68686 16.6667 10 16.6667C13.6819 16.6667 16.6667 13.6819 16.6667 10C16.6667 6.3181 13.6819 3.33333 10 3.33333Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.0893 7.74408C13.4147 8.06951 13.4147 8.59715 13.0893 8.92259L9.75594 12.2559C9.43051 12.5814 8.90287 12.5814 8.57743 12.2559L6.91077 10.5893C6.58533 10.2638 6.58533 9.73618 6.91077 9.41074C7.2362 9.08531 7.76384 9.08531 8.08928 9.41074L9.16669 10.4882L11.9108 7.74408C12.2362 7.41864 12.7638 7.41864 13.0893 7.74408Z"
      fill={color}
    />
  </svg>
);
